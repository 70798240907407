import {
  RESET_ALL,
  GET_LIQUIDATOR_RATES_REQUEST,
  GET_LIQUIDATOR_RATES_SUCCESS,
  GET_LIQUIDATOR_RATES_FAILURE,
  GET_LIQUIDATOR_DETAIL_REQUEST,
  GET_LIQUIDATOR_DETAIL_SUCCESS,
  GET_LIQUIDATOR_DETAIL_FAILURE,
  GET_LIQUIDATOR_REQUEST,
  GET_LIQUIDATOR_SUCCESS,
  GET_LIQUIDATOR_FAILURE,
} from '../action-types'

const initialState = {
  loading: false,
  loadingDetail: false,
  liquidatorRates: false,
  liquidator: false,
  liquidatorDetail: false,
  error: false,
}

const liquidator = (state = initialState, action) => {
  switch (action.type) {

    case GET_LIQUIDATOR_RATES_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIQUIDATOR_RATES_SUCCESS: {
      let liquidatorRates = ('TASAS_LIQUIDADOR' in action.data && action.data.TASAS_LIQUIDADOR.length > 0) ? action.data.TASAS_LIQUIDADOR[0] : false;
      return {
        ...state,
        liquidatorRates,
        error: false,
        loading: false
      }
    }

    case GET_LIQUIDATOR_RATES_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case GET_LIQUIDATOR_DETAIL_REQUEST: {
      return {
        ...state,
        loadingDetail: true
      }
    }

    case GET_LIQUIDATOR_DETAIL_SUCCESS: {
      let liquidatorDetail = 'DETALLE_LIQUIDADOR' in action.data  ? action.data.DETALLE_LIQUIDADOR : false;
      return {
        ...state,
        liquidatorDetail,
        error: false,
        loadingDetail: false
      }
    }

    case GET_LIQUIDATOR_DETAIL_FAILURE: {
      return {
        ...state,
        error: action.error,
        loadingDetail: false
      }
    }

    case GET_LIQUIDATOR_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIQUIDATOR_SUCCESS: {
      let liquidator = 'LIQUIDADOR' in action.data ? action.data.LIQUIDADOR : false;
      return {
        ...state,
        liquidator,
        error: false,
        loading: false
      }
    }

    case GET_LIQUIDATOR_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case RESET_ALL: {
      return initialState
    }

    default:
      return state
  }
}

export default liquidator
