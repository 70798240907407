import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import { Col, Button, Modal, Row } from 'react-bootstrap';

class ConfirmChange extends Component {

  cancel = () => {
    this.props.onHide();
  }

  confirm = () => {
    this.props.confirmReserve();
  }

  render() {
    const {
      name,
      reserveValue,
      productQuota,
      reserveDays,
      show,
      onHide,
    } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="confirm-reserve-title">
            Confirmar reserva
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="confirm-reserve-body">
            <Col xs={12} className="confirm-reserve_body-description">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Nombre:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">{name.toLowerCase()}</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Cupo disponible:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">${productQuota}</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Días de reserva:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">{reserveDays} Días</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Valor reserva:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">${reserveValue}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <Button
                    className="button-primary"
                    variant="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        this.cancel();
                    }}
                    block
                  >
                    Corregir
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className="button-send"
                    variant="success"
                    onClick={(e) => {
                        e.preventDefault();
                        this.confirm();
                    }}
                    block
                  >
                    Aceptar
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ConfirmChange.propTypes = {
  name: PropTypes.string,
  reserveValue: PropTypes.string,
  productQuota: PropTypes.string,
  reserveDays: PropTypes.string,
  confirmReserve: PropTypes.func.isRequired,
};

ConfirmChange.defaultProps = {
  name: '',
  reserveValue: '',
  productQuota: '',
  reserveDays: '',
  confirmReserve: () => {},
};

export default ConfirmChange;
