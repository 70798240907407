import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './refunds.scss';
import * as actions from '../../redux/actions'
import { Col, Button, Row, Table } from 'react-bootstrap';
import arrowback from '../../assets/icons/arrowback.svg';
import Footer from '../footer';
import Header from '../header';
import Menu from '../menu';
import Chat from '../chat';
import UserHeader from '../user-header';
import searchicon from '../../assets/icons/searchicon.svg';
import calendaricon from '../../assets/icons/calendaricon.svg';
import ReduxLazyScroll from 'redux-lazy-scroll';
import {TextField, MenuItem} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment from "moment";
import "moment/locale/es";
import MomentUtils from '@date-io/moment';
import Loading from '../loading';
import FileSaver from 'file-saver';
import ListLoader from '../list-loader';

moment.locale("es");

const searchSelect = [
  {id: "document", value: "Documento"},
  {id: "invoice", value: "Factura"},
  {id: "date", value: "Fecha"}
]

class Refunds extends Component {

  constructor() {
    super();
    this.state = {
      menuOpen: false,
      fromDate: null,
      toDate: null,
      documentType: '',
      documentNumber: '',
      userName: '',
      refundSearchSelect: 'document',
      productNumber: ''
    };
    this.page = 0;
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.scrollPager = null;
  }

  scrollToMyRef = () => {
    let myDiv = document.getElementsByClassName("redux-lazy-scroll");
    myDiv[0].scrollTop = 0;
  }

  goHome = () => {
    if(this.state.documentNumber !== '') {
      this.setState({
        documentType: '',
        documentNumber: '',
      }, () => {
        this.scrollToMyRef();
        this.page= 0;
        this.getRefunds();
      });
    } else {
      this.props.actions.resetRefunds();
      this.props.history.push(`/`);
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.actions.getUser();
    this.props.actions.getAppGlobals();
  }

  getRefunds = () => {
    const {
      documentType,
      documentNumber,
      fromDate,
      toDate,
      productNumber
    } = this.state;
    const {
      user
    } = this.props;
    let newSearch = documentNumber === '';
    this.page = this.page + 1;
    this.props.actions.getRefunds({
      documentType: newSearch ? 'C' : documentType,
      documentNumber: newSearch ? 0 : documentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      initialDate: fromDate ? moment(fromDate).format('DD/MM/YYYY') : '01/01/2015',
      finalDate: toDate ? moment(toDate).format('DD/MM/YYYY') : moment(new Date()).format('DD/MM/YYYY'),
      page: this.page,
      productNumber: productNumber ? productNumber : 0,
    });
  }

  exportRefunds = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    // window.gtag('event', 'descargar_listado_reintegros', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    const {
      documentType,
      documentNumber,
      fromDate,
      toDate,
      productNumber
    } = this.state;
    const {
      user
    } = this.props;
    let newSearch = documentNumber === '';
    this.props.actions.exportRefunds({
      documentType: newSearch ? 'C' : documentType,
      documentNumber: newSearch ? 0 : documentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      initialDate: fromDate ? moment(fromDate).format('DD/MM/YYYY') : '01/01/2015',
      finalDate: toDate ? moment(toDate).format('DD/MM/YYYY') : moment(new Date()).format('DD/MM/YYYY'),
      productNumber: productNumber ? productNumber : 0,
    });
  }

  savePaymentPdf(excelBlob) {
    FileSaver.saveAs(excelBlob, `reporteExoneracion.xlsx`);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.refunds){
      this.setState({
        userName: this.state.documentNumber !== '' ? (nextProps.refunds.length > 0 ? nextProps.refunds[0].NOMBRE : '') : ''
      })
    }
    if (nextProps.exportRefunds) {
      this.props.actions.resetExportRefunds()
      this.savePaymentPdf(nextProps.exportRefunds)
    }
  }

  closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true
    });
  }

  handleDateFromChange = date => {
    this.setState({ fromDate: date });
  };

  handleDateToChange = date => {
    this.setState({ toDate: date });
  };

  oninputChange = (event, input) => {
    this.setState({
      [input] : event.target.value
    });
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  setUserSearch = (item) => {
    this.setState({
      documentType: item.TIPO_DOCUMENTO,
      documentNumber: item.DOCUMENTO,
      productNumber: '',
      fromDate: null,
      toDate: null,
    }, () => {
      this.props.actions.resetRefunds();
      this.scrollToMyRef();
      this.page= 0;
      this.getRefunds();
    });
  }

  handleDateChange = (date, dateKey) => {
    this.setState({ [dateKey]: date });
  }

  renderNoticeSearchSelect = () => {
    const {
      refundSearchSelect,
      documentType,
      documentNumber,
      fromDate,
      toDate,
      productNumber
    } = this.state;
    const {
      globals
    } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let searchForm = ''
    switch (refundSearchSelect) {
      case 'invoice':
      searchForm = (
        <Col xs={12} md={9}>
          <Row className="search-date-container">
            <Col>
              <TextField
                id="productNumber"
                label="Buscar número de factura"
                className="home-input"
                margin="dense"
                value={productNumber}
                onChange={(event) => {
                  this.oninputChange(event, 'productNumber');
                }}
                InputProps={{
                  endAdornment: (
                    <img src={searchicon} alt="icono calendario" className="calendar-icon"/>
                  ),
                }}
                fullWidth
                />
            </Col>
            <Col xs={12} md={4}>
              <Button
                className="button-send"
                variant="success"
                disabled={productNumber === ''}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    documentType: '',
                    documentNumber: '',
                    fromDate: null,
                    toDate: null,
                  }, () => {
                    this.props.actions.resetRefunds();
                    this.scrollToMyRef();
                    this.page= 0;
                    this.getRefunds();
                  })
                }}
                block
                >
                Buscar
              </Button>
            </Col>
          </Row>
        </Col>
      )
      break;
      case 'date':
        searchForm = (
          <Col xs={12} md={9}>
            <Row className="search-date-container">
              <Col xs={12} md={4} className="exoneraton-date-boder">
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                  <DatePicker
                    margin="normal"
                    label="Desde"
                    className="home-input input-date"
                    value={fromDate}
                    locale='es-ES'
                    onChange={(date) =>{
                      this.handleDateChange(date, 'fromDate')
                    }}
                    fullWidth
                    format="DD/MM/YYYY"
                    keyboardIcon={<img src={calendaricon} alt="icono calendario" className="calendar-icon"/>}
                    keyboard
                    disableOpenOnEnter
                    placeholder="DD/MM/YYYY"
                    invalidDateMessage="Formato inválido"
                    mask={value =>
                      // handle clearing outside if value can be changed outside of the component
                      value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                    }
                    onInputChange={e => {
                      let value = e.target.value
                      value = value.replace(/_/g, '')
                      if (value.length === 10) {
                        this.handleDateChange(moment(value, 'DD/MM/YYYY'), 'fromDate')
                      }
                    }}
                    />
                </MuiPickersUtilsProvider>
              </Col>
              <Col xs={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                  <DatePicker
                    margin="normal"
                    label="Hasta"
                    className="home-input input-date"
                    value={toDate}
                    locale='es-ES'
                    onChange={(date) =>{
                      this.handleDateChange(date, 'toDate')
                    }}
                    fullWidth
                    format="DD/MM/YYYY"
                    keyboardIcon={<img src={calendaricon} alt="icono calendario" className="calendar-icon"/>}
                    keyboard
                    disableOpenOnEnter
                    placeholder="DD/MM/YYYY"
                    invalidDateMessage="Formato inválido"
                    mask={value =>
                      // handle clearing outside if value can be changed outside of the component
                      value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                    }
                    onInputChange={e => {
                      let value = e.target.value
                      value = value.replace(/_/g, '')
                      if (value.length === 10) {
                        this.handleDateChange(moment(value, 'DD/MM/YYYY'), 'toDate')
                      }
                    }}
                    />
                </MuiPickersUtilsProvider>
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="button-send"
                  variant="success"
                  disabled={!(toDate && fromDate)}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      documentType: '',
                      documentNumber: '',
                      productNumber: '',
                    }, () => {
                      this.props.actions.resetRefunds();
                      this.scrollToMyRef();
                      this.page= 0;
                      this.getRefunds();
                    })
                  }}
                  block
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Col>
        )
        break;
      case 'document':
        searchForm = (
          <Col xs={12} md={9}>
            <Row className="search-date-container">
              <Col xs={12} md={4} className="login-input-container">
                <TextField
                  id="documentType"
                  select
                  label="Tipo de documento"
                  className="home-input"
                  margin="dense"
                  value={documentType}
                  onChange={(event) => {
                    this.oninputChange(event, 'documentType');
                  }}
                  fullWidth
                  >
                  {documents.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.ID}>
                        {item.LABEL}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Col>
              <Col xs={12} md={4} className="login-input-container">
                <TextField
                  id="documentNumber"
                  label="Número de documento"
                  className="home-input"
                  margin="dense"
                  type="number"
                  value={documentNumber}
                  onChange={(event) => {
                    this.oninputChange(event, 'documentNumber');
                  }}
                  fullWidth
                  />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="button-send"
                  variant="success"
                  disabled={documentType === '' || documentNumber === ''}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      productNumber: '',
                      fromDate: null,
                      toDate: null,
                    }, () => {
                      this.props.actions.resetRefunds();
                      this.scrollToMyRef();
                      this.page= 0;
                      this.getRefunds();
                    })
                  }}
                  block
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Col>
        )
        break;
      default:

    }
    return searchForm;
  }

  render() {
    const { menuOpen,
      fromDate,
      toDate,
      userName,
      documentNumber,
      refundSearchSelect
    } = this.state;
    const {
      loading,
      refunds,
      refundsPageCount,
      user,
      loadingPaginate
    } = this.props;
    return (
      <div className="refunds">
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={10} />
        <Header openMenu={this.openMenu} />
        <div className="page-body-container center-horizontally">
          <div className="page-body">
            <UserHeader />
            { true && (
              <Col xs={12} className="general-main-card">
                { (loading) && (
                  <Loading />
                )}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                  >
                    <span>
                      <img src={arrowback} alt="arrow back" className="goback-icon" />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                <Col xs={12} className="refunds-margin">
                    <Col xs={12} className="card-title">
                      Reintegros
                    </Col>
                    <Col xs={12}>
                      <hr />
                    </Col>
                </Col>
                <Col xs={12}>
                  {/*
                    <Row>
                    {(fromDate || toDate || userName) && (
                    <Col xs={12} className="subheader_container">
                    <Row>
                    { userName && (
                    <Col xs={12} md={5}>
                    <Row>
                    <Col xs={12} className="subheader_name">Cliente</Col>
                    <Col xs={12} className="subheader_value">{userName}</Col>
                    </Row>
                    </Col>
                    )}
                    {(fromDate || toDate) && (
                    <Col xs={12} md={7}>
                    <Row>
                    <Col xs={12} className="subheader_name">Fecha de consulta</Col>
                    <Col xs={12}>
                    <Row>
                    {fromDate && (
                    <Col xs="auto" className="subheader_value subheader_value-border">{moment(fromDate).format('DD/MM/YYYY')}</Col>
                    )}
                    {toDate && (
                    <Col xs="auto" className="subheader_value">{moment(toDate).format('DD/MM/YYYY')}</Col>
                    )}

                    </Row>
                    </Col>
                    </Row>
                    </Col>
                    )}
                    </Row>
                    </Col>
                    )}
                    { userName === '' && (
                    <Col xs={12} md={5}>
                    <Row>
                    <Col xs={12} className="exoneraton-search_title">Seleccione una fecha</Col>
                    <Col xs={12} className="exoneraton-col-padding">
                    <Row>
                    <Col xs={12} md={6} className="exoneraton-date-boder">
                    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                    <DatePicker
                    margin="normal"
                    label="Desde"
                    className="home-input input-date"
                    value={fromDate}
                    locale='es-ES'
                    onChange={this.handleDateFromChange}
                    fullWidth
                    format="DD/MM/YYYY"
                    InputProps={{
                    endAdornment: (
                    <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                  ),
                }}
                />
              </MuiPickersUtilsProvider>
            </Col>
            <Col xs={12} md={6}>
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <DatePicker
            margin="normal"
            label="Hasta"
            className="home-input input-date"
            value={toDate}
            locale='es-ES'
            onChange={this.handleDateToChange}
            fullWidth
            format="DD/MM/YYYY"
            InputProps={{
            endAdornment: (
            <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
          ),
        }}
        />
      </MuiPickersUtilsProvider>
    </Col>
  </Row>

</Col>
</Row>
</Col>
)}
{ userName === '' && (
<Col xs={12} md={7} className="refund-search_container-col">
<Row className="refund-search_container">
<Col>
<TextField
id="documentNumber"
label="Buscar número documento"
className="home-input"
margin="dense"
value={documentNumber}
onChange={(event) => {
this.oninputChange(event, 'documentNumber');
}}
InputProps={{
endAdornment: (
<img src={searchicon} alt="icono calendario" className="calendar-icon"/>
),
}}
fullWidth
/>
</Col>
<Col xs={5}>
<Button
className="button-send"
variant="success"
onClick={(e) => {
e.preventDefault();
this.scrollToMyRef();
this.page= 0;
this.getRefunds();
}}
block
>
Buscar
</Button>
</Col>
</Row>

</Col>
)}
</Row>
                    */}
                  <Row className="search-notice-form">
                    <Col xs={12} md={3} className="login-input-container">
                      <TextField
                        id="refundSearchSelect"
                        select
                        label="Filtro de busqueda"
                        className="home-input"
                        margin="dense"
                        value={refundSearchSelect}
                        onChange={(event) => {
                          this.oninputChange(event, 'refundSearchSelect');
                        }}
                        fullWidth
                        >
                        {searchSelect.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.id}>
                              {item.value}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                    </Col>
                    {this.renderNoticeSearchSelect()}
                  </Row>
                </Col>
                <Col xs={12} className="refunds-table-container" >
                  { user && (
                    <ReduxLazyScroll
                      id="scroll-pager"
                      isFetching={loadingPaginate}
                      errorMessage=''
                      isParentScrollable={true}
                      parentHeight='250px'
                      loadMore={() => {
                        this.getRefunds();
                      }}
                      hasMore={refundsPageCount > 0}
                    >
                      <Table bordered responsive="xl">
                        <thead>
                          <tr>
                            <th rowSpan="2">Tipo de documento</th>
                            <th rowSpan="2" >Nº documento</th>
                            <th rowSpan="2">Número producto</th>
                            <th rowSpan="2">Valor</th>
                            <th rowSpan="2">Fecha de vencimiento</th>
                            <th colSpan="2" className="center">Reintegro</th>
                            <th colSpan="2" className="center">
                              Deducibles
                            </th>
                          </tr>
                          <tr>
                            <th className="subtitle">Fecha</th>
                            <th className="subtitle">Monto</th>
                            <th className="subtitle">Fecha</th>
                            <th className="subtitle">Monto</th>

                          </tr>
                        </thead>
                        <tbody>
                          {refunds.map((item, index) => {
                            console.log('item', item)
                            return (
                              <tr key={index}>
                                <td>{item.TIPO_DOCUMENTO}</td>
                                <td
                                  className="document"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setUserSearch(item);
                                  }}
                                  >{item.DOCUMENTO}</td>
                                <td>{item.TITULO_VALOR}</td>
                                <td>${this.getNumber(item.VALOR_RECLAMADO)}</td>
                                <td>{item.VENCIMIENTO === '' ? '' : moment(item.VENCIMIENTO).format('DD/MM/YYYY')}</td>
                                <td>{item.FECHA_REINTEGRO === '' ? '' : moment(item.FECHA_REINTEGRO).format('DD/MM/YYYY')}</td>
                                <td>${this.getNumber(item.MONTO_REINTEGRO)}</td>
                                <td>{item.FECHA_DEDUCIBLE === '' ? '' : moment(item.FECHA_DEDUCIBLE).format('DD/MM/YYYY')}</td>
                                <td>${this.getNumber(item.MONTO_DEDUCIBLE)}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                      { (loadingPaginate ) && (
                        <ListLoader />
                      )}
                    </ReduxLazyScroll>
                  )}
                </Col>
                <Col xs={12} md={{ span: 4, offset: 4 }} className="button-send-container refunds-margin">
                  <Button className="button-send" variant="success"
                    onClick={(e) => {
                      e.preventDefault();
                      this.exportRefunds();
                    }}
                    block>
                    Descargar reporte
                  </Button>
                </Col>
              </Col>
            )}
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
    user,
    globals
  } = state.user;
  let {
    loading,
    refunds,
    refundsPageCount,
    exportRefunds,
    loadingPaginate
  } = state.refunds;
  return {
    globals,
    loading,
    user,
    refunds,
    refundsPageCount,
    exportRefunds,
    loadingPaginate
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Refunds);
