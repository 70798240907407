import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import { Col, Button, Modal, Row } from 'react-bootstrap';

class LiquidatorInfoModal extends Component {

  confirm = () => {
    this.props.onHide();
  }

  render() {
    const {
      liquidatorNumber,
      show,
      onHide,
    } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="liquidator-info-modal-title">
            Liquidador Nº {liquidatorNumber}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="liquidator-info-modal-body">
            <Col xs={12} className="liquidator-info-modal_body-description">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} className="liquidator-info-modal_body-description-text">
                      Para más información comuníquese a<br />
                      nuestras líneas de atención e indíquenos<br />
                      su número de liquidador
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="liquidator-info-modal_body-key">Bogotá:</Col>
                    <Col xs="auto" className="liquidator-info-modal_body-value">(+571) 353 4324</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="liquidator-info-modal_body-key">Barranquilla:</Col>
                    <Col xs="auto" className="liquidator-info-modal_body-value">(+575) 361 6800</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="liquidator-info-modal_body-key">Cali:</Col>
                    <Col xs="auto" className="liquidator-info-modal_body-value">(+575) 882 7977</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="liquidator-info-modal_body-key">Medellín:</Col>
                    <Col xs="auto" className="liquidator-info-modal_body-value">(+575) 351 1211</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="liquidator-info-modal_body-key">Bucaramanga:</Col>
                    <Col xs="auto" className="liquidator-info-modal_body-value">(+577) 630 6702</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="liquidator-info-modal_body-key">Línea nacional:</Col>
                    <Col xs="auto" className="liquidator-info-modal_body-value">01 800 94 69696</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={{span: 8, offset: 2}} md={{span: 4, offset: 4}}>
                  <Button
                    className="button-send"
                    variant="success"
                    onClick={(e) => {
                        e.preventDefault();
                        this.confirm();
                    }}
                    block
                  >
                    Cerrar
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

LiquidatorInfoModal.propTypes = {
  liquidatorNumber: PropTypes.string,
};

LiquidatorInfoModal.defaultProps = {
  liquidatorNumber: '',
};

export default LiquidatorInfoModal;
