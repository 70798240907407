import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import { Col, Button, Modal, Row } from 'react-bootstrap';
import {TextField} from '@material-ui/core';

class EmailModal extends Component {

  constructor() {
    super();
    this.state = {
      email: '',
      emailValid: true,
    };

  }

  confirm = () => {
    const {
      email,
    } = this.state;
    this.props.confirm(email);
    this.setState({
      email : ''
    });
  }

  oninputChange = (event, input) => {
    this.setState({
      [input] : event.target.value
    });
  }

  isValidEmail(emailUser, input) {
    if(!emailUser) {
      this.setState({
        [input]: true
      });
      return
    }
    let validEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_]+\.[A-Za-z-_.]+$/.test(emailUser)) {
      validEmail = true;
    }
    this.setState({
      [input]: validEmail
    });
  }

  isEmail = (event, input) => {
    let email = event.target.value;
    let validName = input + 'Valid'
    this.isValidEmail(email, validName);
    let validEmail = false
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_]+\.[A-Za-z-_.]+$/.test(email)) {
      validEmail = true;
    }
    if (validEmail) {
      this.setState({
        [input]: email,
        [validName]: validEmail
      });
    } else {
      this.setState({
        [input]: email,
      });
    }
  }

  render() {
    const {
      show,
      onHide,
    } = this.props;
    const {
      email,
      emailValid,
    } = this.state;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="query-code-title">
            Enviar por correo
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="terms-modal-body">
            <Col xs={12} className="query-code-description">
              Ingrese el correo electrónico<br/>
             del comprador, donde recibirá<br/>
           el formulario de solicitud de cupo.
            </Col>
            <Col xs={12}>
              <Row className="modal-end">
                <Col xs={12} md={8} className="login-input-container">
                  <TextField
                    id="email"
                    label="Correo electrónico"
                    className="home-input"
                    margin="dense"
                    value={email}
                    onChange={(event) => {
                      this.isEmail(event, 'email')
                    }}
                    error={email && !emailValid ? true : false}
                    helperText={email && !emailValid ? 'Escriba un formato de email válido': null}
                    fullWidth
                    />
                </Col>
                <Col xs={12} md={4} className="query-code-buttons">
                  <Button
                    className="button-send"
                    variant="success"
                    disabled={!emailValid}
                    onClick={(e) => {
                      e.preventDefault();
                      this.confirm();
                    }}
                    block
                    >
                    Enviar
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

EmailModal.propTypes = {
  confirm: PropTypes.func.isRequired,
};

EmailModal.defaultProps = {
  confirm: () => {},
};

export default EmailModal;
