import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { TextField, MenuItem } from '@material-ui/core';
import { Col, Button, Modal, Row } from 'react-bootstrap';

import * as actions from '../../../redux/actions';

const documentTypesPartners = [
  { LABEL: 'NIT', ID: 'N' },
  { LABEL: 'CEDULA DE CIUDADANIA', ID: 'C' },
  { LABEL: 'CEDULA DE EXTRANJERIA', ID: 'E' },
];

class SelectClientModal extends Component {
  state = {
    documentType: '',
    documentNumber: '',
    contract: '',
    selectedAffiliate: '',
  };

  confirm = () => {
    this.props.onHide();
  };

  oninputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  getAffiliates = () => {
    this.setState({
      selectedAffiliate: '',
    });
    const { user } = this.props;
    const { documentType, documentNumber, contract } = this.state;
    this.props.actions.getAffiliate({
      user: user.userEmail,
      affiliateDocumentType: documentType,
      affiliateDocumentNumber: documentNumber,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userDocumentNumber: user.userInfo.DOCUMENTO,
      contract: contract,
    });
  };

  selectAffiliate = () => {
    const { user, affiliateUsers } = this.props;
    const { selectedAffiliate } = this.state;
    this.props.actions.getAffiliateToken({
      user: user.userEmail,
      affiliateDocumentType: affiliateUsers[selectedAffiliate].TIPO_DOCUMENTO,
      affiliateDocumentNumber: affiliateUsers[selectedAffiliate].DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userDocumentNumber: user.userInfo.DOCUMENTO,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.affiliateUsers !== this.props.affiliateUsers &&
      nextProps.affiliateUsers
    ) {
      console.log('nextProps.affiliateUsers', nextProps.affiliateUsers);
    }
    if (
      nextProps.affiliateToken !== this.props.affiliateToken &&
      nextProps.affiliateToken
    ) {
      const { affiliateUsers } = this.props;
      const { selectedAffiliate } = this.state;
      let affiliate = Object.assign({}, affiliateUsers[selectedAffiliate]);
      affiliate['token'] = nextProps.affiliateToken;
      this.props.selectAffiliate(affiliate);
    }
  }

  render() {
    const { show, onHide, loading, affiliateUsers } = this.props;
    const { documentType, documentNumber, contract, selectedAffiliate } =
      this.state;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <div className='query-code-title modal-study-title'>
            Seleccionar Afiliado
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='terms-modal-body'>
            <Col xs={12} md={12} className='study-modal-succes_info-container'>
              <Row>
                <Col xs={12} md={4} className='login-input-container'>
                  <TextField
                    id='documentType'
                    select
                    label='Tipo de documento'
                    className='home-input'
                    value={documentType}
                    onChange={(event) => {
                      this.oninputChange(event, 'documentType');
                    }}
                    margin='dense'
                    fullWidth
                  >
                    {documentTypesPartners.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.ID}>
                          {item.LABEL}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Col>
                <Col xs={12} md={4} className='login-input-container'>
                  <TextField
                    id='documentNumber'
                    label='Número de documento'
                    className='home-input'
                    margin='dense'
                    type='number'
                    value={documentNumber}
                    onChange={(event) => {
                      this.oninputChange(event, 'documentNumber');
                    }}
                    fullWidth
                  />
                </Col>
                <Col xs={12} md={4} className='login-input-container'>
                  <TextField
                    id='contract'
                    label='Contrato'
                    className='home-input'
                    margin='dense'
                    type='number'
                    value={contract}
                    onChange={(event) => {
                      this.oninputChange(event, 'contract');
                    }}
                    fullWidth
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={{ span: 4, offset: 4 }}
              className='query-code-buttons'
            >
              <Button
                className='button-send'
                variant='success'
                disabled={
                  !(contract || (documentNumber && documentType)) || loading
                }
                onClick={(e) => {
                  e.preventDefault();
                  this.getAffiliates();
                }}
                block
              >
                {loading ? 'Consultar Afiliado...' : 'Consultar Afiliado'}
              </Button>
            </Col>

            {affiliateUsers.length > 0 && (
              <Col
                xs={12}
                md={12}
                className='study-modal-succes_info-container'
              >
                <Row>
                  <Col
                    xs={12}
                    md={{ span: 8, offset: 2 }}
                    className='login-input-container'
                  >
                    <TextField
                      id='selectedAffiliate'
                      select
                      label='Seleccionar Afiliado'
                      className='home-input'
                      value={selectedAffiliate}
                      onChange={(event) => {
                        this.oninputChange(event, 'selectedAffiliate');
                      }}
                      margin='dense'
                      fullWidth
                    >
                      {affiliateUsers.map((item, index) => {
                        return (
                          <MenuItem key={index} value={index}>
                            {item.NOMBRE} {item.CONTRATO} {item.ESTADO}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Col>
                </Row>
              </Col>
            )}
            {affiliateUsers.length > 0 && (
              <Col
                xs={12}
                md={{ span: 4, offset: 4 }}
                className='query-code-buttons'
              >
                <Button
                  className='button-send'
                  variant='success'
                  disabled={
                    selectedAffiliate === '' ||
                    loading ||
                    affiliateUsers[selectedAffiliate].ESTADO !== 'ACTIVO'
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.selectAffiliate();
                  }}
                  block
                >
                  Seleccionar Afiliado
                </Button>
              </Col>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

SelectClientModal.propTypes = {
  selectAffiliate: PropTypes.func,
};

SelectClientModal.defaultProps = {
  selectAffiliate: () => {},
};

const mapStateToProps = (state, props) => {
  return {
    ...state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectClientModal);
