import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "debounce";

import "./advance-agreements.scss";
import * as actions from "../../redux/actions";
import { Col, Button, Row, Table } from "react-bootstrap";
import arrowback from "../../assets/icons/arrowback.svg";
import Footer from "../footer";
import Header from "../header";
import Menu from "../menu";
import Chat from "../chat";
import UserHeader from "../user-header";
import aproved from "../../assets/icons/aproved.svg";
import ReduxLazyScroll from "redux-lazy-scroll";
import { TextField, MenuItem, InputAdornment } from "@material-ui/core";
import Loading from "../loading";
import FileSaver from "file-saver";
import ListLoader from "../list-loader";
import AdvanceAgreementsModal from "./confirm-advance-agreements-modal";

const searchSelect = [
  { id: "document", value: "Documento" },
  { id: "invoice", value: "Factura" },
  { id: "date", value: "Fecha" },
];

const initialState = {
  menuOpen: false,
  fromDate: null,
  toDate: null,
  documentType: "",
  documentNumber: "",
  userName: "",
  refundSearchSelect: "document",
  productNumber: "",
  advanceAgreement: {},
  advanceAgreementsModal: false,
};

class AdvanceAgreements extends Component {
  constructor() {
    super();
    this.state = { ...initialState };
    this.page = 0;
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.scrollPager = null;
    this.showValueError = debounce(this.showValueError, 1000);
  }

  scrollToMyRef = () => {
    let myDiv = document.getElementsByClassName("redux-lazy-scroll");
    myDiv[0].scrollTop = 0;
  };

  goHome = () => {
    if (this.state.documentNumber !== "") {
      this.setState(
        Object.assign(...Object.keys(this.state).map((k) => ({ [k]: null })))
      );
      this.setState(initialState, () => {
        this.scrollToMyRef();
        this.page = 0;
        this.getAdvanceAgreements();
      });
    } else {
      this.props.actions.resetAdvanceAgreements();
      this.props.history.push(`/`);
    }
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.actions.resetAdvanceAgreements();
    this.props.actions.getUser();
    this.props.actions.getAppGlobals();
  };

  getAdvanceAgreements = () => {
    const { documentType, documentNumber } = this.state;
    const { user } = this.props;
    let newSearch = documentNumber === "";
    this.page = this.page + 1;
    this.props.actions.getAdvanceAgreements({
      documentType: newSearch ? "C" : documentType,
      documentNumber: newSearch ? 0 : documentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      page: this.page,
    });
  };

  exportAdvanceAgreements = () => {
    let userJson = JSON.parse(localStorage.getItem("user"));
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    // window.gtag("event", "descargar_listado_reintegros", {
    //   event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
    //   event_label: userJson.userEmail,
    // });
    const {
      documentType,
      documentNumber,
      fromDate,
      toDate,
      productNumber,
    } = this.state;
    const { user } = this.props;
    let newSearch = documentNumber === "";
    this.props.actions.exportAdvanceAgreements({
      documentType: newSearch ? "C" : documentType,
      documentNumber: newSearch ? 0 : documentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
    });
  };

  savePaymentPdf(excelBlob) {
    FileSaver.saveAs(excelBlob, `reporteExoneracion.xlsx`);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.advanceAgreements) {
      this.setState({
        userName:
          this.state.documentNumber !== ""
            ? nextProps.advanceAgreements.length > 0
              ? nextProps.advanceAgreements[0].NOMBRE
              : ""
            : "",
      });
    }
    if (nextProps.exportExonerations) {
      this.props.actions.resetExportExonerationReport();
      this.saveExonerationFile(nextProps.exportExonerations);
    }
  }

  exportFile = () => {
    this.setState(
      {
        exportType: "Exonerada",
      },
      () => {
        const { user, uploadAdvanceAgreements } = this.props;
        this.props.actions.exportMasiveExoneration({
          userDocument: user.userInfo.DOCUMENTO,
          userDocumentType: user.userInfo.TIPO_DOCUMENTO,
          numberFiled: uploadAdvanceAgreements["RADICACION:"],
          status: "Exonerada",
        });
      }
    );
  };

  saveExonerationFile(excelBlob) {
    const { exportType } = this.state;
    FileSaver.saveAs(excelBlob, `reporteExoneraciones${exportType}.xlsx`);
  }

  closeMenu() {
    this.setState({
      menuOpen: false,
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true,
    });
  }

  handleDateFromChange = (date) => {
    this.setState({ fromDate: date });
  };

  handleDateToChange = (date) => {
    this.setState({ toDate: date });
  };

  oninputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  getNumber(numberValue) {
    let thousand = Math.ceil(numberValue)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if (thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  setUserSearch = (item) => {
    this.setState(
      {
        documentType: item.TIPO_DOCUMENTO,
        documentNumber: item.DOCUMENTO,
        productNumber: "",
        fromDate: null,
        toDate: null,
      },
      () => {
        this.props.actions.resetAdvanceAgreements();
        this.scrollToMyRef();
        this.page = 0;
        this.getAdvanceAgreements();
      }
    );
  };

  resetAdvanceAgreements = () => {
    this.setState(
      Object.assign(...Object.keys(this.state).map((k) => ({ [k]: null })))
    );
    this.setState(initialState, () => {
      this.props.actions.resetAdvanceAgreements();
      this.page = 0;
      this.getAdvanceAgreements();
    });
  };

  handleDateChange = (date, dateKey) => {
    this.setState({ [dateKey]: date });
  };

  renderNoticeSearchSelect = () => {
    const { documentType, documentNumber } = this.state;
    const { globals } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let searchForm = (
      <Col xs={12}>
        <Row className="search-date-container">
          <Col xs={12} md={4} className="login-input-container">
            <TextField
              id="documentType"
              select
              label="Tipo de documento"
              className="home-input"
              margin="dense"
              value={documentType}
              onChange={(event) => {
                this.oninputChange(event, "documentType");
              }}
              fullWidth
            >
              {documents.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.ID}>
                    {item.LABEL}
                  </MenuItem>
                );
              })}
            </TextField>
          </Col>
          <Col xs={12} md={4} className="login-input-container">
            <TextField
              id="documentNumber"
              label="Número de documento"
              className="home-input"
              margin="dense"
              type="number"
              value={documentNumber}
              onChange={(event) => {
                this.oninputChange(event, "documentNumber");
              }}
              fullWidth
            />
          </Col>
          <Col xs={12} md={4}>
            <Button
              className="button-send"
              variant="success"
              onClick={(e) => {
                e.preventDefault();
                this.setState(
                  {
                    productNumber: "",
                    fromDate: null,
                    toDate: null,
                  },
                  () => {
                    this.props.actions.resetAdvanceAgreements();
                    this.scrollToMyRef();
                    this.page = 0;
                    this.getAdvanceAgreements();
                  }
                );
              }}
              block
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Col>
    );

    return searchForm;
  };

  showValueError = (index, inputValue, valueToPay, agreement) => {
    let minValue = parseInt(valueToPay) * 0.5;
    if (valueToPay < 10000000) {
      minValue = parseInt(valueToPay) * 0.5;
    } else if (valueToPay < 20000000) {
      minValue = parseInt(valueToPay) * 0.5;
    } else if (valueToPay < 50000000) {
      minValue = parseInt(valueToPay) * 0.4;
    } else if (valueToPay <= 10000000) {
      minValue = parseInt(valueToPay) * 0.2;
    } else if (valueToPay > 10000000) {
      minValue = parseInt(valueToPay) * 0.1;
    }
    if (
      (parseInt(agreement) === 0 && parseInt(inputValue) < minValue) ||
      parseInt(inputValue) > parseInt(valueToPay)
    ) {
      this.setState({
        [`advanceAgreementValueError${index}`]: minValue,
      });
    } else {
      this.setState({
        [`advanceAgreementValueError${index}`]: false,
      });
    }
  };

  onAdvanceAgreementChange = (index, event, valueToPay, agreement) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^\d]+/g, "");
    inputValue = inputValue.replace(/\./g, "");
    inputValue = inputValue.replace(/´/g, "");
    let advanceAgreementValue = inputValue;
    let thousand = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if (thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    this.setState({
      [`advanceAgreementValue${index}`]: advanceAgreementValue,
      [`advanceAgreementValueMask${index}`]: thousand,
    });
    let minValue = parseInt(valueToPay) * 0.5;
    if (valueToPay < 10000000) {
      minValue = parseInt(valueToPay) * 0.5;
    } else if (valueToPay < 20000000) {
      minValue = parseInt(valueToPay) * 0.5;
    } else if (valueToPay < 50000000) {
      minValue = parseInt(valueToPay) * 0.4;
    } else if (valueToPay <= 10000000) {
      minValue = parseInt(valueToPay) * 0.2;
    } else if (valueToPay > 10000000) {
      minValue = parseInt(valueToPay) * 0.1;
    }
    if (
      (parseInt(agreement) === 0 &&
        parseInt(advanceAgreementValue) < minValue) ||
      parseInt(advanceAgreementValue) > parseInt(valueToPay)
    ) {
      this.setState({
        [`advanceAgreementValueErrorDisabled${index}`]: true,
      });
    } else {
      this.setState({
        [`advanceAgreementValueErrorDisabled${index}`]: false,
        [`advanceAgreementValueError${index}`]: false,
      });
    }
    this.showValueError(index, advanceAgreementValue, valueToPay, agreement);
  };

  isIndexAdvanceAgreementValue = (index) => {
    let partialValue = this.state[`advanceAgreementValue${index}`];
    return !!partialValue;
  };

  confirmAdvanceAgreementClose = () => {
    this.setState({
      advanceAgreement: null,
      advanceAgreementsModal: false,
    });
  };

  confirmAdvanceAgreement = (
    advanceAgreement,
    valueToAgreement,
    valueToClear
  ) => {
    this.setState({
      advanceAgreement,
      advanceAgreementsModal: true,
      valueToClear,
      valueToAgreement,
    });
  };

  sendAdvanceAgreement = () => {
    let userJson = JSON.parse(localStorage.getItem("user"));
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    // window.gtag("event", "envio_acuerdo-anticipado", {
    //   event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
    //   event_label: userJson.userEmail,
    // });
    const { advanceAgreement, valueToClear, valueToAgreement } = this.state;
    const { user } = this.props;
    this.setState({
      [valueToClear]: null,
      advanceAgreementsModal: false,
    });
    this.props.actions.uploadAdvanceAgreement({
      documentType: advanceAgreement.TIPO_DOCUMENTO,
      documentNumber: advanceAgreement.DOCUMENTO,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userName: user.userEmail,
      invoiceCount: advanceAgreement.TITULOS_A_EXONERAR,
      valueToPay: advanceAgreement.VALOR_A_EXONERAR,
      valueSelected: valueToAgreement,
    });
  };

  render() {
    const {
      menuOpen,
      advanceAgreementsModal,
      advanceAgreement,
      valueToAgreement,
      documentType,
      documentNumber,
    } = this.state;
    const {
      loading,
      exonerationLoading,
      advanceAgreements,
      advanceAgreementsPageCount,
      user,
      loadingPaginate,
      uploadAdvanceAgreements,
    } = this.props;
    return (
      <div className="advance-agreements">
        <AdvanceAgreementsModal
          show={advanceAgreementsModal}
          onHide={this.confirmAdvanceAgreementClose}
          advanceAgreement={advanceAgreement}
          valueToAgreement={valueToAgreement}
          confirmAdvanceAgreements={this.sendAdvanceAgreement}
        />
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={10} />
        <Header openMenu={this.openMenu} />
        <div className="page-body-container center-horizontally">
          <div className="page-body">
            <UserHeader />
            {true && (
              <Col xs={12} className="general-main-card">
                {(loading || exonerationLoading) && <Loading />}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                  >
                    <span>
                      <img
                        src={arrowback}
                        alt="arrow back"
                        className="goback-icon"
                      />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                <Col xs={12} className="advance-agreements-margin">
                  <Row>
                    <Col xs={12} className="card-title">
                      Acuerdos Anticipados
                    </Col>
                    <Col xs={12}>
                      <hr />
                    </Col>
                    {!uploadAdvanceAgreements && (
                      <Col xs={12} className="card-sub-title">
                        Los compradores que se encuentran en el listado tienen
                        aviso de no pago en la totalidad de sus títulos
                        vencidos.
                      </Col>
                    )}
                  </Row>
                </Col>
                {!uploadAdvanceAgreements && (
                  <Col xs={12}>
                    <Row className="search-notice-form">
                      {this.renderNoticeSearchSelect()}
                    </Row>
                  </Col>
                )}
                {user && !uploadAdvanceAgreements && (
                  <Col xs={12} className="advance-agreements-table-container">
                    <ReduxLazyScroll
                      id="scroll-pager"
                      isFetching={loadingPaginate}
                      errorMessage=""
                      isParentScrollable={true}
                      parentHeight="250px"
                      loadMore={() => {
                        this.getAdvanceAgreements();
                      }}
                      hasMore={advanceAgreementsPageCount > 0}
                    >
                      <Table bordered responsive="xl">
                        <thead>
                          <tr>
                            <th>Tipo de documento</th>
                            <th>Nº documento</th>
                            <th>Nombre</th>
                            <th>Nº de título</th>
                            <th>Valor total producto</th>
                            <th className="notice-th">Abono</th>
                            <th className="notice-th">Abonar</th>
                          </tr>
                        </thead>
                        <tbody>
                          {advanceAgreements.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.TIPO_DOCUMENTO}</td>
                                <td
                                  className="document"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setUserSearch(item);
                                  }}
                                >
                                  {item.DOCUMENTO}
                                </td>
                                <td>{item.NOMBRE}</td>
                                <td>{item.TITULOS_A_EXONERAR}</td>
                                <td>
                                  ${this.getNumber(item.VALOR_A_EXONERAR)}
                                </td>
                                <td className="notice-td">
                                  <TextField
                                    id="amount"
                                    label="Valor"
                                    className="home-input input-agreement"
                                    margin="dense"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    value={
                                      this.isIndexAdvanceAgreementValue(index)
                                        ? this.state[
                                            `advanceAgreementValueMask${index}`
                                          ]
                                        : ""
                                    }
                                    onChange={(e) => {
                                      this.onAdvanceAgreementChange(
                                        index,
                                        e,
                                        item.VALOR_A_EXONERAR,
                                        item.ACUERDO
                                      );
                                    }}
                                    fullWidth
                                  />
                                  {!!this.state[
                                    `advanceAgreementValueError${index}`
                                  ] && (
                                    <div className="input-error">
                                      <span>
                                        El valor debe
                                        <br /> ser mayor a <br />$
                                        {this.getNumber(
                                          this.state[
                                            `advanceAgreementValueError${index}`
                                          ]
                                        )}
                                        <br />
                                        y menor a <br />$
                                        {this.getNumber(item.VALOR_A_EXONERAR)}
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td
                                  className={`notice-td${
                                    this.isIndexAdvanceAgreementValue(index) &&
                                    !!!this.state[
                                      `advanceAgreementValueErrorDisabled${index}`
                                    ]
                                      ? " enabled-link"
                                      : ""
                                  }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      this.isIndexAdvanceAgreementValue(
                                        index
                                      ) &&
                                      !!!this.state[
                                        `advanceAgreementValueErrorDisabled${index}`
                                      ]
                                    ) {
                                      this.confirmAdvanceAgreement(
                                        item,
                                        this.state[
                                          `advanceAgreementValue${index}`
                                        ],
                                        `advanceAgreementValue${index}`
                                      );
                                    }
                                  }}
                                >
                                  <div className="notice-td-container">
                                    <span>Abonar</span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      {loadingPaginate && <ListLoader />}
                    </ReduxLazyScroll>
                  </Col>
                )}
                {user &&
                  !uploadAdvanceAgreements &&
                  (documentType != "" || documentNumber != "") && (
                    <Col xs={12} md={{ span: 4, offset: 4 }}>
                      <Button
                        className="button-primary"
                        variant="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          this.resetAdvanceAgreements();
                        }}
                        block
                      >
                        Limpiar
                      </Button>
                    </Col>
                  )}
                {uploadAdvanceAgreements && (
                  <Col xs={12} className="advance-agreements-succes-container">
                    <Row>
                      <Col
                        xs={12}
                        md={{ span: 4, offset: 4 }}
                        className="reservation-loading-text"
                      >
                        <img
                          src={aproved}
                          alt="icono reservas"
                          className="resevation-icon"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={{ span: 6, offset: 3 }}
                        className="reserve-aproved-title"
                      >
                        ¡PAGO ANTICIPADO REALIZADO!
                      </Col>
                      <Col
                        xs={12}
                        md={{ span: 6, offset: 3 }}
                        className="not-reservation-text"
                      >
                        Muchas gracias por utilizar los servicios de{" "}
                        <span className="not-reservation-text_bold">
                          Covifactura
                        </span>{" "}
                        .{" "}
                      </Col>
                      <Col
                        xs={12}
                        md={{ span: 5, offset: 1 }}
                        className="reserve-aproved-button-container"
                      >
                        <Button
                          className="button-primary"
                          variant="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            this.resetAdvanceAgreements();
                          }}
                          block
                        >
                          Realizar nuevo pago anticipado
                        </Button>
                      </Col>
                      <Col
                        xs={12}
                        md={5}
                        className="reserve-aproved-button-container"
                      >
                        <Button
                          className="button-send"
                          variant="success"
                          onClick={(e) => {
                            e.preventDefault();
                            this.exportFile();
                          }}
                          block
                        >
                          Reporte de Acuerdos
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Col>
            )}
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { user, globals } = state.user;
  let {
    loading,
    advanceAgreements,
    advanceAgreementsPageCount,
    uploadAdvanceAgreements,
    loadingPaginate,
  } = state.advanceAgreements;

  let { loading: exonerationLoading, exportExonerations } = state.exoneration;

  return {
    globals,
    loading,
    user,
    advanceAgreements,
    advanceAgreementsPageCount,
    uploadAdvanceAgreements,
    loadingPaginate,
    exportExonerations,
    exonerationLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceAgreements);
