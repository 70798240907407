import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import { Col, Button, Modal, Row } from 'react-bootstrap';

class TotalModal extends Component {

  cancel = () => {
    this.props.onHide();
  }

  confirm = () => {
    this.props.confirmExoneration();
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  getTotalvalue() {
    const {
      exonerations
    } = this.props;
    let total = 0;
    for (let key in exonerations) {
      if (exonerations.hasOwnProperty(key)) {
        total+= parseInt(exonerations[key].VALOR_A_EXONERAR);
      }
    }
    return total;
  }

  render() {
    const {
      exonerations,
      show,
      onHide,
    } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="partial-modal-title">
            Validar información
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="confirm-reserve-body">
            <Col xs={12} className="confirm-reserve_body-description">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Número de facturas:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">{Object.keys(exonerations).length}</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Total facturas:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">${this.getNumber(this.getTotalvalue())}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <Button
                    className="button-primary"
                    variant="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        this.cancel();
                    }}
                    block
                  >
                    Corregir
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className="button-send"
                    variant="success"
                    block
                    onClick={(e) => {
                        e.preventDefault();
                        this.confirm();
                    }}
                    >
                    Exonerar total
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

TotalModal.propTypes = {
  exonerations: PropTypes.object,
  confirmExoneration: PropTypes.func.isRequired,
};

TotalModal.defaultProps = {
  exonerations: [],
  confirmExoneration: () => {},
};

export default TotalModal;
