import config from '../../config';
import { authHeader } from '../../helpers';

export const getDocuments = () => {
  const requestOptions = {
    method: 'GET'
  };
  return fetch(`${config.apiUrl}/listado_documentos`, requestOptions).then(handleResponse);
}

export const getTerms = ({documentType, documentNumber, termsType}) => {
  const headers = authHeader()
  const requestOptions = {
    method: 'GET',
    headers
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais = ('PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]) ? globals.PAIS[0].ID_PAIS : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&id_documento=${termsType}`
  return fetch(`${config.apiUrl}/pr_exportar_documento_legal?${finalParams}`, requestOptions).then(handleResponseHtml);
}

export const getPublicTerms = ({termsType}) => {
  const requestOptions = {
    method: 'GET'
  };
  let finalParams = `id_documento=${termsType}`
  return fetch(`${config.apiUrl}/exportar_documento_legal?${finalParams}`, requestOptions).then(handleResponseHtml);
}

export const getTermsAcepted = ({documentType, documentNumber, userEmail, userDocumentType, userDocumentNumber}) => {
  const headers = authHeader()
  const requestOptions = {
    method: 'GET',
    headers
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais = ('PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]) ? globals.PAIS[0].ID_PAIS : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&usuario=${userEmail}&tipo_documento_usuario=${userDocumentType}&documento_usuario=${userDocumentNumber}&id_documento=[1]`
  return fetch(`${config.apiUrl}/pr_consulta_aceptacion_doc_legal?${finalParams}`, requestOptions).then(handleResponse);
}

export const setTermsAcepted = ({documentType, documentNumber, userEmail, userDocumentType, userDocumentNumber, termsType}) => {
  const headers = authHeader()
  const requestOptions = {
    method: 'POST',
    headers
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais = ('PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]) ? globals.PAIS[0].ID_PAIS : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${documentType}&documento=${documentNumber}&id_documento=${termsType}&usuario=${userEmail}&tipo_documento_usuario=${userDocumentType}&documento_usuario=${userDocumentNumber}`
  return fetch(`${config.apiUrl}/pr_aceptacion_documentos_legales?${finalParams}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = (text && response.ok) && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        window.location.reload(true);
      }
      let errorMessage = false
      if (response.status === 400) {
        errorMessage = response.headers.get('mensaje_error');
      }
      let errorArray = {
        status: response.status,
        text: (data && data.message) || response.statusText,
        errorMessage
      }
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}

function handleResponseHtml(response) {
  return response.text().then(html => {
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        window.location.reload(true);
      }
      let errorMessage = false
      if (response.status === 400) {
        errorMessage = response.headers.get('mensaje_error');
      }
      let errorArray = {
        status: response.status,
        text: response.statusText,
        errorMessage
      }
      const error = errorArray;
      return Promise.reject(error);
    }

    return html;
  });
}
