import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './transactions.scss';
import * as actions from '../../redux/actions'
import { Col, Button, Row, Table } from 'react-bootstrap';
// import Footer from '../footer';
// import Header from '../header';
// import Menu from '../menu';
// import Chat from '../chat';
// import UserHeader from '../user-header';
// import arrowback from '../../assets/icons/arrowback.svg';
import uploadtransaction from '../../assets/icons/uploadtransaction.svg';
import clock from '../../assets/icons/clock.svg';
import checkicon from '../../assets/icons/checkicon.svg';
import closeIcon from '../../assets/icons/redequis.svg';
import {TextField} from '@material-ui/core';
import Loading from '../loading';
import moment from "moment";
import FileSaver from 'file-saver';

moment.locale("es");
class MassiveTransaction extends Component {

  constructor() {
    super();
    this.state = {
      menuOpen: false,
      firstFile: false,
      secondFile: false,
      editArray: {},
      exportType: '',
      fileError: false
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.masiveFirst = React.createRef();
    this.masiveSecond = React.createRef();
  }

  goHome = () => {
    const {
      bulkTransactionResponse,
      bulkEmailTransactionResponse,
    } = this.props;
    this.props.actions.resetQuota();
    if(!bulkTransactionResponse && !bulkEmailTransactionResponse) {
      this.props.history.push(`/`);
    }
  }

  resetTab() {
    this.setState({
      menuOpen: false,
      firstFile: false,
      secondFile: false,
      editArray: {},
      exportType: '',
      fileError: false
    })
    this.masiveFirst.value = ''
    this.masiveSecond.value = ''

    this.props.actions.resetQuota();
  }

  errorFile() {
    this.setState({
      editArray: {},
      exportType: '',
      fileError: true
    })
    this.props.actions.resetQuota();
  }

  saveTransactionFile(excelBlob) {
    const {
      exportType
    } = this.state;
    FileSaver.saveAs(excelBlob, `reporteTransacciones${exportType}s.xlsx`);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.bulkTransactionResponse && nextProps.bulkTransactionResponse !== this.props.bulkTransactionResponse) {
      const { bulkTransactionResponse } = nextProps;
      let errorLenght = "REGISTROS_ERROR" in bulkTransactionResponse ? bulkTransactionResponse.REGISTROS_ERROR.length : 0
      let succesLenght = "REGISTROS_CORRECTOS" in bulkTransactionResponse ? bulkTransactionResponse.REGISTROS_CORRECTOS.length : 0
      if(errorLenght === 0 && succesLenght === 0) {
        this.errorFile()
      } else {
        if(bulkTransactionResponse && "REGISTROS_ERROR" in bulkTransactionResponse) {
          let editArray = {};
          for (let index in bulkTransactionResponse.REGISTROS_ERROR) {
            editArray[index] = bulkTransactionResponse.REGISTROS_ERROR[index];
          }
          this.setState({
            editArray
          })
        }
      }
    }
    if (nextProps.exportTransactions) {
      this.props.actions.resetExportTransactionReport()
      this.saveTransactionFile(nextProps.exportTransactions)
    }
  }

  componentDidMount() {
    this.props.provideCtrl({
      resetTab: () => this.resetTab()
    });
    this.props.actions.getUser();
    this.props.actions.getDocuments();
  }

  closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true
    });
  }

  openSelector = () => {
    this.masiveFirst.click();
  }

  openSecondSelector = () => {
    this.masiveSecond.click();
  }

  handleFileChange = (firstFile) => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    // window.gtag('event', 'carga_archivo_opt1', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    this.setState({
      firstFile: firstFile[0],
      fileError: false
    });
  }

  handleSecondFileChange = (secondFile) => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    // window.gtag('event', 'carga_archivo_opt2', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    this.setState({
      secondFile: secondFile[0]
    });
  }

  sendMasiveTransaction = (check) => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    // window.gtag('event', 'envio_transaccionmasiva', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    const {
      user,
      bulkTransactionResponse
    } = this.props;
    const {
      firstFile,
      editArray
    } = this.state;
    this.setState({
      confirmModal: false,
      fileError: false
    });
    let correctionsArray = null;
    if(bulkTransactionResponse) {
      correctionsArray = {CARGA: {
        REGISTROS_CORRECTOS: []
      }};
      for (let index in bulkTransactionResponse.REGISTROS_CORRECTOS) {
        correctionsArray.CARGA.REGISTROS_CORRECTOS.push(bulkTransactionResponse.REGISTROS_CORRECTOS[index]);
      }
      for (let key in editArray) {
        if (editArray.hasOwnProperty(key)) {
          if(!editArray[key].deleted) {
            correctionsArray.CARGA.REGISTROS_CORRECTOS.push(editArray[key]);
          }
        }
      }
      correctionsArray = JSON.stringify(correctionsArray);
    }
    this.props.actions.sendMasiveTransaction({
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      file: firstFile,
      correctionsArray,
      check: check ? 0 : 1
    });
  }

  sendMasiveTransactionEmail = (check) => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    // window.gtag('event', 'envio_transaccionmasivaemail', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    const {
      user
    } = this.props;
    const {
      secondFile,
    } = this.state;
    this.props.actions.sendMasiveTransactionEmail({
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userEmail: user.userEmail,
      file: secondFile,
    });

    this.setState({
      secondFile: false
    }, () => {
      this.masiveSecond.files = null;
      this.masiveSecond.value = "";
    })
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  onEditChange = (e, index, key, item, deleted) => {
    let editArray = Object.assign([], this.state.editArray);
    if(!(index in editArray)){
      editArray[index] = item;
    }
    if(deleted){
      editArray[index][key] = true;
    } else {
      editArray[index][key] = e.target.value;
    }
    this.setState({
      editArray
    }, () => {
      if(deleted) {
        this.sendMasiveTransaction();
      }
    });
  }

  openLink = () => {
    const {documents} = this.props;
    var document = documents.find((element) => {
      return element.DOCUMENTO === "Formato Carga Masiva";
    });
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    // window.gtag('event', 'descarga_formatocargamasiva', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    window.open(document.LINK,'_newtab');
  }

  handleBlur = () => {
    this.sendMasiveTransaction();
  }

  exportFile = (exportType, exportArray) => {

    if(exportArray.length > 0) {
      this.setState({
        exportType
      }, () => {
        let numberFiled = exportArray[0].RADICACION
        const {
          user
        } = this.props;
        this.props.actions.exportMasiveTransaction({
          userDocument: user.userInfo.DOCUMENTO,
          userDocumentType: user.userInfo.TIPO_DOCUMENTO,
          numberFiled,
          status: exportType
        });
      })
    }
  }

  render() {
    const {
      firstFile,
      secondFile,
      editArray,
      fileError,
    } = this.state;
    const {
      loading,
      bulkTransactionResponse,
      bulkTransactionFinalResponse,
      bulkEmailTransactionResponse
    } = this.props;
    let bulkTransactionFinalResponseError = []
    let bulkTransactionFinalResponseSucces = []
    if (bulkTransactionFinalResponse) {
      bulkTransactionFinalResponseError = bulkTransactionFinalResponse.filter((item) => {
        return item.AUTORIZACION === "0" || item.AUTORIZACION === "";
      });
      bulkTransactionFinalResponseSucces = bulkTransactionFinalResponse.filter((item) => {
        return !(item.AUTORIZACION === "0" || item.AUTORIZACION === "");
      });
    }
    return (
      <div className="transactions">
        <input
          style={{display: "none"}}
          ref={input => this.masiveFirst = input}
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={ (e) => this.handleFileChange(e.target.files) }
        />
        <input
          style={{display: "none"}}
          ref={input => this.masiveSecond = input}
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={ (e) => this.handleSecondFileChange(e.target.files) }
        />
      { !bulkTransactionResponse && (
          <Col xs={12} className="">
            { loading && (
              <Loading />
            )}
            <Col xs={12} className="transactions-margin">
              <Col xs={12} className="card-sub-title-masive transactions-margin">
                Para realizar transacciones masivas, puede hacerlo con cualquier de estas dos opciones.
              </Col>
            </Col>
            <Col xs={12}>
              <Col xs={12} className="transactions-margin">
                <Row>
                  <Col xs={12} className="option-title">
                    Opción 1. Hágalo usted mismo
                  </Col>
                  <Col xs={12}>
                    <Row>
                      <Col xs="auto">
                        <img src={uploadtransaction} alt="Trasacción masiva icono" className="upload-transaction-icon" />
                      </Col>
                      <Col>
                        { firstFile && (
                          <Row>
                            <Col
                              xs={12}
                              md={6}
                              onClick={(e) => {
                                  e.preventDefault();
                                  this.openSelector();
                              }}
                              className="login-input-container set-pointer">
                              <TextField
                                id="uploadedFile"
                                label="Archivo cargado"
                                value={firstFile.name}
                                className="home-input set-pointer"
                                margin="dense"
                                disabled
                                fullWidth
                                />
                            </Col>
                            <Col xs={12} md={5}>
                              {fileError ? (
                                <Button
                                  className="button-send"
                                  variant="success"
                                  onClick={(e) => {
                                      e.preventDefault();
                                      this.openSelector();
                                  }}
                                  block
                                >
                                  Cargar archivo
                                </Button>
                              ) : (
                                <Button
                                  className="button-send"
                                  variant="success"
                                  onClick={(e) => {
                                      e.preventDefault();
                                      this.sendMasiveTransaction();
                                  }}
                                  block
                                >
                                  Realizar transacción
                                </Button>
                              )}

                            </Col>
                            {fileError ? (
                              <Col xs={12} className="card-sub-title red">
                                El archivo no puede estar vacío
                              </Col>
                            ) : (
                              <Col xs={12} className="card-sub-title">
                                Haga clic en realizar transacción
                              </Col>
                            )}
                          </Row>
                        )}
                        {!firstFile && (
                          <Row>
                            <Col xs={12} md={8} className="option-description">
                              <span
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.openLink();
                                }}
                                className="option-link">Descargue aquí el formato de transacción masiva</span>, complete los datos solicitados cárguelo a la plataforma en el siguiente botón.
                            </Col>
                            <Col xs={12} md={6}>
                              <Button
                                className="button-send"
                                variant="success"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.openSelector();
                                }}
                                block
                              >
                                Cargar archivo
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}></Col>
                </Row>
              </Col>
              <Col xs={12} className="transactions-margin">
                <Row>
                  <Col xs={12} className="option-title">
                    Opción 2. Nosotros le ayudamos
                  </Col>
                  <Col xs={12}>
                    <Row>
                      <Col xs="auto">
                        <img src={clock} alt="reloj icono" className="upload-transaction-clock-icon" />
                      </Col>
                      <Col>
                        { secondFile && (
                          <Row className="top-space">
                            <Col xs={12} md={6} className="login-input-container">
                              <TextField
                                id="uploadedFile"
                                label="Archivo cargado"
                                value={secondFile.name}
                                className="home-input"
                                margin="dense"
                                disabled
                                fullWidth
                                />
                            </Col>
                            <Col xs={12} md={5}>
                              <Button
                                className="button-send"
                                variant="success"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.sendMasiveTransactionEmail();
                                }}
                                block
                              >
                                Realizar transacción
                              </Button>
                            </Col>
                            <Col xs={12} className="card-sub-title">
                              Haga clic en realizar transacción
                            </Col>
                          </Row>
                        )}
                        { !secondFile && (
                          <Row>
                            { bulkEmailTransactionResponse && (
                              <Col xs={12} className="claim-upload-title claim-upload-left check-icon-container">
                                Archivo cargado satisfactoriamente
                                <img src={checkicon} alt="chulo icono" className="check-icon"/>
                              </Col>
                            )}
                              { !bulkEmailTransactionResponse && (
                                <Col xs={12} md={8} className="option-description">
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.openLink();
                                    }}
                                    className="option-link">Descargue aquí el formato de transacción masiva</span>, complete los datos solicitados cárguelo a la plataforma en el siguiente botón. En 24 horas le daremos respuesta sobre la carga de su información.

                                  </Col>
                              )}
                              { !bulkEmailTransactionResponse && (
                                <Col xs={12} md={6}>
                                  <Button
                                    className="button-send"
                                    variant="success"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.openSecondSelector();
                                    }}
                                    block>
                                    Cargar archivo
                                  </Button>
                                </Col>
                              )}
                            </Row>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}></Col>
                </Row>
              </Col>
            </Col>
          </Col>
        )}
        { (bulkTransactionResponse && !bulkTransactionFinalResponse) && (
          <Col xs={12} className="">
            { loading && (
              <Loading />
            )}
            <Col xs={12}>
              <Row>
                <Col xs={12} className="errors-title">
                  Registros errados {bulkTransactionResponse.REGISTROS_ERROR.length}
                </Col>
                <Col xs={12} className="results-table-container">
                  <Table bordered responsive="sm">
                    <thead>
                      <tr>
                        <th>Tipo doc/to</th>
                        <th>Número documento</th>
                        <th>Número factura</th>
                        <th>Valor factura</th>
                        <th>Fecha vencimiento</th>
                        <th>Código consulta</th>
                        <th className="delete">Borrar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bulkTransactionResponse.REGISTROS_ERROR.map((item, index) => {
                        if( index in editArray && editArray[index].deleted) {
                            return (
                              ""
                            );
                        }
                        return (
                          <tr key={index}>
                            <td>
                              <div className={'TIPO_DOCUMENTO' in item.MENSAJE ? 'error-value' : ''}>
                                <input className="table-input" type="text" onBlur={this.handleBlur} onChange={(e) => { this.onEditChange(e, index, "TIPO_DOCUMENTO", item)}} value={ index in editArray ? editArray[index].TIPO_DOCUMENTO : item.TIPO_DOCUMENTO} />
                              </div>
                              {'TIPO_DOCUMENTO' in item.MENSAJE && (
                                <div className="error-text">{item.MENSAJE.TIPO_DOCUMENTO}</div>
                              )}
                            </td>
                            <td>
                              <div className={'DOCUMENTO' in item.MENSAJE ? 'error-value' : ''}><input className="table-input" type="number" onBlur={this.handleBlur} onChange={(e) => { this.onEditChange(e, index, "DOCUMENTO", item)}} value={ index in editArray ? editArray[index].DOCUMENTO : item.DOCUMENTO} /></div>
                              {'DOCUMENTO' in item.MENSAJE && (
                                <div className="error-text">{item.MENSAJE.DOCUMENTO}</div>
                              )}
                            </td>
                            <td>
                              <div className={'TITULO_VALOR' in item.MENSAJE ? 'error-value' : ''}>
                                <input className="table-input" type="text" onBlur={this.handleBlur} onChange={(e) => { this.onEditChange(e, index, "TITULO_VALOR", item)}} value={ index in editArray ? editArray[index].TITULO_VALOR : item.TITULO_VALOR} />
                              </div>
                              {'TITULO_VALOR' in item.MENSAJE && (
                                <div className="error-text">{item.MENSAJE.TITULO_VALOR}</div>
                              )}
                            </td>
                            <td>
                              <div className={'VALOR' in item.MENSAJE ? 'error-value' : ''}>
                                <input className="table-input" type="text" onBlur={this.handleBlur} onChange={(e) => { this.onEditChange(e, index, "VALOR", item)}} value={ index in editArray ? editArray[index].VALOR : item.VALOR} />
                              </div>
                              {'VALOR' in item.MENSAJE && (
                                <div className="error-text">{item.MENSAJE.VALOR}</div>
                              )}
                            </td>
                            <td>
                              <div className={'FECHA' in item.MENSAJE ? 'error-value' : ''}>
                                <input className="table-input" type="text" onBlur={this.handleBlur} onChange={(e) => { this.onEditChange(e, index, "FECHA", item)}} value={ index in editArray ? editArray[index].FECHA : item.FECHA} />
                              </div>
                              {'FECHA' in item.MENSAJE && (
                                <div className="error-text">{item.MENSAJE.FECHA}</div>
                              )}
                            </td>
                            <td>
                              <div className={'CODIGO_CONSULTA' in item.MENSAJE ? 'error-value' : ''}>
                                <input className="table-input" type="text" onBlur={this.handleBlur} onChange={(e) => { this.onEditChange(e, index, "CODIGO_CONSULTA", item)}} value={ index in editArray ? editArray[index].CODIGO_CONSULTA : item.CODIGO_CONSULTA} />
                              </div>
                              {'CODIGO_CONSULTA' in item.MENSAJE && (
                                <div className="error-text">{item.MENSAJE.CODIGO_CONSULTA}</div>
                              )}
                            </td>
                            <td>
                              <button
                                className="close"
                                onClick={(e) => {
                                  e.preventDefault()
                                  this.onEditChange(e, index, "deleted", item, true)
                                }}
                                >
                                <img src={closeIcon} alt="close icon"/>
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Col>
                {/*
                  <Col xs={12} md={{span: 4, offset:4}}>
                  <Button
                  className="button-send"
                  variant="success"
                  disabled={bulkTransactionResponse.REGISTROS_ERROR.length === 0}
                  onClick={(e) => {
                  e.preventDefault();
                  this.sendMasiveTransaction();
                  }}
                  block
                  >
                  Guardar
                  </Button>
                  </Col>
                  */}
              </Row>
              <Row>
                <Col xs={12} className="success-title">
                  Registros exitosos {bulkTransactionResponse.REGISTROS_CORRECTOS.length}
                </Col>
                <Col xs={12} className="results-table-container">
                  <Table bordered responsive="sm">
                    <thead>
                      <tr>
                        <th>Tipo doc/to</th>
                        <th>Número documento</th>
                        <th>Número factura</th>
                        <th>Valor factura</th>
                        <th>Fecha vencimiento</th>
                        <th>Código consulta</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bulkTransactionResponse.REGISTROS_CORRECTOS.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.TIPO_DOCUMENTO}</td>
                            <td>{item.DOCUMENTO}</td>
                            <td>{item.TITULO_VALOR}</td>
                            <td>${this.getNumber(item.VALOR)}</td>
                            <td>{item.FECHA}</td>
                            <td>{item.CODIGO_CONSULTA}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Col>
                <Col xs={12} md={{span: 4, offset:4}}>
                  <Button
                    className="button-send"
                    variant="success"
                    disabled={bulkTransactionResponse.REGISTROS_ERROR.length > 0}
                    onClick={(e) => {
                        e.preventDefault();
                        this.sendMasiveTransaction(true);
                    }}
                    block
                  >
                  Realizar transacción
                  </Button>
                </Col>
              </Row>
            </Col>
          </Col>
        )}
        { bulkTransactionFinalResponse && (
          <Col xs={12} className="">
            { loading && (
              <Loading />
            )}
            <Col xs={12}>
              <Row>
                <Col xs={12} className="errors-title">
                  Transacciones  negadas {bulkTransactionFinalResponseError.length}
                </Col>
                <Col xs={12} className="results-table-container">
                  <Table bordered responsive="sm">
                    <thead>
                      <tr>
                        <th>Tipo doc/to</th>
                        <th>Número documento</th>
                        <th>Número factura</th>
                        <th>Valor factura</th>
                        <th>Fecha vencimiento</th>
                        <th className="denegate-th">Negadas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bulkTransactionFinalResponseError.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="denegate">
                              {item.TIPO_DOCUMENTO}
                            </td>
                            <td className="denegate cross-out">
                              {item.DOCUMENTO}
                            </td>
                            <td className="denegate cross-out">
                              {item.NUMERO_CHQ}
                            </td>
                            <td className="denegate cross-out">
                              ${this.getNumber(item.VALOR)}
                            </td>
                            <td className="denegate cross-out">
                              {item.FECHA === '' ? '' : moment(item.FECHA).format('DD/MM/YYYY')}
                            </td>
                            <td className="denegate">
                              {item.OBSERVACION}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="download-result-file_container">
                  <button className="download-result-file" onClick={(e) => {
                      e.preventDefault();
                      let userJson = JSON.parse(localStorage.getItem('user'));
                      let globalsJson = JSON.parse(localStorage.getItem('globals'));
                      let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
                      // window.gtag('event', 'descargar_negados', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
                      this.exportFile('Negado', bulkTransactionFinalResponseError);
                    }}>
                    Descargar archivos negados
                  </button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="approved-title">
                  Transacciones  aprobadas {bulkTransactionFinalResponseSucces.length}
                </Col>
                <Col xs={12} className="results-table-container">
                  <Table bordered responsive="sm">
                    <thead>
                      <tr>
                        <th>Tipo doc/to</th>
                        <th>Número documento</th>
                        <th>Número factura</th>
                        <th>Valor factura</th>
                        <th>Fecha vencimiento</th>
                        <th className="approved-th">Código aprobación</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bulkTransactionFinalResponseSucces.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.TIPO_DOCUMENTO}</td>
                            <td>{item.DOCUMENTO}</td>
                            <td>{item.NUMERO_CHQ}</td>
                            <td>${this.getNumber(item.VALOR)}</td>
                            <td>{item.FECHA === '' ? '' : moment(item.FECHA).format('DD/MM/YYYY')}</td>
                            <td className="approved-td">{item.AUTORIZACION}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Col>
                <Col xs={12} md={{span: 4, offset:4}}>
                  <Button
                    className="button-send"
                    variant="success"
                    onClick={(e) => {
                        e.preventDefault();
                        this.goHome();
                    }}
                    block
                  >
                  Ir al inicio
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="download-result-file_container">
                  <button className="download-result-file" onClick={(e) => {
                      e.preventDefault();
                      let userJson = JSON.parse(localStorage.getItem('user'));
                      let globalsJson = JSON.parse(localStorage.getItem('globals'));
                      let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
                      // window.gtag('event', 'descargar_aprobados', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
                      this.exportFile('Aprobado', bulkTransactionFinalResponseSucces);
                    }}>
                    Descargar archivos aceptados
                  </button>
                </Col>
              </Row>
            </Col>
          </Col>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
      loading,
      bulkTransactionResponse,
      bulkTransactionFinalResponse,
      bulkTransactionError,
      bulkEmailTransactionResponse,
      bulkEmailTransactionError,
      exportTransactions,
  } = state.transaction;
  let {
      user
  } = state.user;
  let {
      documents,
  } = state.document;
  return {
    loading,
    bulkTransactionResponse,
    bulkTransactionFinalResponse,
    bulkTransactionError,
    bulkEmailTransactionResponse,
    bulkEmailTransactionError,
    user,
    documents,
    exportTransactions,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MassiveTransaction);
