import * as userService from '../services';
import {
  RESET_ALL,
  SET_USERDATA,
  GET_USERDATA,
  GET_GLOBALDATA_REQUEST,
  GET_GLOBALDATA_SUCCESS,
  GET_GLOBALDATA_FAILURE,
  GET_ROLPERMISSIONS_REQUEST,
  GET_ROLPERMISSIONS_SUCCESS,
  GET_ROLPERMISSIONS_FAILURE,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
  GET_CLIENTTYPES_REQUEST,
  GET_CLIENTTYPES_SUCCESS,
  GET_CLIENTTYPES_FAILURE,
  SET_BIRTHDATE_REQUEST,
  SET_BIRTHDATE_SUCCESS,
  SET_BIRTHDATE_FAILURE,
  SEND_FILE_REQUEST,
  SEND_FILE_SUCCESS,
  SEND_FILE_FAILURE,
  GET_FAMILYLIST_REQUEST,
  GET_FAMILYLIST_SUCCESS,
  GET_FAMILYLIST_FAILURE,
  GET_CAREERLIST_REQUEST,
  GET_CAREERLIST_SUCCESS,
  GET_CAREERLIST_FAILURE,
  GET_ISUSER_REQUEST,
  GET_ISUSER_SUCCESS,
  GET_ISUSER_FAILURE,
  GET_RECOVER_REQUEST,
  GET_RECOVER_SUCCESS,
  GET_RECOVER_FAILURE,
  GET_REMEMBER_REQUEST,
  GET_REMEMBER_SUCCESS,
  GET_REMEMBER_FAILURE,
  GET_CHANGE_REQUEST,
  GET_CHANGE_SUCCESS,
  GET_CHANGE_FAILURE,
  GET_CHANGEPASSWORD_REQUEST,
  GET_CHANGEPASSWORD_SUCCESS,
  GET_CHANGEPASSWORD_FAILURE,
  GET_MYPAYMENTS_REQUEST,
  GET_MYPAYMENTS_SUCCESS,
  GET_MYPAYMENTS_FAILURE,
  GET_MYPAYMENT_INVOICE_REQUEST,
  GET_MYPAYMENT_INVOICE_SUCCESS,
  GET_MYPAYMENT_INVOICE_FAILURE,
  GET_MANAGEDUSERS_REQUEST,
  GET_MANAGEDUSERS_SUCCESS,
  GET_MANAGEDUSERS_FAILURE,
  GET_AFFILIATE_REQUEST,
  GET_AFFILIATE_SUCCESS,
  GET_AFFILIATE_FAILURE,
  GET_AFFILIATE_TOKEN_REQUEST,
  GET_AFFILIATE_TOKEN_SUCCESS,
  GET_AFFILIATE_TOKEN_FAILURE,
  SET_MANAGEDUSERS_REQUEST,
  SET_MANAGEDUSERS_SUCCESS,
  SET_MANAGEDUSERS_FAILURE,
  RESET_MANAGEDUSERS,
  GET_ROLELIST_REQUEST,
  GET_ROLELIST_SUCCESS,
  GET_ROLELIST_FAILURE,
  GET_PAYMENTDETAIL_REQUEST,
  GET_PAYMENTDETAIL_SUCCESS,
  GET_PAYMENTDETAIL_FAILURE,
  GET_PAYMENTLINK_REQUEST,
  GET_PAYMENTLINK_SUCCESS,
  GET_PAYMENTLINK_FAILURE,
  RESET_PAYMENTDETAIL_REQUEST,
  GET_MYSTATISTICS_REQUEST,
  GET_MYSTATISTICS_SUCCESS,
  GET_MYSTATISTICS_FAILURE,
  REQUEST_CLIENTSTUDY_REQUEST,
  REQUEST_CLIENTSTUDY_SUCCESS,
  REQUEST_CLIENTSTUDY_FAILURE,
  REQUEST_THREE_PAYMENTS_CLIENTSTUDY_REQUEST,
  REQUEST_THREE_PAYMENTS_CLIENTSTUDY_SUCCESS,
  REQUEST_THREE_PAYMENTS_CLIENTSTUDY_FAILURE,
  SAVE_FORM_REQUEST,
  SAVE_FORM_SUCCESS,
  SAVE_FORM_FAILURE,
  GET_VALIDATE_FORM_REQUEST,
  GET_VALIDATE_FORM_SUCCESS,
  GET_VALIDATE_FORM_FAILURE,
  REQUEST_PUBLICCLIENTSTUDY_REQUEST,
  REQUEST_PUBLICCLIENTSTUDY_SUCCESS,
  REQUEST_PUBLICCLIENTSTUDY_FAILURE,
  SEND_CLIENTLINK_REQUEST,
  SEND_CLIENTLINK_SUCCESS,
  SEND_CLIENTLINK_FAILURE,
  SEND_HASH_REQUEST,
  SEND_HASH_SUCCESS,
  SEND_HASH_FAILURE,
  SEND_PUBLIC_HASH_REQUEST,
  SEND_PUBLIC_HASH_SUCCESS,
  SEND_PUBLIC_HASH_FAILURE,
  RESET_CLIENTLINK,
  RESET_AFFILIATE_TOKEN,
  GET_ISUSER_CLEAN,
  GENERAL_ERROR,
} from '../action-types';

export const getAppGlobals = (reload) => {
  return (dispatch) => {
    dispatch(request());
    let globals = JSON.parse(localStorage.getItem('globals'));
    if (!reload && globals) {
      dispatch(success(globals));
    } else {
      userService.getGlobals().then(
        (data) => {
          localStorage.setItem('globals', JSON.stringify(data));
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
    }
  };

  function request() {
    return { type: GET_GLOBALDATA_REQUEST };
  }
  function success(data) {
    return { type: GET_GLOBALDATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_GLOBALDATA_FAILURE, error };
  }
};

export const getRolPermissions = ({
  documentType,
  documentNumber,
  idRol,
  reload,
}) => {
  return (dispatch) => {
    dispatch(request());
    let permissions = JSON.parse(localStorage.getItem('permissions'));
    if (!reload && permissions) {
      dispatch(success(permissions));
    } else {
      userService
        .getRolPermissions({ documentType, documentNumber, idRol })
        .then(
          (data) => {
            localStorage.setItem('permissions', JSON.stringify(data));
            dispatch(success(data));
          },
          (error) => {
            if (error.errorMessage) {
              dispatch(generalError(error.errorMessage));
            }
            dispatch(failure(error));
          }
        );
    }
  };

  function request() {
    return { type: GET_ROLPERMISSIONS_REQUEST };
  }
  function success(data) {
    return { type: GET_ROLPERMISSIONS_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_ROLPERMISSIONS_FAILURE, error };
  }
};

export const getCities = ({ departmentId, cityArray }) => {
  return (dispatch) => {
    dispatch(request());
    userService.getCities({ departmentId }).then(
      (data) => {
        dispatch(success(data, cityArray));
      },
      (error) => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_CITIES_REQUEST };
  }
  function success(data, cityArray) {
    return { type: GET_CITIES_SUCCESS, data, cityArray };
  }
  function failure(error) {
    return { type: GET_CITIES_FAILURE, error };
  }
};

export const getClientTypes = ({ documentType, documentNumber }) => {
  return (dispatch) => {
    dispatch(request());
    userService.getClientTypes({ documentType, documentNumber }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_CLIENTTYPES_REQUEST };
  }
  function success(data) {
    return { type: GET_CLIENTTYPES_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_CLIENTTYPES_FAILURE, error };
  }
};

export const setBirthDate = ({
  userEmail,
  documentType,
  documentNumber,
  birthDate,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .setBirthDate({ userEmail, documentType, documentNumber, birthDate })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: SET_BIRTHDATE_REQUEST };
  }
  function success(data) {
    return { type: SET_BIRTHDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: SET_BIRTHDATE_FAILURE, error };
  }
};

export const sendFile = ({
  documentType,
  documentNumber,
  fileType,
  sellerDocumentType,
  sellerDocumentNumber,
  file,
  quotaNumber = null,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .sendFile({
        documentType,
        documentNumber,
        fileType,
        sellerDocumentType,
        sellerDocumentNumber,
        file,
        quotaNumber,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: SEND_FILE_REQUEST, fileType };
  }
  function success(data) {
    return { type: SEND_FILE_SUCCESS, data, fileType };
  }
  function failure(error) {
    return { type: SEND_FILE_FAILURE, error, fileType };
  }
};

export const sendPublicFile = ({
  fileType,
  sellerDocumentType,
  sellerDocumentNumber,
  file,
  quotaNumber,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .sendPublicFile({
        fileType,
        sellerDocumentType,
        sellerDocumentNumber,
        file,
        quotaNumber,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: SEND_FILE_REQUEST };
  }
  function success(data) {
    return { type: SEND_FILE_SUCCESS, data };
  }
  function failure(error) {
    return { type: SEND_FILE_FAILURE, error };
  }
};

export const getFamilyList = (reload) => {
  return (dispatch) => {
    dispatch(request());
    let familyList = false;
    try {
      familyList = JSON.parse(localStorage.getItem('familyList'));
    } catch (e) {}
    if (!reload && familyList) {
      dispatch(success(familyList));
    } else {
      userService.getFamilyList().then(
        (data) => {
          localStorage.setItem('familyList', JSON.stringify(data.PARENTESCO));
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
    }
  };

  function request() {
    return { type: GET_FAMILYLIST_REQUEST };
  }
  function success(data) {
    return { type: GET_FAMILYLIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_FAMILYLIST_FAILURE, error };
  }
};

export const getCareerList = (reload) => {
  return (dispatch) => {
    dispatch(request());
    let careerList = false;
    try {
      careerList = JSON.parse(localStorage.getItem('careerList'));
    } catch (e) {}
    if (!reload && careerList) {
      dispatch(success(careerList));
    } else {
      userService.getCareerList().then(
        (data) => {
          localStorage.setItem('careerList', JSON.stringify(data.CARRERAS));
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
    }
  };

  function request() {
    return { type: GET_CAREERLIST_REQUEST };
  }
  function success(data) {
    return { type: GET_CAREERLIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_CAREERLIST_FAILURE, error };
  }
};

export const isUser = ({ userEmail, password }) => {
  return (dispatch) => {
    dispatch(request());
    userService.isUser({ userEmail, password }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        // if (error.errorMessage) {
        //   dispatch(generalError(error.errorMessage));
        // }
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_ISUSER_REQUEST };
  }
  function success(data) {
    return { type: GET_ISUSER_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_ISUSER_FAILURE, error };
  }
};

export const recoverPassword = ({ userEmail }) => {
  return (dispatch) => {
    dispatch(request());
    userService.recoverPassword({ userEmail }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_RECOVER_REQUEST };
  }
  function success(data) {
    return { type: GET_RECOVER_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_RECOVER_FAILURE, error };
  }
};

export const rememberPassword = ({ userEmail, rememberType }) => {
  return (dispatch) => {
    dispatch(request());
    userService.rememberPassword({ userEmail, rememberType }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_REMEMBER_REQUEST };
  }
  function success(data) {
    return { type: GET_REMEMBER_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_REMEMBER_FAILURE, error };
  }
};

export const changePassword = ({ password, passwordRepeat, code }) => {
  return (dispatch) => {
    dispatch(request());
    userService.changePassword({ password, passwordRepeat, code }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_CHANGE_REQUEST };
  }
  function success(data) {
    return { type: GET_CHANGE_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_CHANGE_FAILURE, error };
  }
};

export const changeUserPassword = ({
  userEmail,
  documentType,
  documentNumber,
  password,
  passwordRepeat,
  currentPassword,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .changeUserPassword({
        userEmail,
        documentType,
        documentNumber,
        password,
        passwordRepeat,
        currentPassword,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_CHANGEPASSWORD_REQUEST };
  }
  function success(data) {
    return { type: GET_CHANGEPASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_CHANGEPASSWORD_FAILURE, error };
  }
};

export const getMyPayments = ({ documentType, documentNumber }) => {
  return (dispatch) => {
    dispatch(request());
    userService.getMyPayments({ documentType, documentNumber }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_MYPAYMENTS_REQUEST };
  }
  function success(data) {
    return { type: GET_MYPAYMENTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_MYPAYMENTS_FAILURE, error };
  }
};

export const getMyPaymentInvoice = ({
  suffix,
  consecutive,
  documentType,
  documentNumber,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .getMyPaymentInvoice({
        suffix,
        consecutive,
        documentType,
        documentNumber,
      })
      .then(
        (data) => {
          dispatch(
            success(
              data['S:Envelope']['S:Body']['ns2:consultarArchivosResponse'][
                'return'
              ].listaArchivos.streamFile
            )
          );
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_MYPAYMENT_INVOICE_REQUEST };
  }
  function success(data) {
    return { type: GET_MYPAYMENT_INVOICE_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_MYPAYMENT_INVOICE_FAILURE, error };
  }
};

export const getRoleList = ({ documentType, documentNumber }) => {
  return (dispatch) => {
    dispatch(request());
    userService.getRoleList({ documentType, documentNumber }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_ROLELIST_REQUEST };
  }
  function success(data) {
    return { type: GET_ROLELIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_ROLELIST_FAILURE, error };
  }
};

export const getManagedUsers = ({
  documentType,
  documentNumber,
  userDocumentType,
  userDocumentNumber,
  page,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .getManagedUsers({
        documentType,
        documentNumber,
        userDocumentType,
        userDocumentNumber,
        page,
      })
      .then(
        (data) => {
          dispatch(success(data, page));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_MANAGEDUSERS_REQUEST };
  }
  function success(data, page) {
    return { type: GET_MANAGEDUSERS_SUCCESS, data, page };
  }
  function failure(error) {
    return { type: GET_MANAGEDUSERS_FAILURE, error };
  }
};

export const getAffiliate = ({
  user,
  affiliateDocumentType,
  affiliateDocumentNumber,
  userDocumentType,
  userDocumentNumber,
  contract,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .getAffiliate({
        user,
        affiliateDocumentType,
        affiliateDocumentNumber,
        userDocumentType,
        userDocumentNumber,
        contract,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_AFFILIATE_REQUEST };
  }
  function success(data, page) {
    return { type: GET_AFFILIATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_AFFILIATE_FAILURE, error };
  }
};

export const getAffiliateToken = ({
  affiliateDocumentType,
  affiliateDocumentNumber,
  userDocumentType,
  userDocumentNumber,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .getAffiliateToken({
        affiliateDocumentType,
        affiliateDocumentNumber,
        userDocumentType,
        userDocumentNumber,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_AFFILIATE_TOKEN_REQUEST };
  }
  function success(data) {
    return { type: GET_AFFILIATE_TOKEN_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_AFFILIATE_TOKEN_FAILURE, error };
  }
};

export const manageUser = ({
  userEmail,
  documentType,
  documentNumber,
  sellerEmail,
  firstName,
  secondName,
  lastName,
  secondLastName,
  role,
  action,
  sellerDocumentType,
  sellerDocumentNumber,
  sellerCellular,
  city,
  branch,
  status,
  password,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .manageUser({
        userEmail,
        documentType,
        documentNumber,
        sellerEmail,
        firstName,
        secondName,
        lastName,
        secondLastName,
        role,
        action,
        sellerDocumentType,
        sellerDocumentNumber,
        sellerCellular,
        city,
        branch,
        status,
        password,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: SET_MANAGEDUSERS_REQUEST };
  }
  function success(data) {
    return { type: SET_MANAGEDUSERS_SUCCESS, data };
  }
  function failure(error) {
    return { type: SET_MANAGEDUSERS_FAILURE, error };
  }
};

export const resetManageUser = () => {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: RESET_MANAGEDUSERS };
  }
};

export const getPaymentDetail = ({ documentType, documentNumber, invoice }) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .getPaymentDetail({ documentType, documentNumber, invoice })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_PAYMENTDETAIL_REQUEST };
  }
  function success(data) {
    return { type: GET_PAYMENTDETAIL_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_PAYMENTDETAIL_FAILURE, error };
  }
};

export const getPaymentLink = ({
  documentType,
  documentNumber,
  invoice,
  expirationDate,
  totalAmount,
  totalTax,
  productCode,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .getPaymentLink({
        documentType,
        documentNumber,
        invoice,
        expirationDate,
        totalAmount,
        totalTax,
        productCode,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_PAYMENTLINK_REQUEST };
  }
  function success(data) {
    return { type: GET_PAYMENTLINK_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_PAYMENTLINK_FAILURE, error };
  }
};

export const resetPaymentDetail = () => {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: RESET_PAYMENTDETAIL_REQUEST };
  }
};

export const getMyStatistics = ({ documentType, documentNumber }) => {
  return (dispatch) => {
    dispatch(request());
    userService.getMyStatistics({ documentType, documentNumber }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: GET_MYSTATISTICS_REQUEST };
  }
  function success(data) {
    return { type: GET_MYSTATISTICS_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_MYSTATISTICS_FAILURE, error };
  }
};

export const requestLinkClientStudy = ({
  userName,
  documentType,
  documentNumber,
  clientEmail,
  admissionDate,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .requestLinkClientStudy({
        userName,
        documentType,
        documentNumber,
        clientEmail,
        admissionDate,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: SEND_CLIENTLINK_REQUEST };
  }
  function success(data) {
    return { type: SEND_CLIENTLINK_SUCCESS, data };
  }
  function failure(error) {
    return { type: SEND_CLIENTLINK_FAILURE, error };
  }
};

export const sendHash = ({
  userName,
  documentType,
  documentNumber,
  clientIp,
  admissionDate,
  quotaNumber,
}) => {
  return (dispatch) => {
    // dispatch(request());
    userService
      .sendHash({
        userName,
        documentType,
        documentNumber,
        clientIp,
        admissionDate,
        quotaNumber,
      })
      .then(
        (data) => {
          // dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            // dispatch(generalError(error.errorMessage));
          }
          // dispatch(failure(error));
        }
      );
  };
};

export const sendPublicHash = ({ tokenCode, quotaNumber }) => {
  return (dispatch) => {
    // dispatch(request());
    userService.sendPublicHash({ tokenCode, quotaNumber }).then(
      (data) => {
        // dispatch(success(data));
      },
      (error) => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        // dispatch(failure(error));
      }
    );
  };
};

export const resettLinkClientStudy = () => {
  return (dispatch) => {
    dispatch(reset());
  };

  function reset() {
    return { type: RESET_CLIENTLINK };
  }
};

export const resettAffiliateUsers = () => {
  return (dispatch) => {
    dispatch(reset());
  };

  function reset() {
    return { type: RESET_AFFILIATE_TOKEN };
  }
};

export const requestClientStudy = ({
  userEmail,
  documentType,
  documentNumber,
  contract,
  productType,
  sellerDocumentType,
  sellerDocumentNumber,
  applicantName,
  city,
  address,
  phone,
  quota,
  expeditionDate,
  expeditionCity,
  cellular,
  email,
  birthDate,
  economicActivity,
  representativeDocumentType,
  representativeDocumentNumber,
  representativeName,
  constactFirstName,
  constactPosition,
  constactCellular,
  constactEmail,
  partnersArray,
  representativeFistLastName,
  representativeSecondLastName,
  constactSecondName,
  constactFirstLastName,
  constactSecondLastName,
  creationDate,
  firstRefFirstName,
  firstRefSecondName,
  firstRefFistLastName,
  firstRefSecondLastName,
  firstRefCity,
  firstRefPhone,
  firstRefEmail,
  secondRefFirstName,
  secondRefSecondName,
  secondRefFistLastName,
  secondRefSecondLastName,
  secondRefCity,
  secondRefPhone,
  secondRefEmail,
  pointsOfSale,
  modeWP,
  modeEmail,
  modeSMS,
  modePhone,
  companyType,
  refType,
  refType2,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .requestClientStudy({
        userEmail,
        documentType,
        documentNumber,
        contract,
        productType,
        sellerDocumentType,
        sellerDocumentNumber,
        applicantName,
        city,
        address,
        phone,
        quota,
        expeditionDate,
        expeditionCity,
        cellular,
        email,
        birthDate,
        economicActivity,
        representativeDocumentType,
        representativeDocumentNumber,
        representativeName,
        constactFirstName,
        constactPosition,
        constactCellular,
        constactEmail,
        partnersArray,
        representativeFistLastName,
        representativeSecondLastName,
        constactSecondName,
        constactFirstLastName,
        constactSecondLastName,
        creationDate,
        firstRefFirstName,
        firstRefSecondName,
        firstRefFistLastName,
        firstRefSecondLastName,
        firstRefCity,
        firstRefPhone,
        firstRefEmail,
        secondRefFirstName,
        secondRefSecondName,
        secondRefFistLastName,
        secondRefSecondLastName,
        secondRefCity,
        secondRefPhone,
        secondRefEmail,
        pointsOfSale,
        modeWP,
        modeEmail,
        modeSMS,
        modePhone,
        companyType,
        refType,
        refType2,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: REQUEST_CLIENTSTUDY_REQUEST };
  }
  function success(data) {
    return { type: REQUEST_CLIENTSTUDY_SUCCESS, data };
  }
  function failure(error) {
    return { type: REQUEST_CLIENTSTUDY_FAILURE, error };
  }
};

export const requestThreePaymentsClientStudy = ({
  queryCode,
  userEmail,
  documentType,
  documentNumber,
  sellerDocumentType,
  sellerDocumentNumber,
  applicantName,
  city,
  address,
  phone,
  quota,
  expeditionDate,
  expeditionCity,
  cellular,
  email,
  birthDate,
  economicActivity,
  representativeDocumentType,
  representativeDocumentNumber,
  representativeName,
  constactFirstName,
  constactPosition,
  constactCellular,
  constactEmail,
  partnersArray,
  representativeFistLastName,
  representativeSecondLastName,
  constactSecondName,
  constactFirstLastName,
  constactSecondLastName,
  creationDate,
  firstRefFirstName,
  firstRefSecondName,
  firstRefFistLastName,
  firstRefSecondLastName,
  firstRefCity,
  firstRefPhone,
  firstRefEmail,
  secondRefFirstName,
  secondRefSecondName,
  secondRefFistLastName,
  secondRefSecondLastName,
  secondRefCity,
  secondRefPhone,
  secondRefEmail,
  pointsOfSale,
  modeWP,
  modeEmail,
  modeSMS,
  modePhone,
  companyType,
  refType,
  refType2,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .requestThreePaymentsClientStudy({
        queryCode,
        userEmail,
        documentType,
        documentNumber,
        sellerDocumentType,
        sellerDocumentNumber,
        applicantName,
        city,
        address,
        phone,
        quota,
        expeditionDate,
        expeditionCity,
        cellular,
        email,
        birthDate,
        economicActivity,
        representativeDocumentType,
        representativeDocumentNumber,
        representativeName,
        constactFirstName,
        constactPosition,
        constactCellular,
        constactEmail,
        partnersArray,
        representativeFistLastName,
        representativeSecondLastName,
        constactSecondName,
        constactFirstLastName,
        constactSecondLastName,
        creationDate,
        firstRefFirstName,
        firstRefSecondName,
        firstRefFistLastName,
        firstRefSecondLastName,
        firstRefCity,
        firstRefPhone,
        firstRefEmail,
        secondRefFirstName,
        secondRefSecondName,
        secondRefFistLastName,
        secondRefSecondLastName,
        secondRefCity,
        secondRefPhone,
        secondRefEmail,
        pointsOfSale,
        modeWP,
        modeEmail,
        modeSMS,
        modePhone,
        companyType,
        refType,
        refType2,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: REQUEST_THREE_PAYMENTS_CLIENTSTUDY_REQUEST };
  }
  function success(data) {
    return { type: REQUEST_THREE_PAYMENTS_CLIENTSTUDY_SUCCESS, data };
  }
  function failure(error) {
    return { type: REQUEST_THREE_PAYMENTS_CLIENTSTUDY_FAILURE, error };
  }
};

export const saveForm = ({
  userEmail,
  documentType,
  documentNumber,
  sellerDocumentType,
  sellerDocumentNumber,
  applicantName,
  city,
  address,
  phone,
  quota,
  expeditionDate,
  expeditionCity,
  cellular,
  email,
  birthDate,
  economicActivity,
  representativeDocumentType,
  representativeDocumentNumber,
  representativeName,
  constactFirstName,
  constactPosition,
  constactCellular,
  constactEmail,
  partnersArray,
  representativeFistLastName,
  representativeSecondLastName,
  constactSecondName,
  constactFirstLastName,
  constactSecondLastName,
  creationDate,
  firstRefFirstName,
  firstRefSecondName,
  firstRefFistLastName,
  firstRefSecondLastName,
  firstRefCity,
  firstRefPhone,
  firstRefEmail,
  secondRefFirstName,
  secondRefSecondName,
  secondRefFistLastName,
  secondRefSecondLastName,
  secondRefCity,
  secondRefPhone,
  secondRefEmail,
  pointsOfSale,
  modeWP,
  modeEmail,
  modeSMS,
  modePhone,
  companyType,
  refType,
  filingNumber,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .saveForm({
        userEmail,
        documentType,
        documentNumber,
        sellerDocumentType,
        sellerDocumentNumber,
        applicantName,
        city,
        address,
        phone,
        quota,
        expeditionDate,
        expeditionCity,
        cellular,
        email,
        birthDate,
        economicActivity,
        representativeDocumentType,
        representativeDocumentNumber,
        representativeName,
        constactFirstName,
        constactPosition,
        constactCellular,
        constactEmail,
        partnersArray,
        representativeFistLastName,
        representativeSecondLastName,
        constactSecondName,
        constactFirstLastName,
        constactSecondLastName,
        creationDate,
        firstRefFirstName,
        firstRefSecondName,
        firstRefFistLastName,
        firstRefSecondLastName,
        firstRefCity,
        firstRefPhone,
        firstRefEmail,
        secondRefFirstName,
        secondRefSecondName,
        secondRefFistLastName,
        secondRefSecondLastName,
        secondRefCity,
        secondRefPhone,
        secondRefEmail,
        pointsOfSale,
        modeWP,
        modeEmail,
        modeSMS,
        modePhone,
        companyType,
        refType,
        filingNumber,
      })
      .then(
        (data) => {
          dispatch(success({ data, time: new Date() }));
        },
        (error) => {
          // if (error.errorMessage) {
          //   dispatch(generalError(error.errorMessage));
          // }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: SAVE_FORM_REQUEST };
  }
  function success(data) {
    return { type: SAVE_FORM_SUCCESS, data };
  }
  function failure(error) {
    return { type: SAVE_FORM_FAILURE, error };
  }
};

export const validateForm = ({
  documentType,
  documentNumber,
  userDocument,
  userDocumentType,
  userEmail,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .validateForm({
        documentType,
        documentNumber,
        userDocument,
        userDocumentType,
        userEmail,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: GET_VALIDATE_FORM_REQUEST };
  }
  function success(data) {
    return { type: GET_VALIDATE_FORM_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_VALIDATE_FORM_FAILURE, error };
  }
};

export const requestPublicClientStudy = ({
  sellerDocumentType,
  sellerDocumentNumber,
  tokenCode,
  applicantName,
  city,
  address,
  phone,
  quota,
  expeditionDate,
  expeditionCity,
  cellular,
  email,
  birthDate,
  economicActivity,
  representativeDocumentType,
  representativeDocumentNumber,
  representativeName,
  constactFirstName,
  constactPosition,
  constactCellular,
  constactEmail,
  partnersArray,
  representativeFistLastName,
  representativeSecondLastName,
  constactSecondName,
  constactFirstLastName,
  constactSecondLastName,
  creationDate,
  firstRefFirstName,
  firstRefSecondName,
  firstRefFistLastName,
  firstRefSecondLastName,
  firstRefCity,
  firstRefPhone,
  firstRefEmail,
  secondRefFirstName,
  secondRefSecondName,
  secondRefFistLastName,
  secondRefSecondLastName,
  secondRefCity,
  secondRefPhone,
  secondRefEmail,
  pointsOfSale,
  modeWP,
  modeEmail,
  modeSMS,
  modePhone,
  companyType,
  refType,
}) => {
  return (dispatch) => {
    dispatch(request());
    userService
      .requestPublicClientStudy({
        sellerDocumentType,
        sellerDocumentNumber,
        tokenCode,
        applicantName,
        city,
        address,
        phone,
        quota,
        expeditionDate,
        expeditionCity,
        cellular,
        email,
        birthDate,
        economicActivity,
        representativeDocumentType,
        representativeDocumentNumber,
        representativeName,
        constactFirstName,
        constactPosition,
        constactCellular,
        constactEmail,
        partnersArray,
        representativeFistLastName,
        representativeSecondLastName,
        constactSecondName,
        constactFirstLastName,
        constactSecondLastName,
        creationDate,
        firstRefFirstName,
        firstRefSecondName,
        firstRefFistLastName,
        firstRefSecondLastName,
        firstRefCity,
        firstRefPhone,
        firstRefEmail,
        secondRefFirstName,
        secondRefSecondName,
        secondRefFistLastName,
        secondRefSecondLastName,
        secondRefCity,
        secondRefPhone,
        secondRefEmail,
        pointsOfSale,
        modeWP,
        modeEmail,
        modeSMS,
        modePhone,
        companyType,
        refType,
      })
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          if (error.errorMessage) {
            dispatch(generalError(error.errorMessage));
          }
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return { type: REQUEST_PUBLICCLIENTSTUDY_REQUEST };
  }
  function success(data) {
    return { type: REQUEST_PUBLICCLIENTSTUDY_SUCCESS, data };
  }
  function failure(error) {
    return { type: REQUEST_PUBLICCLIENTSTUDY_FAILURE, error };
  }
};

export const cleanUser = () => {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: GET_ISUSER_CLEAN };
  }
};

export const setUser = ({ userEmail, token, userInfo }) => {
  let now = Date.now();
  localStorage.setItem('loginDate', now);
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify({ userEmail, token, userInfo }));
  return (dispatch) => {
    dispatch(setUser());
  };
  function setUser() {
    return { type: SET_USERDATA };
  }
};

export const resetRedux = () => {
  return (dispatch) => {
    dispatch(resetReduxState());
  };
  function resetReduxState() {
    return { type: RESET_ALL };
  }
};

export const getUser = () => {
  // let user = {"userEmail":"CEGM@PRUEBA.COM","password":"PRUEBAS","token":"60a87a2c73b91115a79d498974db287e","userInfo":{"DOCUMENTO":"8110286501","ROL_USUARIO":"1","EMPRESA":"COVINOC S A","TIPO_DOCUMENTO":"N","ERROR":0,"SUCURSAL_USUARIO":"BOGOTA","TOKEN":"29e16b4b3926ded2f2eb65e76b0fc29a","NOMBRE":"CLAUDIA  GRAU ","MENSAJE":""}};
  let user = JSON.parse(localStorage.getItem('user'));
  return (dispatch) => {
    dispatch(success(user));
  };
  function success(user) {
    return { type: GET_USERDATA, user };
  }
};

export const generalError = (generalError) => {
  return { type: GENERAL_ERROR, generalError };
};
