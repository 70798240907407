import React, { Component } from 'react';

import { Col, Button, Modal, Row } from 'react-bootstrap';

class ConfirmChange extends Component {

  render() {
    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="confirm-reserve-title">
            Confirmar reclamación
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="confirm-reserve-body">
            <Col xs={12} className="confirm-reserve_body-description">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Factura Nº:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">245346-1</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Valor factura:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">$1´250.000</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <Button className="button-primary" variant="primary" block>
                    Cancelar
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button className="button-send" variant="success" block>
                    Aceptar
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ConfirmChange;
