import {
  RESET_ALL,
  GET_DOCUMENTS_REQUEST,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAILURE,
  GET_TERMS_REQUEST,
  GET_TERMS_SUCCESS,
  GET_TERMS_FAILURE,
  GET_TERMSACEPTED_REQUEST,
  GET_TERMSACEPTED_SUCCESS,
  GET_TERMSACEPTED_FAILURE,
  SET_TERMSACEPTED_REQUEST,
  SET_TERMSACEPTED_SUCCESS,
  SET_TERMSACEPTED_FAILURE,
} from '../action-types'

const initialState = {
  loading: false,
  error: false,
  documents: [],
  terms: false,
  termsAccepted: true,
  acceptedTerms: true,
}

const documents = (state = initialState, action) => {
  switch (action.type) {

    case GET_DOCUMENTS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_DOCUMENTS_SUCCESS: {
      let documents = action.data
      return {
        ...state,
        documents,
        error: false,
        loading: false
      }
    }

    case GET_DOCUMENTS_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case GET_TERMS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_TERMS_SUCCESS: {
      let terms = action.data
      return {
        ...state,
        terms,
        error: false,
        loading: false
      }
    }

    case GET_TERMS_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case GET_TERMSACEPTED_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_TERMSACEPTED_SUCCESS: {
      let termsAccepted = action.data
      return {
        ...state,
        termsAccepted,
        error: false,
        loading: false
      }
    }

    case GET_TERMSACEPTED_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case SET_TERMSACEPTED_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case SET_TERMSACEPTED_SUCCESS: {
      let acceptedTerms = action.data
      return {
        ...state,
        acceptedTerms,
        error: false,
        loading: false
      }
    }

    case SET_TERMSACEPTED_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case RESET_ALL: {
      return initialState
    }

    default:
      return state
  }
}

export default documents
