import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import { Col, Button, Modal, Row } from 'react-bootstrap';

class ConfirmChange extends Component {
  cancel = () => {
    this.props.onHide();
  };

  confirm = () => {
    this.props.confirmInvoice();
  };

  render() {
    const {
      name,
      invoiceValue,
      invoiceNumber,
      selectedDate,
      show,
      onHide,
      threePayments,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <div className='confirm-reserve-title'>Confirmar transacción</div>
        </Modal.Header>
        <Modal.Body>
          <div className='confirm-reserve-body'>
            <Col xs={12} className='confirm-reserve_body-description'>
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col className='confirm-reserve_body-key'>Nombre:</Col>
                    {name && (
                      <Col xs='auto' className='confirm-reserve_body-value'>
                        {name.toLowerCase()}
                      </Col>
                    )}
                    {!name && (
                      <Col xs='auto' className='confirm-reserve_body-value'>
                        --
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className='confirm-reserve_body-key'>
                      Valor factura:
                    </Col>
                    <Col xs='auto' className='confirm-reserve_body-value'>
                      ${invoiceValue}
                    </Col>
                  </Row>
                </Col>
                {!threePayments && (
                  <>
                    <Col xs={12}>
                      <Row>
                        <Col className='confirm-reserve_body-key'>
                          Nº de factura:
                        </Col>
                        <Col xs='auto' className='confirm-reserve_body-value'>
                          {invoiceNumber}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col className='confirm-reserve_body-key'>
                          Fecha de vencimiento:
                        </Col>
                        <Col xs='auto' className='confirm-reserve_body-value'>
                          {selectedDate}
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <Button
                    className='button-primary'
                    variant='primary'
                    onClick={(e) => {
                      e.preventDefault();
                      this.cancel();
                    }}
                    block
                  >
                    Corregir
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className='button-send'
                    variant='success'
                    block
                    onClick={(e) => {
                      e.preventDefault();
                      this.confirm();
                    }}
                  >
                    Aceptar
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ConfirmChange.propTypes = {
  name: PropTypes.string,
  invoiceValue: PropTypes.string,
  invoiceNumber: PropTypes.string,
  selectedDate: PropTypes.string,
  confirmInvoice: PropTypes.func.isRequired,
};

ConfirmChange.defaultProps = {
  name: '',
  invoiceValue: '',
  invoiceNumber: '',
  selectedDate: '',
  confirmInvoice: () => {},
};

export default ConfirmChange;
