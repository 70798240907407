import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import './footer.scss';
import { Col, Row } from 'react-bootstrap';
import covinocLogo from '../../assets/logos/Logo-Covinoc.svg';

class Footer extends Component {
  render() {
    const { isLogin } = this.props;
    const year = new Date().getFullYear()
    return (
      <Col className={isLogin ? "footer footer-login" : "footer"} xs={12} >
        { isLogin && (
          <div className="footer-container" >
            <h3 className="footer-text">Un servicio de</h3>
            <img className={isLogin ? "footer-logo-home" : "footer-logo"} src={covinocLogo} alt='covinoc logo'/>
          </div>
        )}
        { !isLogin && (
          <Col className="height-100">
            <Row className="height-100">
              <Col className="footer-left-container" xs={12} md={6} >
                <h3 className="footer-text">Un servicio de</h3>
                <img className={isLogin ? "footer-logo" : "footer-logo-home"} src={covinocLogo} alt='covinoc logo'/>
              </Col>
              <Col className="footer-right-container" xs={12} md={6} >
                <h3 className="footer-text footer-text-right">Todos los derechos reservados </h3>
                <h3 className="footer-text-bold">Covifactura © {year}</h3>
              </Col>
            </Row>
          </Col>
        )}
      </Col>
    );
  }
}

Footer.propTypes = {
  isLogin: PropTypes.bool
};

Footer.defaultProps = {
  isLogin: false
};

export default Footer;
