import * as exonerationService from '../services';
import {
  GET_GUARANTEESTONOTIFY_REQUEST,
  GET_GUARANTEESTONOTIFY_SUCCESS,
  GET_GUARANTEESTONOTIFY_FAILURE,
  SEND_NOTIFY_REQUEST,
  SEND_NOTIFY_SUCCESS,
  SEND_NOTIFY_FAILURE,
  GET_GUARANTEESTORAISE_REQUEST,
  GET_GUARANTEESTORAISE_SUCCESS,
  GET_GUARANTEESTORAISE_FAILURE,
  SEND_RAISE_REQUEST,
  SEND_RAISE_SUCCESS,
  SEND_RAISE_FAILURE,
  RESET_GUARANTEESTONOTIFY,
  GENERAL_ERROR,
  SEND_BULKNOTICE_REQUEST,
  SEND_BULKNOTICE_SUCCESS,
  SEND_BULKNOTICEFINAL_SUCCESS,
  SEND_BULKNOTICE_FAILURE,
  EXPORT_BULKNOTICE_REQUEST,
  EXPORT_BULKNOTICE_SUCCESS,
  EXPORT_BULKNOTICE_FAILURE,
  RESET_EXPORT_BULKNOTICE_REQUEST,
} from '../action-types'

export const getGuaranteesToNotify = ({documentType, documentNumber, userDocument, userDocumentType, page, invoiceNumber, initialDate, finalDate}) => {
// export const getGuaranteesToNotify = ({documentType, documentNumber, userDocument, userDocumentType, page, invoiceNumber}) => {
  return dispatch => {
    dispatch(request());
    exonerationService.getGuaranteesToNotify({documentType, documentNumber, userDocument, userDocumentType, page , invoiceNumber, initialDate, finalDate})
    .then(
      data => {
        dispatch(success(data, page));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: GET_GUARANTEESTONOTIFY_REQUEST } }
  function success(data, page) { return { type: GET_GUARANTEESTONOTIFY_SUCCESS, data, page } }
  function failure(error) { return { type: GET_GUARANTEESTONOTIFY_FAILURE, error } }
}

export const notifyGuarante = ({userDocument, userDocumentType, userEmail, notifyArray }) => {
  return dispatch => {
    dispatch(request());
    exonerationService.notifyGuarante({userDocument, userDocumentType, userEmail, notifyArray })
    .then(
      data => {
        dispatch(success(data));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: SEND_NOTIFY_REQUEST } }
  function success(data) { return { type: SEND_NOTIFY_SUCCESS, data } }
  function failure(error) { return { type: SEND_NOTIFY_FAILURE, error } }
}

export const getGuaranteesToRaise = ({documentType, documentNumber, userDocument, userDocumentType, page, invoiceNumber, initialDate, finalDate}) => {
  return dispatch => {
    dispatch(request());
    exonerationService.getGuaranteesToRaise({documentType, documentNumber, userDocument, userDocumentType, page , invoiceNumber, initialDate, finalDate})
    .then(
      data => {
        dispatch(success(data, page));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: GET_GUARANTEESTORAISE_REQUEST } }
  function success(data, page) { return { type: GET_GUARANTEESTORAISE_SUCCESS, data, page } }
  function failure(error) { return { type: GET_GUARANTEESTORAISE_FAILURE, error } }
}

export const raiseGuarante = ({userDocument, userDocumentType, userEmail, notifyArray }) => {
  return dispatch => {
    dispatch(request());
    exonerationService.raiseGuarante({userDocument, userDocumentType, userEmail, notifyArray })
    .then(
      data => {
        dispatch(success(data));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: SEND_RAISE_REQUEST } }
  function success(data) { return { type: SEND_RAISE_SUCCESS, data } }
  function failure(error) { return { type: SEND_RAISE_FAILURE, error } }
}

export const resetGuaranteesToNotify = () => {
  return dispatch => {
    dispatch(request());
  }

  function request() { return { type: RESET_GUARANTEESTONOTIFY } }
}

export const sendMasiveNotice = ({ userDocument, userDocumentType, userEmail, file, correctionsArray, check }) => {
  return dispatch => {
    dispatch(request());
    exonerationService.sendMasiveNotice({ userDocument, userDocumentType, userEmail, file, correctionsArray, check })
    .then(
      data => {
        if(check) {
          dispatch(success(data));
        } else {
          dispatch(successFinal(data));
        }
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: SEND_BULKNOTICE_REQUEST } }
  function success(data) { return { type: SEND_BULKNOTICE_SUCCESS, data } }
  function successFinal(data) { return { type: SEND_BULKNOTICEFINAL_SUCCESS, data } }
  function failure(error) { return { type: SEND_BULKNOTICE_FAILURE, error } }
}

export const exportMasiveNotice = ({ userDocument, userDocumentType, numberFiled, status }) => {
  return dispatch => {
    dispatch(request());
    exonerationService.exportMasiveNotice({ userDocument, userDocumentType, numberFiled, status })
    .then(
      data => {
        dispatch(success(data));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: EXPORT_BULKNOTICE_REQUEST } }
  function success(data) { return { type: EXPORT_BULKNOTICE_SUCCESS, data } }
  function failure(error) { return { type: EXPORT_BULKNOTICE_FAILURE, error } }
}

export const resetExportNoticeReport = () => {
  return dispatch => {
    dispatch(request());
  }
  function request() { return { type: RESET_EXPORT_BULKNOTICE_REQUEST } }
}

export const generalError = (generalError) => { return { type: GENERAL_ERROR, generalError } }
