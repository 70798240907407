import React, { Component } from "react";
import { PropTypes } from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";

import { Col, Button, Modal, Row } from "react-bootstrap";
import loaddocument from "../../../assets/icons/loaddocument.svg";

class StudySucces extends Component {
  constructor() {
    super();
    this.state = {
      firstFile: false,
      firstFilePercent: 0,
      firstFilePercentFull: false,
      secondFile: false,
      secondFilePercent: 0,
      secondFilePercentFull: false,
      thirdFile: false,
      thirdFilePercent: 0,
      thirdFilePercentFull: false,
      fourthFile: false,
      fourthFilePercent: 0,
      fourthFilePercentFull: false,
      fifthFile: false,
      fifthFilePercent: 0,
      fifthFilePercentFull: false,
      sixthFile: false,
      sixthFilePercent: 0,
      sixthFilePercentFull: false,
    };

    this.firstFile = React.createRef();
    this.secondFile = React.createRef();
    this.thirdFile = React.createRef();
    this.fourthFile = React.createRef();
    this.fifthFile = React.createRef();
    this.sixthFile = React.createRef();
  }

  openSelector = (selector) => {
    selector.click();
  };

  handleFileChange = (file, fileIndex) => {
    if (file[0].size > 4000000) {
      alert("El archivo no puede ser mayor a 4M");
    } else {
      this.setState(
        {
          [fileIndex]: file[0],
          [fileIndex + "Percent"]: 100,
        },
        () => {
          setTimeout(() => {
            this.setState({
              [fileIndex + "PercentFull"]: true,
            });
          }, 500);
        }
      );
    }
  };

  confirm = () => {
    const {
      firstFile,
      secondFile,
      thirdFile,
      fourthFile,
      fifthFile,
      sixthFile,
    } = this.state;
    this.props.confirm(
      firstFile,
      secondFile,
      thirdFile,
      fourthFile,
      fifthFile,
      sixthFile
    );
    this.setState({
      firstFile: false,
      firstFilePercent: 0,
      firstFilePercentFull: false,
      secondFile: false,
      secondFilePercent: 0,
      secondFilePercentFull: false,
      thirdFile: false,
      thirdFilePercent: 0,
      thirdFilePercentFull: false,
      fourthFile: false,
      fourthFilePercent: 0,
      fourthFilePercentFull: false,
      fifthFile: false,
      fifthFilePercent: 0,
      fifthFilePercentFull: false,
      sixthFile: false,
      sixthFilePercent: 0,
      sixthFilePercentFull: false,
    });
  };

  isValid = () => {
    const {
      firstFile,
      secondFile,
      thirdFile,
      fourthFile,
      fifthFile,
      sixthFile,
    } = this.state;
    const { quotaValue } = this.props;

    if (firstFile) {
      return true;
    }

    if (quotaValue >= 75000000) {
      if (
        firstFile &&
        secondFile &&
        thirdFile &&
        fourthFile &&
        fifthFile &&
        sixthFile
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (quotaValue >= 20000000) {
      if (firstFile && thirdFile && fifthFile && sixthFile) {
        return true;
      } else {
        return false;
      }
    }
    if (firstFile && sixthFile) {
      return true;
    }
    return false;
  };

  render() {
    const { show, onHide, quotaValue } = this.props;

    const {
      firstFile,
      firstFilePercent,
      firstFilePercentFull,
      secondFile,
      secondFilePercent,
      secondFilePercentFull,
      thirdFile,
      thirdFilePercent,
      thirdFilePercentFull,
      fourthFile,
      fourthFilePercent,
      fourthFilePercentFull,
      fifthFile,
      fifthFilePercent,
      fifthFilePercentFull,
      sixthFile,
      sixthFilePercent,
      sixthFilePercentFull,
    } = this.state;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <input
          style={{ display: "none" }}
          ref={(input) => (this.firstFile = input)}
          type="file"
          max-file-size="4"
          accept="application/pdf"
          onChange={(e) => this.handleFileChange(e.target.files, "firstFile")}
        />
        <input
          style={{ display: "none" }}
          ref={(input) => (this.secondFile = input)}
          type="file"
          max-file-size="4"
          accept="application/pdf"
          onChange={(e) => this.handleFileChange(e.target.files, "secondFile")}
        />
        <input
          style={{ display: "none" }}
          ref={(input) => (this.thirdFile = input)}
          type="file"
          max-file-size="4"
          accept="application/pdf"
          onChange={(e) => this.handleFileChange(e.target.files, "thirdFile")}
        />
        <input
          style={{ display: "none" }}
          ref={(input) => (this.fourthFile = input)}
          type="file"
          max-file-size="4"
          accept="application/pdf"
          onChange={(e) => this.handleFileChange(e.target.files, "fourthFile")}
        />
        <input
          style={{ display: "none" }}
          ref={(input) => (this.fifthFile = input)}
          type="file"
          max-file-size="4"
          accept="application/pdf"
          onChange={(e) => this.handleFileChange(e.target.files, "fifthFile")}
        />
        <input
          style={{ display: "none" }}
          ref={(input) => (this.sixthFile = input)}
          type="file"
          max-file-size="4"
          accept="application/pdf"
          onChange={(e) => this.handleFileChange(e.target.files, "sixthFile")}
        />
        <Modal.Header>
          <div className="query-code-title">Cargar Información Financiera</div>
        </Modal.Header>
        <Modal.Body>
          <div className="terms-modal-body">
            <Col xs={12} className="load-documents-modal_title">
              Cargue los siguiente documentos para continuar:
            </Col>
            <Col xs={12} className="study-modal-succes_description">
              *El peso máximo de los archivos es de 4MB
            </Col>
            <Col
              xs={12}
              md={{ span: 6, offset: 3 }}
              className="load-documents-modal_documents-container"
            >
              <Row>
                {/* FAU */}
                <Col
                  xs={12}
                  className="load-documents-modal_document-container"
                >
                  <Row>
                    <Col
                      xs="auto"
                      className="load-documents-modal_document-image-container"
                    >
                      <img
                        src={loaddocument}
                        className="load-documents-modal_document-image"
                        alt="exoneración masiva icono"
                      />
                    </Col>
                    <Col className="load-documents-modal_document-info-container">
                      <Row>
                        <Col
                          xs={12}
                          className="load-documents-modal_document-title"
                        >
                          (FAU) Formato de solicitud de estudio
                        </Col>
                        <Col
                          xs={12}
                          className="load-documents-modal_document-subtitle"
                        ></Col>
                        <Col xs={12}>
                          <LinearProgress
                            className={`load-documents-modal_document-progress${
                              firstFilePercentFull ? " full-loader" : ""
                            }`}
                            color="secondary"
                            variant="determinate"
                            value={firstFilePercent}
                          />
                        </Col>
                        {firstFile && !firstFilePercentFull ? (
                          <Col
                            xs="auto"
                            className="load-documents-modal_document-name-loading"
                          >
                            Cargando...
                          </Col>
                        ) : (
                          <Col
                            xs="auto"
                            onClick={(e) => {
                              e.preventDefault();
                              this.openSelector(this.firstFile);
                            }}
                            className="load-documents-modal_document-load"
                          >
                            Cargar
                          </Col>
                        )}
                        {firstFile && !firstFilePercentFull && (
                          <Col className="load-documents-modal_document-name-loading load-documents-modal_document_text-right">
                            {firstFile.name}
                          </Col>
                        )}
                        {firstFilePercentFull && (
                          <Col className="load-documents-modal_document-name-loaded load-documents-modal_document_text-right">
                            {firstFile.name}
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                {/* CEDULA */}
                <Col
                  xs={12}
                  className="load-documents-modal_document-container"
                >
                  <Row>
                    <Col
                      xs="auto"
                      className="load-documents-modal_document-image-container"
                    >
                      <img
                        src={loaddocument}
                        className="load-documents-modal_document-image"
                        alt="exoneración masiva icono"
                      />
                    </Col>
                    <Col className="load-documents-modal_document-info-container">
                      <Row>
                        <Col
                          xs={12}
                          className="load-documents-modal_document-title"
                        >
                          Documento de identificación
                        </Col>
                        <Col
                          xs={12}
                          className="load-documents-modal_document-subtitle"
                        ></Col>
                        <Col xs={12}>
                          <LinearProgress
                            className={`load-documents-modal_document-progress${
                              sixthFilePercentFull ? " full-loader" : ""
                            }`}
                            color="secondary"
                            variant="determinate"
                            value={sixthFilePercent}
                          />
                        </Col>
                        {sixthFile && !sixthFilePercentFull ? (
                          <Col
                            xs="auto"
                            className="load-documents-modal_document-name-loading"
                          >
                            Cargando...
                          </Col>
                        ) : (
                          <Col
                            xs="auto"
                            onClick={(e) => {
                              e.preventDefault();
                              this.openSelector(this.sixthFile);
                            }}
                            className="load-documents-modal_document-load"
                          >
                            Cargar
                          </Col>
                        )}
                        {sixthFile && !sixthFilePercentFull && (
                          <Col className="load-documents-modal_document-name-loading load-documents-modal_document_text-right">
                            {sixthFile.name}
                          </Col>
                        )}
                        {sixthFilePercentFull && (
                          <Col className="load-documents-modal_document-name-loaded load-documents-modal_document_text-right">
                            {sixthFile.name}
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                {/* declaración de renta 2018 */}
                {quotaValue >= 75000000 && (
                  <Col
                    xs={12}
                    className="load-documents-modal_document-container"
                  >
                    <Row>
                      <Col
                        xs="auto"
                        className="load-documents-modal_document-image-container"
                      >
                        <img
                          src={loaddocument}
                          className="load-documents-modal_document-image"
                          alt="exoneración masiva icono"
                        />
                      </Col>
                      <Col className="load-documents-modal_document-info-container">
                        <Row>
                          <Col
                            xs={12}
                            className="load-documents-modal_document-title"
                          >
                            Declaración de renta y/o declaraciones de IVA
                          </Col>
                          <Col
                            xs={12}
                            className="load-documents-modal_document-subtitle"
                          >
                            Año 2018
                          </Col>
                          <Col xs={12}>
                            <LinearProgress
                              className={`load-documents-modal_document-progress${
                                secondFilePercentFull ? " full-loader" : ""
                              }`}
                              color="secondary"
                              variant="determinate"
                              value={secondFilePercent}
                            />
                          </Col>
                          {secondFile && !secondFilePercentFull ? (
                            <Col
                              xs="auto"
                              className="load-documents-modal_document-name-loading"
                            >
                              Cargando...
                            </Col>
                          ) : (
                            <Col
                              xs="auto"
                              onClick={(e) => {
                                e.preventDefault();
                                this.openSelector(this.secondFile);
                              }}
                              className="load-documents-modal_document-load"
                            >
                              Cargar
                            </Col>
                          )}
                          {secondFile && !secondFilePercentFull && (
                            <Col className="load-documents-modal_document-name-loading load-documents-modal_document_text-right">
                              {secondFile.name}
                            </Col>
                          )}
                          {secondFilePercentFull && (
                            <Col className="load-documents-modal_document-name-loaded load-documents-modal_document_text-right">
                              {secondFile.name}
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {/* declaración de renta 2019 */}
                {quotaValue >= 20000000 && (
                  <Col
                    xs={12}
                    className="load-documents-modal_document-container"
                  >
                    <Row>
                      <Col
                        xs="auto"
                        className="load-documents-modal_document-image-container"
                      >
                        <img
                          src={loaddocument}
                          className="load-documents-modal_document-image"
                          alt="exoneración masiva icono"
                        />
                      </Col>
                      <Col className="load-documents-modal_document-info-container">
                        <Row>
                          <Col
                            xs={12}
                            className="load-documents-modal_document-title"
                          >
                            Declaración de renta y/o declaraciones de IVA
                          </Col>
                          <Col
                            xs={12}
                            className="load-documents-modal_document-subtitle"
                          >
                            Año 2019
                          </Col>
                          <Col xs={12}>
                            <LinearProgress
                              className={`load-documents-modal_document-progress${
                                thirdFilePercentFull ? " full-loader" : ""
                              }`}
                              color="secondary"
                              variant="determinate"
                              value={thirdFilePercent}
                            />
                          </Col>
                          {thirdFile && !thirdFilePercentFull ? (
                            <Col
                              xs="auto"
                              className="load-documents-modal_document-name-loading"
                            >
                              Cargando...
                            </Col>
                          ) : (
                            <Col
                              xs="auto"
                              onClick={(e) => {
                                e.preventDefault();
                                this.openSelector(this.thirdFile);
                              }}
                              className="load-documents-modal_document-load"
                            >
                              Cargar
                            </Col>
                          )}
                          {thirdFile && !thirdFilePercentFull && (
                            <Col className="load-documents-modal_document-name-loading load-documents-modal_document_text-right">
                              {thirdFile.name}
                            </Col>
                          )}
                          {thirdFilePercentFull && (
                            <Col className="load-documents-modal_document-name-loaded load-documents-modal_document_text-right">
                              {thirdFile.name}
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {/* Estados financieros con notas 2018 */}
                {quotaValue >= 75000000 && (
                  <Col
                    xs={12}
                    className="load-documents-modal_document-container"
                  >
                    <Row>
                      <Col
                        xs="auto"
                        className="load-documents-modal_document-image-container"
                      >
                        <img
                          src={loaddocument}
                          className="load-documents-modal_document-image"
                          alt="exoneración masiva icono"
                        />
                      </Col>
                      <Col className="load-documents-modal_document-info-container">
                        <Row>
                          <Col
                            xs={12}
                            className="load-documents-modal_document-title"
                          >
                            Estados financieros con notas
                          </Col>
                          <Col
                            xs={12}
                            className="load-documents-modal_document-subtitle"
                          >
                            Año 2018
                          </Col>
                          <Col xs={12}>
                            <LinearProgress
                              className={`load-documents-modal_document-progress${
                                fourthFilePercentFull ? " full-loader" : ""
                              }`}
                              color="secondary"
                              variant="determinate"
                              value={fourthFilePercent}
                            />
                          </Col>
                          {fourthFile && !fourthFilePercentFull ? (
                            <Col
                              xs="auto"
                              className="load-documents-modal_document-name-loading"
                            >
                              Cargando...
                            </Col>
                          ) : (
                            <Col
                              xs="auto"
                              onClick={(e) => {
                                e.preventDefault();
                                this.openSelector(this.fourthFile);
                              }}
                              className="load-documents-modal_document-load"
                            >
                              Cargar
                            </Col>
                          )}
                          {fourthFile && !fourthFilePercentFull && (
                            <Col className="load-documents-modal_document-name-loading load-documents-modal_document_text-right">
                              {fourthFile.name}
                            </Col>
                          )}
                          {fourthFilePercentFull && (
                            <Col className="load-documents-modal_document-name-loaded load-documents-modal_document_text-right">
                              {fourthFile.name}
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {/* Estados financieros con notas 2019 */}
                {quotaValue >= 20000000 && (
                  <Col
                    xs={12}
                    className="load-documents-modal_document-container"
                  >
                    <Row>
                      <Col
                        xs="auto"
                        className="load-documents-modal_document-image-container"
                      >
                        <img
                          src={loaddocument}
                          className="load-documents-modal_document-image"
                          alt="exoneración masiva icono"
                        />
                      </Col>
                      <Col className="load-documents-modal_document-info-container">
                        <Row>
                          <Col
                            xs={12}
                            className="load-documents-modal_document-title"
                          >
                            Estados financieros con notas
                          </Col>
                          <Col
                            xs={12}
                            className="load-documents-modal_document-subtitle"
                          >
                            Año 2019
                          </Col>
                          <Col xs={12}>
                            <LinearProgress
                              className={`load-documents-modal_document-progress${
                                fifthFilePercentFull ? " full-loader" : ""
                              }`}
                              color="secondary"
                              variant="determinate"
                              value={fifthFilePercent}
                            />
                          </Col>
                          {fifthFile && !fifthFilePercentFull ? (
                            <Col
                              xs="auto"
                              className="load-documents-modal_document-name-loading"
                            >
                              Cargando...
                            </Col>
                          ) : (
                            <Col
                              xs="auto"
                              onClick={(e) => {
                                e.preventDefault();
                                this.openSelector(this.fifthFile);
                              }}
                              className="load-documents-modal_document-load"
                            >
                              Cargar
                            </Col>
                          )}
                          {fifthFile && !fifthFilePercentFull && (
                            <Col className="load-documents-modal_document-name-loading load-documents-modal_document_text-right">
                              {fifthFile.name}
                            </Col>
                          )}
                          {fifthFilePercentFull && (
                            <Col className="load-documents-modal_document-name-loaded load-documents-modal_document_text-right">
                              {fifthFile.name}
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
            <Col
              xs={12}
              md={{ span: 4, offset: 4 }}
              className="query-code-buttons"
            >
              <Button
                className="button-send"
                variant="success"
                disabled={!this.isValid()}
                onClick={(e) => {
                  e.preventDefault();
                  this.confirm();
                }}
                block
              >
                Finalizar
              </Button>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

StudySucces.propTypes = {
  confirm: PropTypes.func.isRequired,
  quotaValue: PropTypes.number,
};

StudySucces.defaultProps = {
  confirm: () => {},
  quotaValue: 0,
};

export default StudySucces;
