import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import './header.scss';
import { Col, Navbar } from 'react-bootstrap';
import menuIcon from '../../assets/icons/sidebar.svg';
import covifacturaLogo from '../../assets/logos/Logoblanco.svg';

class Header extends Component {

  openMenu() {
    this.props.openMenu()
  }

  goHome = () => {
    this.props.history.push(`/`);
  }

  render() {
    const { clientPage } = this.props;
    return (
      <Navbar sticky="top" className="header">
        { !clientPage && (
          <Col xs={{ span: 3 }} className="no-padding">
            <div
              className="menu-icon-container"
              onClick={(e) => {
                e.preventDefault()
                this.openMenu()
              }}>
              <img className="menu-icon" src={menuIcon} alt='menu icon'/>
            </div>
          </Col>
        )}
        <Col
          xs={{ span: 6 }}
          md={clientPage ? 3 : null}
          className="center-horizontally"
          onClick={(e) => {
            e.preventDefault()
            this.goHome()
          }}
        >
          <img className="header-logo" src={covifacturaLogo} alt='covifactura logo'/>
        </Col>
      </Navbar>
    );
  }
}

Header.propTypes = {
  openMenu: PropTypes.func.isRequired,
  clientPage: PropTypes.bool,
};

Header.defaultProps = {
  openMenu: () => {},
  clientPage: false,
};

export default withRouter(Header);
