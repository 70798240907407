import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import { Col, Button, Modal, Row } from 'react-bootstrap';
import moment from "moment";

class ConfirmChange extends Component {

  cancel = () => {
    this.props.onHide();
  }

  confirm = () => {
    this.props.confirmDayCheck();
  }

  render() {
    const {
      name,
      checkValue,
      accountNumber,
      // titleValue,
      bankName,
      show,
      onHide,
      checkType,
      checksArray,
      selectedCheckDate,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="confirm-reserve-title">
            Confirmar transacción
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="confirm-reserve-body">
            <Col xs={12} className="confirm-reserve_body-description">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Nombre:</Col>
                      { name && (
                        <Col xs="auto" className="confirm-reserve_body-value">{name.toLowerCase()}</Col>
                      )}
                      { !name && (
                        <Col xs="auto" className="confirm-reserve_body-value">--</Col>
                      )}
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Banco:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">{bankName.toLowerCase()}</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Nº de cuenta:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">{accountNumber.toLowerCase()}</Col>
                  </Row>
                </Col>
                { checkType !== 'P' && (
                  <Col xs={12}>
                    <Row>
                      <Col xs={12}>
                        <Row>
                          <Col className="confirm-reserve_body-key">Valor cheque:</Col>
                          <Col xs="auto" className="confirm-reserve_body-value">${checkValue}</Col>
                        </Row>
                      </Col>
                      {/*
                        <Col xs={12}>
                        <Row>
                        <Col className="confirm-reserve_body-key">Nº de cheque:</Col>
                        <Col xs="auto" className="confirm-reserve_body-value">{titleValue}</Col>
                        </Row>
                        </Col>
                        */}
                    </Row>
                  </Col>
                )}
                { checkType === 'P' && (
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} className="check-modal-title">Cheque 1</Col>
                      <Col xs={12} className="confirm-reserve_body-key check-modal-check-info">Valor: ${checkValue}</Col>
                      {/*
                        <Col xs={12} className="confirm-reserve_body-key check-modal-check-info">Número: {titleValue}</Col>
                        */}
                      <Col xs={12} className="confirm-reserve_body-key">Fecha de vencimiento: {selectedCheckDate}</Col>
                    </Row>
                    { checksArray.map((item, index) => {
                      return (
                        <Row key={index}>
                          <Col xs={12} className="check-modal-title">Cheque {index + 2}</Col>
                          <Col xs={12} className="confirm-reserve_body-key check-modal-check-info">Valor: ${item.checkValueMask}</Col>
                          {/*
                            <Col xs={12} className="confirm-reserve_body-key check-modal-check-info">Número: {item.titleValue}</Col>
                            */}
                          <Col xs={12} className="confirm-reserve_body-key">Fecha de vencimiento: {moment(item.selectedCheckDate).format('DD/MM/YYYY')}</Col>
                        </Row>
                      );
                    })}
                  </Col>
                )}
              </Row>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <Button
                    className="button-primary"
                    variant="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        this.cancel();
                    }}
                    block
                  >
                    Corregir
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className="button-send"
                    variant="success"
                    onClick={(e) => {
                        e.preventDefault();
                        this.confirm();
                    }}
                    block
                  >
                    Aceptar
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ConfirmChange.propTypes = {
  name: PropTypes.string,
  checkValue: PropTypes.string,
  accountNumber: PropTypes.string,
  titleValue: PropTypes.string,
  bankName: PropTypes.string,
  checkType: PropTypes.string,
  selectedCheckDate: PropTypes.string,
  confirmDayCheck: PropTypes.func.isRequired,
  checksArray: PropTypes.array,
};

ConfirmChange.defaultProps = {
  name: '',
  checkValue: '',
  accountNumber: '',
  titleValue: '',
  bankName: '',
  checkType: '',
  selectedCheckDate: '',
  confirmDayCheck: () => {},
  checksArray: [],
};

export default ConfirmChange;
