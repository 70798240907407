import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import { Col, Button, Modal, Row } from 'react-bootstrap';

class ConfirmChange extends Component {

  cancel = () => {
    this.props.onHide();
  }

  confirm = () => {
    const {
      individualNotice
    } = this.props;
    this.props.confirmIndividualNotice(individualNotice);
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  render() {
    const {
      individualNotice,
      show,
      onHide,
    } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="confirm-reserve-title">
            Confirmar aviso de no pago individual
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="confirm-reserve-body">
            <Col xs={12} className="confirm-reserve_body-description">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Tipo de documento:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">{individualNotice.TIPO_DOCUMENTO}</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Número de documento:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">{individualNotice.DOCUMENTO}</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Valor factura:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">${this.getNumber(individualNotice.VALOR_GARANTIZADO)}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <Button
                    className="button-primary"
                    variant="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        this.cancel();
                    }}
                    block
                  >
                    Corregir
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className="button-send"
                    variant="success"
                    onClick={(e) => {
                        e.preventDefault();
                        this.confirm();
                    }}
                    block>
                    Aceptar
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ConfirmChange.propTypes = {
  individualNotice: PropTypes.object,
  confirmIndividualNotice: PropTypes.func.isRequired,
};

ConfirmChange.defaultProps = {
  individualNotice: {
      "DOCUMENTO": "1019127792",
      "TITULO_VALOR": "1234",
      "AUTORIZACION": "383626",
      "FECHA": "2019-02-13 09:13:32.0",
      "DIAS_MARCAR_AVISO": "6",
      "TIPO_DOCUMENTO": "C",
      "TIPO_TITULO": "FACTURA",
      "VALOR_GARANTIZADO": "100000",
      "RN": "1",
      "VENCIMIENTO": "2019-03-13 00:00:00.0",
      "N_REGISTROS": "5",
      "NOMBRE": "ANGELA  KATHERINE CHAPARRO  GARAY"
   },
  confirmIndividualNotice: () => {},
};

export default ConfirmChange;
