import config from '../../config';
import { authHeader } from '../../helpers';

// export const getGuaranteesToExonerate = ({documentType, documentNumber, userDocument, userDocumentType, page, initialDate, finalDate }) => {
export const getGuaranteesToExonerate = ({documentType, documentNumber, userDocument, userDocumentType, page, invoiceNumber , initialDate, finalDate}) => {
  const headers = authHeader()
  const requestOptions = {
    method: 'GET',
    headers
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais = ('PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]) ? globals.PAIS[0].ID_PAIS : '1';
  let finalParams = `id_pais=${idPais}&comprador_tipo_documento=${documentType}&tipo_documento=${userDocumentType}&documento=${userDocument}&comprador_documento=${documentNumber}&registros=10&pagina=${page}&ordenar=DOCUMENTO&ordenamiento=desc&titulo_valor=${invoiceNumber}&fecha_inicial=${initialDate}&fecha_final=${finalDate}`;
  return fetch(`${config.apiUrl}/pr_garantias_a_exonerar?${finalParams}`, requestOptions).then(handleResponse);
}

export const exonerateGuarante = ({userDocument, userDocumentType, userEmail, exonerateArray }) => {
  const headers = authHeader()
  const requestOptions = {
    method: 'POST',
    headers
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais = ('PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]) ? globals.PAIS[0].ID_PAIS : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${userDocumentType}&documento=${userDocument}&usuario=${userEmail}&StrJArray=${encodeURIComponent(exonerateArray)}`;
  return fetch(`${config.apiUrl}/pr_exonerar?${finalParams}`, requestOptions).then(handleResponse);
}

export const sendMasiveExoneration = ({ userDocument, userDocumentType, userEmail, file, correctionsArray, check }) => {
  const headers = authHeader();
  let data = new FormData();
  if (correctionsArray) {
    headers['Content-Type'] = 'application/json';
    data = correctionsArray;
  } else {
    data.append('file', file);
  }
  const requestOptions = {
    method: 'PUT',
    headers,
    body: data
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais = ('PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]) ? globals.PAIS[0].ID_PAIS : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${userDocumentType}&documento=${userDocument}&usuario=${userEmail}&verificar=${check}`;
  return fetch(`${config.apiUrl}/pr_exoneraciones_masiva?${finalParams}`, requestOptions).then(handleResponse);
}

export const exportMasiveExoneration = ({ userDocument, userDocumentType, numberFiled, status }) => {
  const headers = authHeader();
  const requestOptions = {
    method: 'GET',
    headers
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais = ('PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]) ? globals.PAIS[0].ID_PAIS : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${userDocumentType}&documento=${userDocument}&radicado=${numberFiled}&estado=${status}`;
  return fetch(`${config.apiUrl}/pr_exportar_exoneracion_masiva?${finalParams}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  if(response.headers.get("content-type").indexOf("application/ms-excel") !== -1) {
    return response.blob().then(blob => {
      return blob;
    });
  }
  return response.text().then(text => {
    const data = (text && response.ok) && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        window.location.reload(true);
      }
      let errorMessage = false
      if (response.status === 400) {
        errorMessage = response.headers.get('mensaje_error');
      }
      let errorArray = {
        status: response.status,
        text: (data && data.message) || response.statusText,
        errorMessage
      }
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}
