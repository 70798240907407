import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./login.scss";
import * as actions from "../../redux/actions";
import { Row, Col, Button } from "react-bootstrap";
import covifacturaLogo from "../../assets/logos/Logoblanco.svg";
import erroricon from "../../assets/icons/error-icon.svg";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Footer from "../footer";
import Loading from "../loading";
import Chat from "../chat";
import { debounce } from "debounce";
import { Visibility, VisibilityOff } from "@material-ui/icons";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      emailUser: "",
      password: "",
      validEmail: true,
      showPassword: false,
    };
    this.isValidEmail = debounce(this.isValidEmail, 1000);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props.actions.getAppGlobals(true);
    this.props.actions.getFamilyList(true);
    this.props.actions.getCareerList(true);
  }

  isValid() {
    const { username, password } = this.state;
    return !!(username && password);
  }

  isValidEmail(emailUser) {
    if (!emailUser) {
      this.setState({
        validEmail: true,
      });
      return;
    }
    let validEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(emailUser)) {
      validEmail = true;
    }
    this.setState({
      validEmail,
    });
  }

  isEmail = (event) => {
    let emailUser = event.target.value.toUpperCase();
    // this.isValidEmail(emailUser);
    // let validEmail = false
    // if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(emailUser)) {
    //   validEmail = true;
    // }
    // if (validEmail) {
    //   this.setState({
    //     validEmail,
    //     emailUser
    //   });
    // } else {
    this.setState({
      emailUser,
    });
    // }
  };

  onPasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  handleSubmit = () => {
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    if (globalsJson) {
      let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    }
    // window.gtag("event", "login", {
    //   event_category: `${this.state.emailUser}:${idPais}`,
    //   event_label: this.state.emailUser,
    // });
    this.props.actions.isUser({
      userEmail: this.state.emailUser,
      password: this.state.password,
    });
  };

  tryAgain = () => {
    this.props.actions.cleanUser();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.props.actions.setUser({
        userEmail: this.state.emailUser,
        token: nextProps.user.TOKEN,
        userInfo: nextProps.user,
      });
    }
    if (nextProps.setUserData !== this.props.setUserData) {
      this.props.actions.getRolPermissions({
        documentType: nextProps.user.TIPO_DOCUMENTO,
        documentNumber: nextProps.user.DOCUMENTO,
        idRol: nextProps.user.ROL_USUARIO,
        reload: true,
      });
    }
    if (nextProps.permissions !== this.props.permissions) {
      let userJson = JSON.parse(localStorage.getItem("user"));
      let globalsJson = JSON.parse(localStorage.getItem("globals"));
      if (globalsJson) {
        let idPais =
        "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
          ? globalsJson.PAIS[0].ID_PAIS
          : "1";
      }
      // window.gtag("event", "login_exitoso", {
      //   event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      //   event_label: userJson.userEmail,
      // });
      // this.props.history.push(`/?info=true`);
      this.props.history.push(`/estudio-de-cliente`);
    }
  }

  goRemember = () => {
    this.props.history.push(`/recuperar-contrasena`);
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  render() {
    const {
      loading,
      errorUser,
      loadingCareerList,
      loadingFamilyList,
    } = this.props;
    console.log("errorUser", errorUser);
    const { emailUser, password } = this.state;
    return (
      <div className="login full-screen">
        {(loading || loadingCareerList || loadingFamilyList) && <Loading />}
        <Col className="image-cover full-screen hide-mobile" md={8}>
          <Row>
            <Col md={6}>
              <h2 className="login_title">Respaldamos a sus clientes</h2>
              <div className="login_title_line-container">
                <div className="login_title_line"></div>
                <div className="login_title_line-point"></div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="full-screen login-scroll" xs={12} md={4}>
          {!errorUser && (
            <Row>
              <Col xs={12}>
                <Col
                  xs={12}
                  className="center-horizontally login-logo-container"
                >
                  <img
                    className="login-logo"
                    src={covifacturaLogo}
                    alt="covifactura logo"
                  />
                </Col>
                <Col xs={12} className="login_sub-title-container">
                  <h3 className="login_sub-title">INICIAR SESIÓN</h3>
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 8, offset: 2 }}
                  className="login-input-container"
                >
                  <TextField
                    id="user"
                    label="Usuario"
                    className="login-input"
                    margin="dense"
                    onChange={this.isEmail}
                    value={emailUser}
                    fullWidth
                  />
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 8, offset: 2 }}
                  className="login-input-container"
                >
                  <TextField
                    id="password"
                    label="Contraseña"
                    type={this.state.showPassword ? "text" : "password"}
                    className="login-input"
                    margin="dense"
                    onChange={this.onPasswordChange}
                    value={password}
                    helperText={false}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            className="login-icon"
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 8, offset: 2 }}
                  className="button-send-container"
                >
                  <Button
                    className="button-send"
                    variant="success"
                    block
                    disabled={
                      emailUser !== "" && password !== "" ? false : true
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleSubmit();
                    }}
                  >
                    INGRESAR
                  </Button>
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 8, offset: 2 }}
                  className="button-link-container"
                >
                  <Button
                    className="button-link"
                    variant="link"
                    block
                    onClick={(e) => {
                      e.preventDefault();
                      this.goRemember();
                    }}
                  >
                    Olvidé mi contraseña
                  </Button>
                </Col>
              </Col>
            </Row>
          )}
          {errorUser && (
            <Row>
              <Col xs={12}>
                <Col
                  xs={12}
                  className="center-horizontally login-logo-container"
                >
                  <img
                    className="login-logo"
                    src={covifacturaLogo}
                    alt="covifactura logo"
                  />
                </Col>
                <Col
                  xs={12}
                  md={{ span: 8, offset: 2 }}
                  className="login_sub-title-container"
                >
                  {errorUser.errorMessage &&
                  errorUser.errorMessage === "Sin codigo de consulta activo" ? (
                    <h3 className="login_sub-title">USUARIO SUSPENDIDO</h3>
                  ) : (
                    <h3 className="login_sub-title">
                      USUARIO O CONTRASEÑA INVALIDA
                    </h3>
                  )}
                </Col>
                <Col xs={12} className="login_error-logo-container">
                  <img
                    className="login_error-logo"
                    src={erroricon}
                    alt="icono error"
                  />
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 8, offset: 2 }}
                  className="login_error-login"
                >
                  {errorUser.errorMessage &&
                  errorUser.errorMessage === "Sin codigo de consulta activo"
                    ? "Comuniquese con su ejecutivo de servicio o con nuestra línea de servicio, en Bogotá al (+571) 3364488 o a nivel nacional al 018000121107"
                    : "Verifique que los datos de ingreso sean los correctos e intente de nuevo."}
                </Col>
                <Col
                  xs={{ span: 10, offset: 1 }}
                  md={{ span: 8, offset: 2 }}
                  className="button-send-container"
                >
                  <Button
                    className="button-send"
                    variant="success"
                    block
                    onClick={(e) => {
                      e.preventDefault();
                      this.tryAgain();
                    }}
                  >
                    INTENTAR DE NUEVO
                  </Button>
                </Col>
              </Col>
            </Row>
          )}
        </Col>
        <Chat home />
        <Footer isLogin />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
    globals,
    loading,
    user,
    errorUser,
    loadingCareerList,
    loadingFamilyList,
    permissions,
    setUserData,
  } = state.user;

  return {
    globals,
    loading,
    user,
    errorUser,
    loadingCareerList,
    loadingFamilyList,
    permissions,
    setUserData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
