import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './manage-users.scss';
import * as actions from '../../redux/actions'
import { Col, Button, Row } from 'react-bootstrap';
import Footer from '../footer';
import Header from '../header';
import Menu from '../menu';
import Chat from '../chat';
import UserHeader from '../user-header';
import arrowback from '../../assets/icons/arrowback.svg';
import redalert from '../../assets/icons/red-alert.svg';
import searchicon from '../../assets/icons/searchicon.svg';
import userLogo from '../../assets/logos/user-logo.svg';
import emptyuser from '../../assets/icons/emptyuser.svg';
import AddUser from './add-user';
import AddSuccess from './add-success';
import ConfirmAction from './confirm-action';
import Loading from '../loading';
import {TextField} from '@material-ui/core';
import ReduxLazyScroll from 'redux-lazy-scroll';
import ListLoader from '../list-loader';

class ManageUsers extends Component {

  constructor() {
    super();
    this.state = {
      menuOpen: false,
      addUserModal: false,
      addSuccessModal: false,
      confirmActionModal: false,
      newUser: null,
      updateModal: false,
      userDocumentNumber: '',
    };
    this.page = 0;
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  goHome = () => {
    this.props.history.push(`/`);
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.props.actions.getUser();
    this.props.actions.getAppGlobals();
  }

  getManagedUsers = (user, update = false) => {
    const { userDocumentNumber } = this.state;
    this.page = this.page + 1;
    this.props.actions.getManagedUsers({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      userDocumentType: 'C',
      userDocumentNumber: userDocumentNumber && !update ? userDocumentNumber : 0,
      page: this.page
    });
  }

  sendEmail() {
    const { newUser } = this.state;
    let userFormatted = this.getFormattedDocumentType(newUser.documentType) + newUser.documentNumber
    this.props.actions.rememberPassword({ userEmail: userFormatted, rememberType: 'CREAR' });
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.user !== this.props.user) {
    //   this.getManagedUsers(nextProps.user);
    // }
    if (nextProps.managedUsersUpdate && nextProps.managedUsersUpdate !== this.props.managedUsersUpdate) {
      if(this.state.newUser.action === 'CREAR') {
        this.sendEmail();
        this.setState({
          addSuccessModal: true
        })

      } else if (this.state.newUser.action === 'ACTUALIZAR' && this.state.newUser.status === 'A') {
        this.setState({
          addSuccessModal: true,
          updateModal: true,
        })
      } else if (this.state.newUser.action === 'ACTUALIZAR' && this.state.newUser.status === 'I') {
        this.setState({
          newUser: null
        })
      } else if (this.state.newUser.action === 'RETIRAR') {
        this.setState({
          newUser: null
        })
      }
      this.page = 0;
      this.getManagedUsers(this.props.user, true);
      this.props.actions.resetManageUser();
    }
  }

  closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true
    });
  }

  addUserModal = () => {
    this.setState({
      addUserModal: true
    });
  }

  editUserModal = (newUser) => {
    this.setState({
      addUserModal: true,
      newUser,
    });
  }

  addUser = (newUser) => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';

    if(newUser.action === 'ACTUALIZAR') {
      // window.gtag('event', 'actualizar_usuario', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    }
    if(newUser.action === 'RETIRAR') {
      // window.gtag('event', 'retirar_usuario', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    }
    if(newUser.action === 'CREAR') {
      // window.gtag('event', 'agregar_usuario', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    }
    const { user } = this.props;
    this.setState({
      addUserModal: false,
      confirmActionModal: false,
      newUser: newUser
    }, () => {
      this.props.actions.manageUser({
        userEmail: user.userEmail,
        documentType: user.userInfo.TIPO_DOCUMENTO,
        documentNumber: user.userInfo.DOCUMENTO,
        sellerEmail: newUser.email,
        firstName: newUser.firstName,
        secondName: newUser.secondName,
        lastName: newUser.lastName,
        secondLastName: newUser.secondLastName,
        role: newUser.role,
        action: newUser.action,
        sellerDocumentType: newUser.documentType,
        sellerDocumentNumber: newUser.documentNumber,
        sellerCellular: newUser.cellular,
        city: newUser.homeCity,
        branch: newUser.branch,
        status: newUser.status,
        password: newUser.password
      });
    });
  }

  addUserModalClose = () => {
    this.setState({
      addUserModal: false,
      newUser: null,
      updateModal: false,
    });
  }

  addSuccessModalClose = () => {
    this.setState({
      addSuccessModal: false,
      newUser: null,
      updateModal: false,
    });
  }

  confirmActionModalClose = () => {
    this.setState({
      confirmActionModal: false,
      newUser: null,
    });
  }

  openConfirmActionModal = (newUser) => {
    this.setState({
      confirmActionModal: true,
      newUser,
    });
  }

  oninputChange = (event, input) => {
    this.setState({
      [input] : event.target.value
    });
  }

  getFormattedDocumentType = (documentType) => {
    let formattedDocumentType = documentType;
    if (documentType === 'C') {
        formattedDocumentType = 'CC';
    }
    if (documentType === 'E') {
        formattedDocumentType = 'CE';
    }
    return formattedDocumentType;
  }

  render() {
    const {
      menuOpen,
      addUserModal,
      addSuccessModal,
      confirmActionModal,
      newUser,
      updateModal,
      userDocumentNumber
    } = this.state;
    const {
      loading,
      managedUsers,
      managedUsersLoading,
      managedUsersPageCount,
      globals,
      user
    } = this.props;
    let departments = globals && globals.DEPARTAMENTOS ? [...globals.DEPARTAMENTOS] : [];
    departments = [{LABEL: "NACIONAL", ID: "1111"}].concat(departments);
    return (
      <div className="manage-users">
        <AddUser
          show={addUserModal}
          onHide={this.addUserModalClose}
          addUser={this.addUser}
          newUser={newUser}
        />
        <AddSuccess
          show={addSuccessModal}
          onHide={this.addSuccessModalClose}
          newUser={newUser}
          updateModal={updateModal}
        />
        <ConfirmAction
          show={confirmActionModal}
          onHide={this.confirmActionModalClose}
          newUser={newUser}
          confirmAction={this.addUser}
        />
        <AddUser />
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={13} />
        <Header openMenu={this.openMenu} />
        <div className="page-body-container center-horizontally">
          <div className="page-body">
            <UserHeader />
            <Col xs={12} className="general-main-card">
              { (loading) && (
                <Loading />
              )}
              <div xs={12} className="goback-container">
                <div
                  className="goback-action"
                  onClick={(e) => {
                    e.preventDefault();
                    this.goHome();
                  }}
                >
                  <span>
                    <img src={arrowback} alt="arrow back" className="goback-icon" />
                  </span>
                  <span className="goback-text">Volver</span>
                </div>
              </div>
              <Col xs={12} className="manage-users-margin">
                  <Col xs={12} className="card-title">
                    Administrar usuarios
                  </Col>
                  <Col xs={12} >
                    <hr />
                  </Col>
              </Col>
              <Col xs={12}>
                <Col xs={12}>
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col xs={12} className="manage-users-margin-top">
                          <Row>
                            <Col xs={12} md={4} className="manage-users-margin">
                              <Button
                                className="button-send"
                                variant="success"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.addUserModal();
                                }}
                                block
                                >
                              Agregar usuario
                              </Button>
                            </Col>
                            <Col xs={12} md={{span: 7, offset: 1}} className="manage-users-margin">
                              <Row>
                                <Col>
                                  {/*
                                    <div className="search-input-container">
                                    <span>
                                    <img src={searchicon} alt="Icono buscar"/>
                                    </span>
                                    <input placeholder="Buscar número documento" />
                                    </div>
                                    */}
                                  <TextField
                                    id="userDocumentNumber"
                                    label="Buscar número documento"
                                    className="home-input"
                                    margin="dense"
                                    value={userDocumentNumber}
                                    onChange={(event) => {
                                      this.oninputChange(event, 'userDocumentNumber');
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <img src={searchicon} alt="icono calendario" className="calendar-icon"/>
                                      ),
                                    }}
                                    fullWidth
                                    />
                                </Col>
                                <Col xs={5}>
                                  <Button
                                    className="button-send"
                                    variant="success"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.page = 0;
                                      this.getManagedUsers(this.props.user);
                                    }}
                                    block
                                  >
                                  Buscar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Col>
              {user && (
                <ReduxLazyScroll
                  id="scroll-pager"
                  isFetching={managedUsersLoading}
                  errorMessage=''
                  isParentScrollable={true}
                  parentHeight='500px'
                  loadMore={() => {
                    this.getManagedUsers(user);
                  }}
                  hasMore={managedUsersPageCount > 0}
                  >
                  {managedUsers.map((item, index) => {
                    return (
                      <Col xs={12} key={index} className="manage-users_user">
                        <Row className="manage-users_info-container">
                          <Col xs={12} md={4}>
                            <Row>
                              <Col xs={12} md="auto" className="manage-users_user-key-container">
                                { item.ESTADO === 'A' && (
                                  <img src={item.FOTO_USUARIO ? item.FOTO_USUARIO : userLogo} alt="imagen usuario" className="manage-users_user-image"/>
                                )}
                                { item.ESTADO === 'I' && (
                                  <img src={emptyuser} alt="imagen usuario" className="manage-users_user-image"/>
                                )}
                              </Col>
                              <Col xs={12} md className="center-vertically manage-users_user-key-container">
                                <Row>
                                  <Col xs={12} className={`${'manage-users_user-key'}${item.ESTADO === 'I' ? ' disabled-color': ''}`}>{item.NOMBRES} {item.APELLIDOS}</Col>
                                  <Col xs={12} className={`${'manage-users_user-value'}${item.ESTADO === 'I' ? ' disabled-color': ''}`}>{this.getFormattedDocumentType(item.TIPO_DOCUMENTO_USUARIO)}{item.DOCUMENTO_USUARIO}</Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md className="center-vertically manage-users_user-key-container">
                            <Row>
                              <Col xs={12} className={`${'manage-users_user-key'}${item.ESTADO === 'I' ? ' disabled-color': ''}`}>Sucursal</Col>
                              <Col xs={12} className={`${'manage-users_user-value'}${item.ESTADO === 'I' ? ' disabled-color': ''}`}>{item.SUCURSAL}</Col>
                            </Row>
                          </Col>
                          <Col xs={12} md className="center-vertically manage-users_user-key-container">
                            <Row>
                              <Col xs={12} className={`${'manage-users_user-key'}${item.ESTADO === 'I' ? ' disabled-color': ''}`}>Correo electrónico</Col>
                              <Col xs={12} className={`${'manage-users_user-value'}${item.ESTADO === 'I' ? ' disabled-color': ''}`}>{item.E_MAIL_OFICINA}</Col>
                            </Row>
                          </Col>
                          <Col xs={12} md className="center-vertically manage-users_user-key-container">
                            <Row>
                              <Col xs={12} className={`${'manage-users_user-key'}${item.ESTADO === 'I' ? ' disabled-color': ''}`}>Rol</Col>
                              <Col xs={12} className={`${'manage-users_user-value'}${item.ESTADO === 'I' ? ' disabled-color': ''}`}>{item.DESCROL}</Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="manage-users_info-second-container">
                          { (item.CAMBIO_CONTRASEÑA === 'Usuario no ha cambiado contraseña' && item.ESTADO === 'A') && (
                            <Col xs={12} md>
                              <Row>
                                <Col xs className="manage-users_change-password">
                                  <img src={redalert} alt="icono alerta" className="alert-icon"/>
                                  Usuario no ha cambiado contraseña
                                </Col>
                              </Row>
                            </Col>
                          )}
                          <Col
                            xs={12}
                            md="auto"
                            className={`${'activate'}${item.ESTADO === 'I' ? ' disabled-button-color': ''}`}
                            onClick={(e) => {
                              e.preventDefault();
                              let homeDepartment = departments.find((department) => {
                                return department.LABEL === item.NOMBRE_DEPTO
                              })
                              let names = item.NOMBRES.split(' ');
                              let lastnames = item.APELLIDOS.split(' ');
                              let firstName =  names[0];
                              let secondName = names.length > 1 ? names[1] : '';
                              let lastName = lastnames[0];
                              let secondLastName = lastnames.length > 1 ? lastnames[1] : '';
                              let newUser = {
                                documentType: item.TIPO_DOCUMENTO_USUARIO,
                                documentNumber: item.DOCUMENTO_USUARIO,
                                homeDepartment: homeDepartment ? homeDepartment.ID : 10,
                                homeCity: parseInt(item.CODIGO_DANE),
                                cellular: item.TEL_DIRECTO_1,
                                email: item.E_MAIL_OFICINA,
                                firstName,
                                secondName,
                                lastName,
                                secondLastName,
                                branch: item.SUCURSAL,
                                role: item.IDROL,
                                status: 'A',
                                action: 'ACTUALIZAR'
                              }
                              if(item.ESTADO === 'A') {
                                this.editUserModal(newUser);
                              } else {
                                this.openConfirmActionModal(newUser);
                              }
                            }}
                            >
                            { item.ESTADO === 'A' && (
                              'Actualizar'
                            )}
                            { item.ESTADO === 'I' && (
                              'Reactivar'
                            )}
                          </Col>
                          <Col
                            xs={12}
                            md="auto"
                            className={`${'disable'}${item.ESTADO === 'I' ? ' disabled-button-color': ''}`}
                            onClick={(e) => {
                              e.preventDefault();
                              let homeDepartment = departments.find((department) => {
                                return department.LABEL === item.NOMBRE_DEPTO
                              })
                              let names = item.NOMBRES.split(' ');
                              let lastnames = item.APELLIDOS.split(' ');
                              let firstName =  names[0];
                              let secondName = names.length > 1 ? names[1] : '';
                              let lastName = lastnames[0];
                              let secondLastName = lastnames.length > 1 ? lastnames[1] : '';
                              let newUser = {
                                documentType: item.TIPO_DOCUMENTO_USUARIO,
                                documentNumber: item.DOCUMENTO_USUARIO,
                                homeDepartment: homeDepartment ? homeDepartment.ID : 10,
                                homeCity: parseInt(item.CODIGO_DANE),
                                cellular: item.TEL_DIRECTO_1,
                                email: item.USUARIO,
                                firstName,
                                secondName,
                                lastName,
                                secondLastName,
                                branch: item.SUCURSAL,
                                role: item.IDROL,
                                status: 'I',
                                action: 'ACTUALIZAR'
                              }
                              if(item.ESTADO === 'A') {
                                this.openConfirmActionModal(newUser);
                              } else {
                                newUser.status = 'E'
                                newUser.action = 'RETIRAR'
                                this.openConfirmActionModal(newUser);
                              }
                            }}
                            >
                            { item.ESTADO === 'A' && (
                              'Desactivar'
                            )}
                            { item.ESTADO === 'I' && (
                              'Eliminar'
                            )}
                          </Col>
                        </Row>
                      </Col>
                    )
                  })}
                  { (managedUsersLoading ) && (
                    <ListLoader />
                  )}
                </ReduxLazyScroll>
              )}
            </Col>
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
      user,
      managedUsers,
      managedUsersLoading,
      loading,
      managedUsersUpdate,
      globals,
      managedUsersPageCount,
  } = state.user;
  return {
    user,
    managedUsers,
    managedUsersLoading,
    loading,
    managedUsersUpdate,
    globals,
    managedUsersPageCount
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
