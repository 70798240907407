import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './home.scss';
import * as actions from '../../redux/actions';
import { Row, Col, Button } from 'react-bootstrap';
// import reservesListIcon from '../../assets/icons/iconolistadoreservas.svg';
// import bulkTransactions from '../../assets/icons/iconotransaccionmasiva.svg';
// import arrow from '../../assets/icons/arrow.svg';
// import arrowGray from '../../assets/icons/arrowGray.svg';
// import myPaymentsIcon from '../../assets/icons/iconomispagos.svg';
// import clientResearchIcon from '../../assets/icons/iconoestudiocliente.svg';
// import exonerationIcon from '../../assets/icons/iconoexoneracion.svg';
// import claimIcon from '../../assets/icons/iconoreclamacion.svg';
import { TextField, MenuItem } from '@material-ui/core';
import Footer from '../footer';
import Header from '../header';
import Menu from '../menu';
import Chat from '../chat';
import UserHeader from '../user-header';
import TermsModal from './terms-modal';
import InfoModal from './info-modal';
import ErrorModal from '../error-modal';
import SelectClientModal from '../client-study/select-client-modal';

import QueryCodeModal from './querycode-modal';
import Loading from '../loading';
import Reservations from '../reservations';
import MassiveTransaction from '../massive-transaction';
// import clientstudygray from '../../assets/icons/clientstudygray.svg';
// import exonerationgray from '../../assets/icons/exonerationgray.svg';
// import mypaymentsgray from '../../assets/icons/mypaymentsgray.svg';
// import reclamationgray from '../../assets/icons/reclamationgray.svg';
// import reservationsgray from '../../assets/icons/reservationsgray.svg';
// import trasactiongray from '../../assets/icons/trasactiongray.svg';

class Home extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      termModal: false,
      infoModal: false,
      errorModal: false,
      queryCodeModal: false,
      selectedDocument: '',
      documentNumber: '',
      queryCode: '',
      selectedTab: 0,
      queryCodeModalThreePayments: false,
      selectClientModal: true,
      selectedAffiliate: null,
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.termModalClose = this.termModalClose.bind(this);
    this.massiveTransactionRef = React.createRef();
    this.reservationsRef = React.createRef();
  }

  componentDidMount() {
    const { userQuota } = this.props;
    // let paramsString = this.props.location.search;
    // const params = new URLSearchParams(paramsString);
    // const term = params.get("info");
    // if (term) {
    //   this.setState({
    //     infoModal: true,
    //   });
    // }
    if (userQuota) {
      let userQuotaInfo =
        userQuota &&
        userQuota.CUPO_COMPRADOR &&
        userQuota.CUPO_COMPRADOR.length > 0
          ? userQuota.CUPO_COMPRADOR[0]
          : false;
      let queryCode =
        userQuota && userQuota.CODIGO_CONSULTA ? userQuota.CODIGO_CONSULTA : '';
      if (userQuotaInfo && userQuotaInfo.ESTADO === 'PERMITIR SIN CUPO') {
        this.resetForm();
      }

      if (userQuotaInfo) {
        this.setState({
          selectedDocument: userQuotaInfo.TIPO_DOCUMENTO,
          documentNumber: userQuotaInfo.DOCUMENTO,
          queryCode,
        });
      } else {
        this.resetForm();
      }
    } else {
      this.loadData();
    }
  }

  loadData() {
    this.props.actions.getUser();
    this.props.actions.getAppGlobals();
  }

  getRolPermissions(user) {
    this.props.actions.getRolPermissions({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      idRol: user.userInfo.ROL_USUARIO,
      reload: false,
    });
  }

  getTermsAcepted = (user) => {
    this.props.actions.getTermsAcepted({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      userEmail: user.userEmail,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO_USUARIO,
      userDocumentNumber: user.userInfo.DOCUMENTO_USUARIO,
    });
  };

  setTermsAcepted = () => {
    const { user } = this.props;
    this.setState(
      {
        termModal: false,
      },
      () => {
        let url = window.location.href;
        this.props.actions.setTermsAcepted({
          url,
          loginDate: user.userInfo.FECHA_INGRESO,
          documentType: user.userInfo.TIPO_DOCUMENTO,
          documentNumber: user.userInfo.DOCUMENTO,
          userEmail: user.userEmail,
          userDocumentType: user.userInfo.TIPO_DOCUMENTO_USUARIO,
          userDocumentNumber: user.userInfo.DOCUMENTO_USUARIO,
          termsType: '[1]',
        });
        // this.props.history.push(`/perfil?terms=1`);
      }
    );
  };

  setQueryCode = (queryCode) => {
    if (!queryCode) return;
    this.setState(
      {
        queryCodeModal: false,
        queryCode,
      },
      () => {
        this.handleSubmit();
      }
    );
  };

  //getDerivedStateFromProps or componentDidUpdate(nextProps) (better)
  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.getRolPermissions(nextProps.user);
      this.getTermsAcepted(nextProps.user);
    }
    if (nextProps.termsAccepted !== this.props.termsAccepted) {
      if ('ACEPTACION' in nextProps.termsAccepted) {
        if (!nextProps.termsAccepted.ACEPTACION[1]) {
          // let userJson = JSON.parse(localStorage.getItem('user'));
          // let globalsJson = JSON.parse(localStorage.getItem('globals'));
          // let idPais =
          //   'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
          //     ? globalsJson.PAIS[0].ID_PAIS
          //     : '1';
          // window.gtag('event', 'mostrar_terminos', {
          //   event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
          //   event_label: userJson.userEmail,
          // });
          this.setState({
            termModal: true,
          });
        }
      }
    }

    if (nextProps.userQuota && nextProps.userQuota !== this.props.userQuota) {
      let userQuotaInfo =
        nextProps.userQuota &&
        'CUPO_COMPRADOR' in nextProps.userQuota &&
        nextProps.userQuota.CUPO_COMPRADOR.length > 0
          ? nextProps.userQuota.CUPO_COMPRADOR[0]
          : false;

      const threePaymentsCode =
        nextProps.userQuota && nextProps.userQuota.TRES_PAGOS === 'SI';
      const threePaymentsQuota =
        nextProps.userQuota && nextProps.userQuota.CUPO_TRES_PAGOS === 'SI';
      const threePaymentsStatus = nextProps.userQuota
        ? nextProps.userQuota.TRANSACCION_TRES_PAGOS
        : '';

      const {
        selectedAffiliate: {
          CONTRATO,
          DOCUMENTO,
          ESTADO,
          NOMBRE,
          N_REGISTROS,
          PRODUCTO,
          RN,
          TIPO_DOCUMENTO,
        },
      } = this.state;

      const threePaymentsQuery = `&DOCUMENTO=${DOCUMENTO}&CONTRATO=${CONTRATO}&ESTADO=${ESTADO}&NOMBRE=${NOMBRE}&N_REGISTROS=${N_REGISTROS}&PRODUCTO=${PRODUCTO}&RN=${RN}&TIPO_DOCUMENTO=${TIPO_DOCUMENTO}`;

      if (threePaymentsCode && userQuotaInfo) {
        this.resetForm();
        this.setState({ queryCodeModalThreePayments: true }, () => {
          this.openQueryCodeModal();
        });
      } else {
        this.setState({ queryCodeModalThreePayments: false });
        if (threePaymentsCode && !threePaymentsQuota) {
          const { selectedDocument, documentNumber, queryCode } = this.state;
          this.props.history.push(
            `/estudio-de-cliente?threepayments=${queryCode}&documentType=${selectedDocument}&documentNumber=${documentNumber}${threePaymentsQuery}`
          );
        }
        if (
          threePaymentsCode &&
          threePaymentsQuota &&
          threePaymentsStatus === 'REINCIDENTE'
        ) {
          const { selectedDocument, documentNumber, queryCode } = this.state;

          this.props.history.push(
            `/transacciones/${selectedDocument}/${documentNumber}/${queryCode}?threepayments=true${threePaymentsQuery}`
          );
        }
        if (!threePaymentsCode) {
          this.setState({ errorModal: true });
        }
      }

      if (userQuotaInfo && userQuotaInfo.ESTADO === 'PERMITIR SIN CUPO') {
        const { selectedDocument, documentNumber, queryCode } = this.state;
        this.props.history.push(
          `/transacciones/${selectedDocument}/${documentNumber}/${queryCode}`
        );
      }
    }
  }

  handleSubmit = () => {
    const { selectedAffiliate } = this.state;
    // let idPais =
    //   'PAIS' in this.props.globals && 'ID_PAIS' in this.props.globals.PAIS[0]
    //     ? this.props.globals.PAIS[0].ID_PAIS
    //     : '1';
    // window.gtag('event', 'pr_cupo', {
    //   event_category: `${this.props.user.userInfo.TIPO_DOCUMENTO}:${this.props.user.userInfo.DOCUMENTO}:${idPais}`,
    //   event_label: this.props.user.userEmail,
    // });
    this.props.actions.getQuota({
      documentType: this.state.selectedDocument,
      documentNumber: this.state.documentNumber,
      userDocument: selectedAffiliate.DOCUMENTO,
      userDocumentType: selectedAffiliate.TIPO_DOCUMENTO,
      queryCode: this.state.queryCode,
    });
  };

  resetForm = () => {
    this.props.actions.resetQuota();
  };

  goTransaction = () => {
    const { selectedDocument, documentNumber, queryCode } = this.state;
    this.props.history.push(
      `/transacciones/${selectedDocument}/${documentNumber}/${queryCode}`
    );
  };

  goReserve = () => {
    const { selectedDocument, documentNumber, queryCode } = this.state;
    this.props.history.push(
      `/reservar/${selectedDocument}/${documentNumber}/${queryCode}`
    );
  };

  goReservations = () => {
    this.props.history.push('/reservas');
  };

  goClientStudy = () => {
    this.props.history.push(`/estudio-de-cliente`);
  };

  goMyPayments = () => {
    this.props.history.push(`/mis-pagos`);
  };

  goExoneration = () => {
    this.props.history.push(`/exoneracion`);
  };

  goClaim = () => {
    this.props.history.push(`/reclamo-aviso-no-pago`);
  };

  goBulkTransactions = () => {
    this.props.history.push(`/transaccion-masiva`);
  };

  closeMenu() {
    this.setState({
      menuOpen: false,
    });
  }

  closeSelectClientModal = () => {};

  selectAffiliate = (selectedAffiliate) => {
    this.setState(
      {
        selectClientModal: false,
        selectedAffiliate,
      },
      () => {
        this.props.actions.resettAffiliateUsers();
      }
    );
  };

  openMenu() {
    this.setState({
      menuOpen: true,
    });
  }

  openQueryCodeModal = () => {
    this.setState({
      queryCodeModal: true,
    });
  };

  queryCodeModalClose = () => {
    this.resetForm();
    this.setState({
      queryCodeModal: false,
    });
  };

  termModalClose() {
    // this.setState({
    //   termModal: false
    // });
  }

  infoModalClose = () => {
    this.setState({
      infoModal: false,
    });
  };

  errorModalClose = () => {
    this.setState(
      {
        errorModal: false,
      },
      () => {
        this.resetForm();
      }
    );
  };

  selectDocument = (event) => {
    let selectedDocument = event.target.value;
    this.setState({
      selectedDocument,
    });
  };

  onDocumentNumberChange = (event) => {
    this.setState({
      documentNumber: event.target.value,
    });
  };

  getDocumentTypeName() {
    const { globals } = this.props;
    const { selectedDocument } = this.state;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let docuemntItem = documents.find((item) => {
      return item.ID === selectedDocument;
    });
    return docuemntItem ? docuemntItem.LABEL : '';
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    let thousandArray = thousand.split('.');
    if (thousandArray.length > 2) {
      thousand = thousand.replace('.', '´');
    }
    return thousand;
  }

  changeTab = (tab) => {
    this.setState(
      {
        selectedTab: tab,
      },
      () => {
        if (tab === 0) {
          // let userJson = JSON.parse(localStorage.getItem('user'));
          // let globalsJson = JSON.parse(localStorage.getItem('globals'));
          // let idPais =
          //   'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
          //     ? globalsJson.PAIS[0].ID_PAIS
          //     : '1';
          // window.gtag('event', 'tab_transaccionindividual', {
          //   event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
          //   event_label: userJson.userEmail,
          // });
          this.resetForm();
        }
        if (tab === 1) {
          // let userJson = JSON.parse(localStorage.getItem('user'));
          // let globalsJson = JSON.parse(localStorage.getItem('globals'));
          // let idPais =
          //   'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
          //     ? globalsJson.PAIS[0].ID_PAIS
          //     : '1';
          // window.gtag('event', 'tab_transaccionmasiva', {
          //   event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
          //   event_label: userJson.userEmail,
          // });
          if (this.massiveTransactionCtrl) {
            this.massiveTransactionCtrl.resetTab();
          }
        }
        if (tab === 2) {
          // let userJson = JSON.parse(localStorage.getItem('user'));
          // let globalsJson = JSON.parse(localStorage.getItem('globals'));
          // let idPais =
          //   'PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]
          //     ? globalsJson.PAIS[0].ID_PAIS
          //     : '1';
          // window.gtag('event', 'tab_listadoreserva', {
          //   event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
          //   event_label: userJson.userEmail,
          // });
          if (this.reservationsCtrl) {
            this.reservationsCtrl.resetTab();
          }
        }
      }
    );
  };

  render() {
    const {
      menuOpen,
      termModal,
      infoModal,
      errorModal,
      selectedDocument,
      documentNumber,
      selectedTab,
      queryCodeModal,
      queryCodeModalThreePayments,
      selectClientModal,
    } = this.state;
    const { globals, userQuota, loading, permissions, user } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let userQuotaInfo =
      userQuota &&
      'CUPO_COMPRADOR' in userQuota &&
      userQuota.CUPO_COMPRADOR.length > 0
        ? userQuota.CUPO_COMPRADOR[0]
        : false;
    const threePaymentsCode = userQuota && userQuota.TRES_PAGOS === 'SI';
    const threePaymentsQuota = userQuota && userQuota.CUPO_TRES_PAGOS === 'SI';
    const threePaymentsStatus = userQuota
      ? userQuota.TRANSACCION_TRES_PAGOS
      : '';
    let trasactionAvailable =
      userQuota && (userQuota.FACTURA === 'SI' || userQuota.CHEQUE === 'SI')
        ? true
        : false;
    let reserveAvailable =
      userQuota && userQuota.RESERVA === 'SI' ? true : false;
    // if(userQuotaInfo) {
    //   userQuotaInfo.ESTADO = 'SUSPENDIDO';
    // }
    let permissionsAray =
      permissions && 'MENU' in permissions ? permissions.MENU : [];
    let permissionMasive = permissionsAray.find(
      (permission) => parseInt(permission.IDOPCION) === 4
    );
    // let reservations = permissionsAray.find(permission => parseInt(permission.IDOPCION) === 5);
    // let notice = permissionsAray.find(permission => parseInt(permission.IDOPCION) === 6);
    // let exoneration = permissionsAray.find(permission => parseInt(permission.IDOPCION) === 8);
    // let clientStudy = permissionsAray.find(permission => parseInt(permission.IDOPCION) === 15);
    // let mypayments = permissionsAray.find(permission => parseInt(permission.IDOPCION) === 11);
    let transactionPermision = permissionsAray.find(
      (permission) => parseInt(permission.IDOPCION) === 2
    );
    let clientStudyPermission =
      user && user.userInfo && user.userInfo.ESTUDIO_CUPO === 'SI';
    // let permissionMasive = false;
    // let reservations = false;
    // let notice = false;
    // let exoneration = false;
    // let clientStudy = false;
    // let mypayments = false;
    return (
      <div className='home'>
        <TermsModal
          show={termModal}
          confirm={this.setTermsAcepted}
          onHide={this.termModalClose}
        />
        <InfoModal
          show={infoModal}
          confirm={this.infoModalClose}
          onHide={this.infoModalClose}
        />
        <ErrorModal
          show={errorModal}
          confirm={this.errorModalClose}
          onHide={this.errorModalClose}
          errorText={
            'El código de consulta no corresponde a un código de tres pagos'
          }
          errorTitle={'Error'}
        />
        <QueryCodeModal
          show={queryCodeModal}
          confirm={this.setQueryCode}
          onHide={this.queryCodeModalClose}
          selectedDocument={selectedDocument}
          queryCodeModalThreePayments={queryCodeModalThreePayments}
          documentNumber={documentNumber}
        />
        <SelectClientModal
          show={selectClientModal}
          onHide={this.closeSelectClientModal}
          selectAffiliate={this.selectAffiliate}
        />
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={100} />
        <Header openMenu={this.openMenu} />
        <div className='page-body-container center-horizontally'>
          <div className='page-body'>
            <UserHeader />
            <Col xs={12} className='general-main-card'>
              <Col xs={12}>
                <Col xs={12} className='tab-container-home'>
                  <Row>
                    <Col xs={12} className='tab-container'>
                      <Row>
                        <Col
                          className={
                            selectedTab === 0 ? 'tab-text-selected' : 'tab-text'
                          }
                          onClick={() => {
                            this.changeTab(0);
                          }}
                        >
                          Transacción individual
                        </Col>
                        {/* {permissionMasive && permissionMasive.ESTADO !== 'O' && (
                          <Col
                            xs={4}
                            className={
                              permissionMasive.ESTADO === 'I'
                                ? 'tab-text-disabled'
                                : selectedTab === 1
                                ? 'tab-text-selected'
                                : 'tab-text'
                            }
                            onClick={() => {
                              if (permissionMasive.ESTADO === 'A') {
                                this.changeTab(1);
                              }
                            }}
                          >
                            Transacción masiva
                          </Col>
                        )}
                        <Col
                          xs={4}
                          className={
                            selectedTab === 2 ? 'tab-text-selected' : 'tab-text'
                          }
                          onClick={() => {
                            this.changeTab(2);
                          }}
                        >
                          Listado de reservas
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Col>
              {selectedTab === 0 && (
                <Col xs={12} className='box-card-container'>
                  <Row className='justify-content-md-center'>
                    {!userQuota && !loading && (
                      <Col xs={12}>
                        <Row className='justify-content-md-center'>
                          <Col
                            xs={12}
                            md={10}
                            className='home_transaction-form-title'
                          >
                            Para realizar una transacción ingrese el tipo y
                            número de documento de su cliente y haga clic en
                            “Realizar transacción”.
                          </Col>
                          <Col xs={12} md={5} className='login-input-container'>
                            <TextField
                              id='documentType'
                              select
                              label='Tipo de documento'
                              className='home-input'
                              margin='dense'
                              value={selectedDocument}
                              onChange={this.selectDocument}
                              fullWidth
                            >
                              {documents.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.ID}>
                                    {item.LABEL}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </Col>
                          <Col xs={12} md={5} className='login-input-container'>
                            <TextField
                              id='documentNumber'
                              label='Número de documento'
                              className='home-input'
                              margin='dense'
                              type='number'
                              value={documentNumber}
                              onChange={this.onDocumentNumberChange}
                              fullWidth
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {loading && (
                      <Col xs={10} md={8}>
                        <Row className='justify-content-md-center'>
                          <Col xs={12} className='home_loading-container'>
                            <Loading />
                          </Col>
                          <Col xs={12} className='home_loading-text'>
                            Estamos buscando en nuestra base de datos...
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {userQuota && !loading && (
                      <Col xs={12} className='home_query-container'>
                        {userQuotaInfo && (
                          <Col xs={12}>
                            <Row className='justify-content-md-center'>
                              {userQuotaInfo.ESTADO !== 'HABILITADO' &&
                                userQuotaInfo.ESTADO !==
                                  'PERMITIR SIN CUPO' && (
                                  <Col
                                    xs={12}
                                    md={10}
                                    className='home_query-unregistered'
                                  >
                                    El número de identificación{' '}
                                    <span className='home_query-unregistered-bold'>
                                      {this.getDocumentTypeName()}{' '}
                                      {documentNumber}
                                    </span>{' '}
                                    se encuentra{' '}
                                    {userQuotaInfo.ESTADO.toLowerCase()} en este
                                    momento, valide que la información sea
                                    correcta y realice una nueva consulta.
                                  </Col>
                                )}
                              {(userQuotaInfo.ESTADO === 'HABILITADO' ||
                                userQuotaInfo.ESTADO ===
                                  'PERMITIR SIN CUPO') && (
                                <Col
                                  xs={12}
                                  md={10}
                                  className='home_query-unregistered'
                                >
                                  El número identificado con{' '}
                                  <span className='home_query-unregistered-bold'>
                                    {this.getDocumentTypeName()}{' '}
                                    {documentNumber}
                                  </span>{' '}
                                  se encuentra{' '}
                                  <span className='home_query-unregistered-bold'>
                                    HABILITADO
                                  </span>{' '}
                                  para realizar transacciones.
                                </Col>
                              )}
                              <Col xs={12}>
                                <Row className='justify-content-md-center'>
                                  <Col
                                    xs={12}
                                    md={4}
                                    className='home_query-row'
                                  >
                                    <Row>
                                      <Col xs={12} className='home_query-key'>
                                        Nombre
                                      </Col>
                                      {userQuotaInfo.NOMBRES && (
                                        <Col
                                          xs={12}
                                          className='home_query-value capitalize'
                                        >
                                          {userQuotaInfo.NOMBRES.toLowerCase()}
                                        </Col>
                                      )}
                                      {!userQuotaInfo.NOMBRES && (
                                        <Col
                                          xs={12}
                                          className='home_query-value'
                                        >
                                          <span>--</span>
                                        </Col>
                                      )}
                                    </Row>
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={4}
                                    className='home_query-row'
                                  >
                                    <Row>
                                      <Col xs={12} className='home_query-key'>
                                        Estado cliente
                                      </Col>
                                      {userQuotaInfo.ESTADO ===
                                        'HABILITADO' && (
                                        <Col
                                          xs={12}
                                          className='home_query-value'
                                        >
                                          {'ALERTA' in userQuotaInfo &&
                                            userQuotaInfo.ALERTA ===
                                              'A RETIRAR' &&
                                            'Habilitado - Cupo a retirar'}
                                          {'ALERTA' in userQuotaInfo &&
                                            userQuotaInfo.ALERTA ===
                                              'CON ACUERDO' &&
                                            'Habilitado - Con acuerdo'}
                                          {!('ALERTA' in userQuotaInfo) ||
                                            ('ALERTA' in userQuotaInfo &&
                                              userQuotaInfo.ALERTA !==
                                                'CON ACUERDO' &&
                                              userQuotaInfo.ALERTA !==
                                                'A RETIRAR' &&
                                              'Habilitado')}
                                        </Col>
                                      )}
                                      {userQuotaInfo.ESTADO !== 'HABILITADO' &&
                                        userQuotaInfo.ESTADO !==
                                          'PERMITIR SIN CUPO' && (
                                          <Col
                                            xs={12}
                                            className='home_query-value red-value'
                                          >
                                            {'ALERTA' in userQuotaInfo &&
                                              userQuotaInfo.ALERTA ===
                                                'OPCION ACUERDO' &&
                                              `${userQuotaInfo.ESTADO.toLowerCase()} - opción acuerdo`}
                                            {!('ALERTA' in userQuotaInfo) ||
                                              ('ALERTA' in userQuotaInfo &&
                                                userQuotaInfo.ALERTA !==
                                                  'OPCION ACUERDO' &&
                                                `${userQuotaInfo.ESTADO.toLowerCase()}`)}
                                          </Col>
                                        )}
                                      {userQuotaInfo.ESTADO ===
                                        'PERMITIR SIN CUPO' && (
                                        <Col
                                          xs={12}
                                          className='home_query-value'
                                        >
                                          <span>--</span>
                                        </Col>
                                      )}
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={12}>
                                <Row className='justify-content-md-center'>
                                  <Col
                                    xs={12}
                                    md={4}
                                    className='home_query-row'
                                  >
                                    <Row>
                                      <Col xs={12} className='home_query-key'>
                                        {'ALERTA' in userQuotaInfo &&
                                        userQuotaInfo.ALERTA === 'CON ACUERDO'
                                          ? 'Cupo inicial del acuerdo'
                                          : 'Cupo'}
                                      </Col>
                                      <Col xs={12} className='home_query-value'>
                                        {userQuotaInfo.ESTADO !==
                                          'HABILITADO' && <span>--</span>}
                                        {userQuotaInfo.ESTADO ===
                                          'HABILITADO' &&
                                          'ALERTA' in userQuotaInfo &&
                                          userQuotaInfo.ALERTA ===
                                            'CON ACUERDO' && (
                                            <span>
                                              $
                                              {this.getNumber(
                                                userQuotaInfo.CUPO_ACUERDO
                                              )}
                                            </span>
                                          )}
                                        {userQuotaInfo.ESTADO ===
                                          'HABILITADO' &&
                                          (!('ALERTA' in userQuotaInfo) ||
                                            userQuotaInfo.ALERTA !==
                                              'CON ACUERDO') && (
                                            <span>
                                              $
                                              {this.getNumber(
                                                userQuotaInfo.CUPO_ASIGNADO
                                              )}
                                            </span>
                                          )}
                                      </Col>
                                    </Row>
                                  </Col>
                                  {'EXTRACUPO' in userQuotaInfo && (
                                    <Col
                                      xs={12}
                                      md={4}
                                      className='home_query-row'
                                    >
                                      <Row>
                                        <Col xs={12} className='home_query-key'>
                                          Extracupo
                                        </Col>
                                        <Col
                                          xs={12}
                                          className='home_query-value'
                                        >
                                          {userQuotaInfo.ESTADO !==
                                            'HABILITADO' && <span>--</span>}
                                          {userQuotaInfo.ESTADO ===
                                            'HABILITADO' &&
                                            (!('ALERTA' in userQuotaInfo) ||
                                              userQuotaInfo.ALERTA !==
                                                'CON ACUERDO') && (
                                              <span>
                                                $
                                                {this.getNumber(
                                                  userQuotaInfo.EXTRACUPO
                                                )}
                                              </span>
                                            )}
                                        </Col>
                                      </Row>
                                    </Col>
                                  )}
                                </Row>
                              </Col>
                              <Col xs={12}>
                                <Row className='justify-content-md-center'>
                                  <Col
                                    xs={12}
                                    md={4}
                                    className='home_query-row'
                                  >
                                    <Row>
                                      <Col xs={12} className='home_query-key'>
                                        {'ALERTA' in userQuotaInfo &&
                                        userQuotaInfo.ALERTA === 'CON ACUERDO'
                                          ? 'Disponible Acuerdo'
                                          : 'Disponible'}
                                      </Col>
                                      <Col xs={12} className='home_query-value'>
                                        {userQuotaInfo.ESTADO !==
                                          'HABILITADO' && <span>--</span>}
                                        {userQuotaInfo.ESTADO ===
                                          'HABILITADO' &&
                                          'ALERTA' in userQuotaInfo &&
                                          userQuotaInfo.ALERTA ===
                                            'CON ACUERDO' && (
                                            <span>
                                              $
                                              {this.getNumber(
                                                userQuotaInfo.CUPO_DISPONIBLE_ACUERDO
                                              )}
                                            </span>
                                          )}
                                        {userQuotaInfo.ESTADO ===
                                          'HABILITADO' &&
                                          (!('ALERTA' in userQuotaInfo) ||
                                            userQuotaInfo.ALERTA !==
                                              'CON ACUERDO') && (
                                            <span>
                                              $
                                              {this.getNumber(
                                                userQuotaInfo.CUPO_DISPONIBLE
                                              )}
                                            </span>
                                          )}
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={4}
                                    className='home_query-row'
                                  >
                                    <Row>
                                      <Col
                                        xs={12}
                                        className='home_query-key'
                                      ></Col>
                                      <Col
                                        xs={12}
                                        className='home_query-value'
                                      ></Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        {!userQuotaInfo && (
                          <Row className='justify-content-md-center'>
                            {threePaymentsQuota ? (
                              <Col
                                xs={12}
                                md={10}
                                className='home_query-unregistered'
                              >
                                El comprador{' '}
                                <span className='home_query-unregistered-bold'>
                                  {userQuota.NOMBRES}
                                </span>{' '}
                                con número de identificación{' '}
                                <span className='home_query-unregistered-bold'>
                                  {this.getDocumentTypeName()} {documentNumber}
                                </span>{' '}
                                cuenta con una factura{' '}
                                {threePaymentsStatus.toLowerCase()} en el
                                servicio{' '}
                                <span className='home_query-unregistered-bold'>
                                  COVIFACTURA 3 PAGOS
                                </span>
                              </Col>
                            ) : (
                              <Col
                                xs={12}
                                md={10}
                                className='home_query-unregistered'
                              >
                                El número de identificación{' '}
                                <span className='home_query-unregistered-bold'>
                                  {this.getDocumentTypeName()} {documentNumber}
                                </span>{' '}
                                no se encuentra registrado en nuestra base de
                                datos.
                              </Col>
                            )}

                            <Col
                              xs={12}
                              md={9}
                              className='home_query-unregistered-second'
                            >
                              Si los datos estan mal digitados haga clic en{' '}
                              <span className='home_query-unregistered-bold space-bold'>
                                {' '}
                                Nueva consulta
                              </span>
                            </Col>
                            {clientStudyPermission && !threePaymentsQuota && (
                              <Col
                                xs={12}
                                md={9}
                                className='home_query-unregistered-second'
                              >
                                Si el cliente no esta registrado haga clic en{' '}
                                <span className='home_query-unregistered-bold space-bold'>
                                  {' '}
                                  Estudio cliente
                                </span>
                              </Col>
                            )}
                          </Row>
                        )}
                        <Row className='justify-content-md-center'>
                          {transactionPermision &&
                            transactionPermision.ESTADO !== 'O' &&
                            (userQuotaInfo.ESTADO === 'HABILITADO' ||
                              userQuotaInfo.ESTADO === 'PERMITIR SIN CUPO' ||
                              userQuotaInfo.ALERTA === 'OPCION ACUERDO') && (
                              <Col
                                xs={12}
                                className='button-send-container home_transaction-form-button'
                              >
                                <Row className='justify-content-md-center'>
                                  <Col xs={12} md={4}>
                                    <Button
                                      className='button-send'
                                      variant='success'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.goTransaction();
                                      }}
                                      disabled={
                                        transactionPermision.ESTADO === 'I' ||
                                        !trasactionAvailable
                                      }
                                      block
                                    >
                                      Realizar transacción
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          {userQuotaInfo.ESTADO === 'HABILITADO' && (
                            <Col xs={12} className='button-send-container'>
                              <Row className='justify-content-md-center'>
                                <Col xs={12} md={4}>
                                  <Button
                                    className='button-primary-link'
                                    variant='link'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.goReserve();
                                    }}
                                    disabled={!reserveAvailable}
                                    block
                                  >
                                    Reservar cupo
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          )}
                          {userQuotaInfo.ESTADO !== 'HABILITADO' &&
                            userQuotaInfo.ESTADO !== 'PERMITIR SIN CUPO' &&
                            userQuotaInfo.ALERTA !== 'OPCION ACUERDO' && (
                              <Col
                                xs={10}
                                md={4}
                                className={
                                  userQuotaInfo
                                    ? 'button-send-container home_transaction-form-button'
                                    : 'button-send-container  home_transaction-form-button-second'
                                }
                              >
                                <Button
                                  className='button-send'
                                  variant='success'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.resetForm();
                                  }}
                                  block
                                >
                                  Nueva consulta
                                </Button>
                              </Col>
                            )}
                          {/* {threePaymentsQuota && (
                            <Col
                              xs={10}
                              md={4}
                              className='button-send-container home_transaction-form-button'
                            >
                              <Button
                                className='button-primary'
                                variant='primary'
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.resetForm();
                                }}
                                block
                              >
                                Volver
                              </Button>
                            </Col>
                          )} */}
                          {!userQuotaInfo &&
                            clientStudyPermission &&
                            !threePaymentsQuota && (
                              <Col
                                xs={10}
                                md={4}
                                className='button-send-container home_transaction-form-button'
                              >
                                <Button
                                  className='button-primary'
                                  variant='primary'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.goClientStudy();
                                  }}
                                  block
                                >
                                  Estudio cliente
                                </Button>
                              </Col>
                            )}
                        </Row>
                      </Col>
                    )}
                    {!userQuota && (
                      <Col
                        xs={10}
                        md={5}
                        className='button-send-container home_transaction-form-button'
                      >
                        <Button
                          className='button-send'
                          variant='success'
                          disabled={
                            !loading &&
                            documentNumber !== '' &&
                            documentNumber.toString().length > 5 &&
                            selectedDocument !== ''
                              ? false
                              : true
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            this.openQueryCodeModal();
                          }}
                          block
                        >
                          Realizar transacción
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
              {selectedTab === 1 && (
                <MassiveTransaction
                  provideCtrl={(ctrl) => (this.massiveTransactionCtrl = ctrl)}
                />
              )}
              {selectedTab === 2 && (
                <Reservations
                  provideCtrl={(ctrl) => (this.reservationsCtrl = ctrl)}
                />
              )}
            </Col>

            {/*
              <Col xs={12} className="home-second-card">
              <Row className="justify-content-md-center">
              <Col xs={12} md={5} className="box-card-container">
              <Col xs={12} className="box-card">
              <Row className="height-100">
              <Col xs={12}>
              { !notice && (
              <div
              className="home-option-disabled"
              >
              <img src={reclamationgray} className="action-icon" alt="Reserves List Icon" />
              <div className="home-option-title">
              <div>Aviso de no pago</div>

              <div className="home-option-description">Realice avisos de no pago</div>
              </div>
              <div className="home-option-arrow">
                <img src={arrowGray} className="arrow" alt="flecha" />
              </div>
            </div>
          )}
          { notice && (
            <div
              className="home-option"
              onClick={(e) => {
                e.preventDefault();
                this.goClaim();
              }}
              >
              <img src={claimIcon} className="action-icon" alt="Reserves List Icon" />
              <div className="home-option-title">
                <div>Aviso de no pago</div>

                  <div className="home-option-description">Realice avisos de no pago</div>

                  </div>
                  <div className="home-option-arrow">
                    <img src={arrow} className="arrow" alt="flecha" />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Col>
      <Col xs={12} md={5} className="box-card-container">
        <Col xs={12} className="box-card">
          <Row className="height-100">
            <Col xs={12}>
              { !exoneration && (
                <div
                  className="home-option-disabled"
                  >
                  <img src={exonerationgray} className="action-icon" alt="Reserves List Icon" />
                  <div className="home-option-title">
                    <div>Exoneración</div>
                    <div className="home-option-description">Exonere los cheques o facturas de sus clientes</div>
                  </div>
                  <div className="home-option-arrow">
                    <img src={arrowGray} className="arrow" alt="flecha" />
                  </div>
                </div>
              )}
              { exoneration && (
                <div
                  className="home-option"
                  onClick={(e) => {
                    e.preventDefault();
                    this.goExoneration();
                  }}
                  >
                  <img src={exonerationIcon} className="action-icon" alt="Reserves List Icon" />
                  <div className="home-option-title">
                    <div>Exoneración</div>
                    <div className="home-option-description">Exonere los cheques o facturas de sus clientes</div>
                  </div>
                  <div className="home-option-arrow">
                    <img src={arrow} className="arrow" alt="flecha" />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Col>
      <Col xs={12} md={5} className="box-card-container">
        <Col xs={12} className="box-card">
          <Row className="height-100">
            <Col xs={12}>
              { !clientStudy && (
                <div
                  className="home-option-disabled"
                  >
                  <img src={clientstudygray} className="action-icon" alt="Reserves List Icon" />
                  <div className="home-option-title">
                    <div>Estudio cliente</div>
                    <div className="home-option-description">Ingrese los datos y registre un nuevo comprador</div>
                  </div>
                  <div className="home-option-arrow">
                    <img src={arrowGray} className="arrow" alt="flecha" />
                  </div>
                </div>
              )}
              { clientStudy && (
                <div
                  className="home-option"
                  onClick={(e) => {
                    e.preventDefault();
                    this.goClientStudy();
                  }}
                  >
                  <img src={clientResearchIcon} className="action-icon" alt="Reserves List Icon" />
                  <div className="home-option-title">
                    <div>Estudio cliente</div>
                    <div className="home-option-description">Ingrese los datos y registre un nuevo comprador</div>
                  </div>
                  <div className="home-option-arrow">
                    <img src={arrow} className="arrow" alt="flecha" />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Col>
      <Col xs={12} md={5} className="box-card-container">
        <Col xs={12} className="box-card">
          <Row className="height-100">
            <Col xs={12}>
              { !mypayments && (
                <div
                  className="home-option-disabled"
                  >
                  <img src={mypaymentsgray} className="action-icon" alt="Reserves List Icon" />
                  <div className="home-option-title">
                    <div>Mis pagos</div>
                    <div className="home-option-description">Conozca aquí las pagos que debe gestionar</div>
                  </div>
                  <div className="home-option-arrow">
                    <img src={arrowGray} className="arrow" alt="flecha" />
                  </div>
                </div>
              )}
              { mypayments && (
                <div
                  className="home-option"
                  onClick={(e) => {
                    e.preventDefault();
                    this.goMyPayments();
                  }}
                  >
                  <img src={myPaymentsIcon} className="action-icon" alt="Reserves List Icon" />
                  <div className="home-option-title">
                    <div>Mis pagos</div>
                    <div className="home-option-description">Conozca aquí las pagos que debe gestionar</div>
                  </div>
                  <div className="home-option-arrow">
                    <img src={arrow} className="arrow" alt="flecha" />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Col>
    </Row>
  </Col>
              */}
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { user, globals, permissions } = state.user;
  let { loading, userQuota } = state.transaction;
  let { termsAccepted } = state.document;

  return {
    loading,
    user,
    userQuota,
    globals,
    permissions,
    termsAccepted,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
