import {
  RESET_ALL,
  GET_REFUNDS_REQUEST,
  GET_REFUNDS_SUCCESS,
  GET_REFUNDS_FAILURE,
  EXPORT_REFUNDS_REQUEST,
  EXPORT_REFUNDS_SUCCESS,
  EXPORT_REFUNDS_FAILURE,
  RESET_REFUNDS_REQUEST,
  RESET_EXPORT_REFUNDS_REQUEST,
} from '../action-types'

const initialState = {
  loading: false,
  loadingPaginate: false,
  refunds: [],
  refundsPageCount: 1,
  error: false,
  exportRefunds: false,
}

const refunds = (state = initialState, action) => {
  switch (action.type) {

    case GET_REFUNDS_REQUEST: {
      return {
        ...state,
        loadingPaginate: true
      }
    }

    case GET_REFUNDS_SUCCESS: {
      let refundsTemp = 'REINTEGROS' in action.data ? action.data.REINTEGROS : [];
      let refundsPageCount = refundsTemp.length;
      let refunds = action.page === 1 ? refundsTemp : state.refunds.concat(refundsTemp);
      return {
        ...state,
        refunds,
        refundsPageCount,
        error: false,
        loadingPaginate: false
      }
    }

    case GET_REFUNDS_FAILURE: {
      return {
        ...state,
        error: action.error,
        loadingPaginate: false
      }
    }

    case EXPORT_REFUNDS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case EXPORT_REFUNDS_SUCCESS: {
      return {
        ...state,
        exportRefunds: action.data,
        error: false,
        loading: false
      }
    }

    case RESET_REFUNDS_REQUEST: {
      return {
        ...state,
        refunds: [],
        refundsPageCount: 1
      }
    }

    case RESET_EXPORT_REFUNDS_REQUEST: {
      return {
        ...state,
        exportRefunds: false
      }
    }

    case EXPORT_REFUNDS_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case RESET_ALL: {
      return initialState
    }

    default:
      return state
  }
}

export default refunds
