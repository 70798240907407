const config = {
  // Producción Postgresql ↷
  apiUrl: 'https://wsclifac.c0v.co/api/covifacturapi/servicios',
  hostUrl: 'https://clientes.covifactura.com',
  // Desarrollo Postgresql ↷
  // apiUrl: 'https://desarrollo11.c0v.co/api/covifacturapi/servicios',
  // hostUrl: 'http://desarrollo.covinoc.com:9094',
  //apiUrl: 'http://200.41.76.20:8080/ServiciosCovifacturaLDAP/servicios',
  //apiUrl: 'https://clientes.covifactura.com:18443/ServiciosCovifactura/servicios',
  // apiUrl: 'http://desarrollo02.c0v.co:8080/ServiciosCovifacturaInterno/servicios',
  //apiUrl: 'http://desarrollo02.c0v.co:8080/ServiciosCovifacturaPostgres/servicios',
  //apiUrl: 'https://clientes.covifactura.com:18443/ServiciosCovifacturaInterno/servicios',
  // Servidor Wildfly Desarrollo ↷
  // apiUrl: 'http://192.168.101.26:8080/ServiciosCovifacturaInterno/servicios',
  // Servidor Wildfly Producción ↷
  // apiUrl: 'https://wsclifac.c0v.co/api/ServiciosCovifacturaInterno/servicios',
  //apiUrl: 'https://desarrollo06.c0v.co/api/ServiciosCovifacturaPostgresPD/servicios',
}

export default config
