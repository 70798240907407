import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './liquidator.scss';
import * as actions from '../../redux/actions'
import { Col, Button, Row, Table } from 'react-bootstrap';
import arrowback from '../../assets/icons/arrowback.svg';
import Footer from '../footer';
import Header from '../header';
import Menu from '../menu';
import Chat from '../chat';
import UserHeader from '../user-header';
import aproved from '../../assets/icons/aproved.svg';
import denegate from '../../assets/icons/denegate.svg';
import calendaricon from '../../assets/icons/calendaricon.svg';
import {TextField, InputAdornment, MenuItem} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import Loading from '../loading';
import moment from "moment";
import "moment/locale/es";
import MomentUtils from '@date-io/moment';
import QueryCodeModal from '../home/querycode-modal';
import LiquidatorInfoModal from './liquidator-info-modal';

moment.locale("es");

class Liquidator extends Component {

  constructor() {
    super();
    this.state = {
      menuOpen: false,
      documentType: '',
      dues: '',
      documentNumber: '',
      quotaValue: '',
      coviRate: '',
      netRate: '',
      quotaValueMask: '',
      extendedQuota: '',
      firstInstallmentDate: null,
      queryCodeModal: true,

      // documentType: 'C',
      // dues: '10',
      // documentNumber: '1094933045',
      // quotaValue: '1000000',
      // coviRate: '2.5',
      // netRate: '2',
      // quotaValueMask: '1´000.000',
      // extendedQuota: '1',
      // firstInstallmentDate: moment('20/11/2019', 'DD/MM/YYYY'),
      // queryCodeModal: false,

      liquidatorModal: false,
      queryCode: '',
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  goHome = () => {
    this.props.history.push(`/`);
  }

  componentDidMount() {
    this.props.actions.getUser();
    this.props.actions.getAppGlobals();
    this.props.actions.getDocuments();
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.liquidatorRates && nextProps.liquidatorRates !== this.props.liquidatorRates) {
      this.setState({
        coviRate: parseFloat(nextProps.liquidatorRates.TASA_COVCHQ),
        netRate: parseInt(nextProps.liquidatorRates.TASA_NET)
      })
    }
    if(nextProps.liquidator && nextProps.liquidator !== this.props.liquidator) {
      this.setState({
        liquidatorModal: true
      }, () => {
        this.getLiquidatorDetail(nextProps.liquidator);
      })
    }
  }

  getLiquidatorDetail = (liquidator) => {
    const {
      user,
    } = this.props;
    this.props.actions.getLiquidatorDetail({
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userDocument: user.userInfo.DOCUMENTO,
      liquidator
    });
  }

  getRates = () => {
    const {
      queryCode,
    } = this.state;
    const {
      user,
    } = this.props;
    this.props.actions.getRates({
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userDocument: user.userInfo.DOCUMENTO,
      queryCode: queryCode
    });
  }

  getLiquidator = () => {
    const {
      queryCode,
      documentType,
      dues,
      documentNumber,
      quotaValue,
      coviRate,
      netRate,
      extendedQuota,
      firstInstallmentDate,
    } = this.state;
    const {
      user,
    } = this.props;

    let coviRateFormatted = coviRate.toString().replace(".", ",");
    let netRateFormatted = netRate.toString().replace(".", ",");
    this.props.actions.getLiquidator({
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userDocument: user.userInfo.DOCUMENTO,
      queryCode: queryCode,
      sellerDocumentType: documentType,
      sellerDocumentNumber: documentNumber,
      quotaValue,
      firstInstallmentDate: firstInstallmentDate ? moment(firstInstallmentDate).format('DD/MM/YYYY'): null,
      coviRate: coviRateFormatted,
      netRate: netRateFormatted,
      dues,
      extendedQuota,
      userName: user.userEmail
    });
  }

  closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true
    });
  }

  confirmChangeClose = () => {
    this.setState({
      confirmModal: false
    });
  }

  getDocumentTypeName = (documentType) => {
    const { globals } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let docuemntItem = documents.find((item)=> {
      return item.ID === documentType;
    });
    return docuemntItem ? docuemntItem.LABEL : '';
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  onQuotaValueChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^\d]+/g,'');
    inputValue = inputValue.replace(/\./g,'');
    inputValue = inputValue.replace(/´/g, "");
    let quotaValue = inputValue;
    let thousand = inputValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    this.setState({
      quotaValue,
      quotaValueMask: thousand,
    });
  }

  onInputChange = (event, input) => {
    this.setState({
      [input] : event.target.value
    });
  }

  handleDateChange = (date, input) => {
    this.setState({ [input] : date });
  };

  isvalidForm = () => {
    const {
      documentType,
      dues,
      documentNumber,
      quotaValue,
      quotaValueMask,
      extendedQuota,
      firstInstallmentDate,
    } = this.state;
    let isvalidForm = true;
    if(!(documentType !== '' && dues !== '' && documentNumber !== '' && quotaValue !== '')){
      isvalidForm = false
    }
    if(!(firstInstallmentDate !== null && quotaValueMask !== '' && extendedQuota !== '')){
      isvalidForm = false
    }
    return isvalidForm;
  }

  openQueryCodeModal = () => {
    this.setState({
      queryCodeModal: true
    });
  }

  openQueryCodeModal = () => {
    this.setState({
      liquidatorModal: true
    });
  }

  closeQueryCodeModal = () => {
    this.setState({
      liquidatorModal: false
    });
  }

  setQueryCode = (queryCode) => {
    this.setState({
      queryCodeModal: false,
      queryCode,
    }, () => {
      this.getRates();
    })
  }

  getTotalValue = (key, arrayValue) => {
    let total = 0.0;
    for (let index in arrayValue) {
      if(key in arrayValue[index]) {
        total+=parseFloat(arrayValue[index][key])
      }
    }
    return total.toFixed(0);
  }

  render() {
    const {
      menuOpen,
      documentType,
      documentNumber,
      quotaValueMask,
      firstInstallmentDate,
      coviRate,
      netRate,
      dues,
      extendedQuota,
      queryCodeModal,
      liquidatorModal,
    } = this.state;
    const {
      loading,
      globals,
      liquidatorLoading,
      liquidator,
      loadingDetail,
      liquidatorDetail,
    } = this.props;

    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let duesRange = [...Array(12).keys()];
    return (
      <div className="liquidator">
      <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={20} />
        <Header openMenu={this.openMenu} />
          <QueryCodeModal
            show={queryCodeModal}
            confirm={this.setQueryCode}
            onHide={this.goHome}
            clientStudy
          />
          <LiquidatorInfoModal
            show={liquidatorModal}
            onHide={this.closeQueryCodeModal}
            liquidatorNumber={liquidator ? liquidator : ''}
          />
        <div className="page-body-container center-horizontally">
          <div className="page-body">
            <UserHeader />
            { true && (
              <Col xs={12} className="general-main-card">
                { (loading || liquidatorLoading || loadingDetail) && (
                  <Loading />
                )}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                    >
                    <span>
                      <img src={arrowback} alt="arrow back" className="goback-icon" />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                <Col xs={12} className="liquidator-margin">
                    <Col xs={12} className="card-title">
                      Liquidador
                    </Col>
                    <Col xs={12} >
                      <hr />
                    </Col>
                    <Col xs={12} >
                      <Row>
                        <Col xs={12} md={6} className="login-input-container">
                          <TextField
                            id="documentType"
                            select
                            label="Tipo de documento"
                            className="home-input"
                            margin="dense"
                            value={documentType}
                            onChange={(event) => {
                              this.onInputChange(event, 'documentType');
                            }}
                            fullWidth
                            >
                            {documents.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.ID}>
                                  {item.LABEL}
                                </MenuItem>
                              )
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className="login-input-container exoneration-margin">
                          <TextField
                            id="documentNumber"
                            label="Número de documento"
                            className="home-input"
                            margin="dense"
                            type="number"
                            value={documentNumber}
                            onChange={(event) => {
                              this.onInputChange(event, 'documentNumber');
                            }}
                            fullWidth
                            />
                        </Col>
                        <Col xs={12} md={6} className="login-input-container">
                          <TextField
                            id="quota"
                            label="Valor a financiar"
                            className="home-input"
                            margin="dense"
                            value={quotaValueMask}
                            onChange={this.onQuotaValueChange}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            fullWidth
                            />
                        </Col>
                        <Col xs={12} md={6} className="login-input-container date-picker-container">
                          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <DatePicker
                              margin="normal"
                              label="Fecha primera cuota"
                              className="home-input"
                              value={firstInstallmentDate}
                              locale='es-ES'
                              onChange={(date) => {
                                this.handleDateChange(date, 'firstInstallmentDate')
                              }}
                              fullWidth
                              format="DD/MM/YYYY"
                              InputProps={{
                                endAdornment: (
                                  <img src={calendaricon} alt="icono calendario" className="calendar-icon"/>
                                ),
                              }}
                              />
                          </MuiPickersUtilsProvider>
                        </Col>
                        <Col xs={12} md={6} className="login-input-container">
                          <TextField
                            id="coviRate"
                            label="Tasa Covicheque"
                            disabled
                            className="home-input"
                            type="number"
                            margin="dense"
                            value={coviRate}
                            onChange={(event) => {
                              this.onInputChange(event, 'coviRate');
                            }}
                            fullWidth
                            />
                        </Col>
                        <Col xs={12} md={6} className="login-input-container">
                          <TextField
                            id="netRate"
                            label="Tasa Net"
                            disabled
                            className="home-input"
                            type="number"
                            margin="dense"
                            value={netRate}
                            onChange={(event) => {
                              this.onInputChange(event, 'netRate');
                            }}
                            fullWidth
                            />
                        </Col>
                        <Col xs={12} md={6} className="login-input-container">
                          <TextField
                            id="dues"
                            select
                            label="Cuotas"
                            className="home-input"
                            margin="dense"
                            value={dues}
                            onChange={(event) => {
                              this.onInputChange(event, 'dues');
                            }}
                            fullWidth
                            >
                            {duesRange.map((item, index) => {
                              return (
                                <MenuItem key={index + 1} value={index + 1}>
                                  {index + 1}
                                </MenuItem>
                              )
                            })}
                          </TextField>
                        </Col>
                        <Col xs={12} md={6} className="login-input-container">
                          <TextField
                            id="extendedQuota"
                            select
                            label="Cupo extendido"
                            className="home-input"
                            margin="dense"
                            value={extendedQuota}
                            onChange={(event) => {
                              this.onInputChange(event, 'extendedQuota');
                            }}
                            fullWidth
                            >
                              <MenuItem  value={1}>
                                SI
                              </MenuItem>
                              <MenuItem  value={0}>
                                NO
                              </MenuItem>
                          </TextField>
                        </Col>
                        <Col xs={12} md={{span: 4, offset: 4}} className="generate-button">
                          <Button
                            className="button-send"
                            variant="success"
                            disabled={!this.isvalidForm()}
                            onClick={(e) => {
                                e.preventDefault();
                                this.getLiquidator();
                            }}
                            block
                          >
                            Generar
                          </Button>
                        </Col>
                        {liquidatorDetail && (
                          <Col xs={12}>
                            <Row>
                              <Col xs={12} className="liquidator-table-title">
                                Liquidación Nº: <span>{liquidator}</span>
                              </Col>
                              <Col xs={12} className="results-table-container">
                                <Table bordered responsive="sm">
                                  <thead>
                                    <tr>
                                      <th>Cuota</th>
                                      <th>Fecha</th>
                                      <th>Valor</th>
                                      <th>Capital</th>
                                      <th>Intereses</th>
                                      <th>Saldo</th>
                                      <th>Valor a pagar</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {liquidatorDetail.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>
                                            {item.CUOTA}/{liquidatorDetail.length}
                                          </td>
                                          <td>
                                            {item.FECHA === '' ? '' : moment(item.FECHA).format('DD/MM/YYYY')}
                                          </td>
                                          <td>
                                            ${this.getNumber(item.VALOR)}
                                          </td>
                                          <td>
                                            ${this.getNumber(item.CAPITAL)}
                                          </td>
                                          <td>
                                            ${this.getNumber(item.INTERES)}
                                          </td>
                                          <td>
                                            ${this.getNumber(item.SALDO)}
                                          </td>
                                          <td>
                                            ${this.getNumber(item.VALOR)}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                    <tr className="total-tr">
                                      <td className="total-tr-first">
                                        Total
                                      </td>
                                      <td>

                                      </td>
                                      <td>
                                        ${this.getNumber(this.getTotalValue('VALOR', liquidatorDetail))}
                                      </td>
                                      <td>
                                        ${this.getNumber(this.getTotalValue('CAPITAL', liquidatorDetail))}
                                      </td>
                                      <td>
                                        ${this.getNumber(this.getTotalValue('INTERES', liquidatorDetail))}
                                      </td>
                                      <td>
                                      </td>
                                      <td className="total-tr-last">
                                        ${this.getNumber(this.getTotalValue('VALOR', liquidatorDetail))}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          </Col>
                        )}
                      </Row>
                    </Col>


                </Col>
              </Col>
            )}
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
      user,
      globals,
      loading
  } = state.user;

  let {
      liquidatorRates,
      loading: liquidatorLoading,
      liquidator,
      loadingDetail,
      liquidatorDetail,
  } = state.liquidator;

  return {
      loading,
      user,
      globals,
      liquidatorRates,
      liquidatorLoading,
      liquidator,
      loadingDetail,
      liquidatorDetail,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Liquidator);
