import {
  RESET_ALL,
  GET_GUARANTEESTOEXONERATE_REQUEST,
  GET_GUARANTEESTOEXONERATE_SUCCESS,
  GET_GUARANTEESTOEXONERATE_FAILURE,
  EXONERATEGUARANTE_REQUEST,
  EXONERATEGUARANTE_SUCCESS,
  EXONERATEGUARANTE_FAILURE,
  SEND_BULKEXONERATION_REQUEST,
  SEND_BULKEXONERATION_SUCCESS,
  SEND_BULKEXONERATIONFINAL_SUCCESS,
  SEND_BULKEXONERATION_FAILURE,
  RESET_EXPORT_BULKEXONERATION_REQUEST,
  EXPORT_BULKEXONERATION_REQUEST,
  EXPORT_BULKEXONERATION_SUCCESS,
  EXPORT_BULKEXONERATION_FAILURE,
  RESET_GUARANTEESTOEXONERATE,
} from '../action-types'

const initialState = {
  loading: false,
  loadingPaginate: false,
  exoneration: false,
  exonerations: [],
  exonerationsPageCount: 1,
  exonerationError: false,
  exonerationNotRegistered: false,
  error: false,
  bulkExonerationError: false,
  bulkExonerationResponse: false,
  bulkExonerationFinalResponse: false,
  exportExonerations: false,
}

const exonerations = (state = initialState, action) => {
  switch (action.type) {

    case GET_GUARANTEESTOEXONERATE_REQUEST: {
      return {
        ...state,
        loadingPaginate: true
      }
    }

    case GET_GUARANTEESTOEXONERATE_SUCCESS: {
      let exonerationsTemp = 'GARANTIAS_A_EXONERAR' in action.data ? action.data.GARANTIAS_A_EXONERAR : [];
      let exonerationsPageCount = exonerationsTemp.length;
      let exonerations = action.page === 1 ? exonerationsTemp : state.exonerations.concat(exonerationsTemp);
      let exonerationsNotRegistered = action.page === 1 ? !('GARANTIAS_A_EXONERAR' in action.data) : false;

      return {
        ...state,
        exonerations,
        exonerationsNotRegistered,
        exonerationsPageCount,
        exonerationError: false,
        loadingPaginate: false
      }
    }

    case GET_GUARANTEESTOEXONERATE_FAILURE: {
      return {
        ...state,
        exonerationError: action.error,
        loadingPaginate: false
      }
    }

    case EXONERATEGUARANTE_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case EXONERATEGUARANTE_SUCCESS: {
      let exoneration = action.data ;
      return {
        ...state,
        exoneration,
        error: false,
        loading: false
      }
    }

    case EXONERATEGUARANTE_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case SEND_BULKEXONERATION_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case SEND_BULKEXONERATION_SUCCESS: {
      let bulkExonerationResponse = "CARGA" in action.data ? action.data.CARGA : {REGISTROS_CORRECTOS: [], REGISTROS_ERROR: []}
      return {
        ...state,
        bulkExonerationResponse,
        bulkExonerationError: false,
        loading: false
      }
    }

    case SEND_BULKEXONERATIONFINAL_SUCCESS: {
      let bulkExonerationFinalResponse = "CARGA" in action.data ? action.data.CARGA : []
      return {
        ...state,
        bulkExonerationFinalResponse,
        bulkExonerationError: false,
        loading: false
      }
    }

    case SEND_BULKEXONERATION_FAILURE: {
      return {
        ...state,
        bulkExonerationError: action.error,
        loading: false
      }
    }

    case EXPORT_BULKEXONERATION_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case EXPORT_BULKEXONERATION_SUCCESS: {
      return {
        ...state,
        exportExonerations: action.data,
        error: false,
        loading: false
      }
    }

    case RESET_EXPORT_BULKEXONERATION_REQUEST: {
      return {
        ...state,
        exportExonerations: false
      }
    }

    case EXPORT_BULKEXONERATION_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case RESET_GUARANTEESTOEXONERATE: {
      return {
        ...state,
        loading: false,
        exoneration: false,
        exonerations: [],
        exonerationsPageCount: 1,
        exonerationError: false,
        exonerationNotRegistered: false,
        error: false,
        bulkExonerationError: false,
        bulkExonerationResponse: false,
        bulkExonerationFinalResponse: false,
        exportExonerations: false,
      }
    }

    case RESET_ALL: {
      return initialState
    }

    default:
      return state
  }
}

export default exonerations
