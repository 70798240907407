import {
  RESET_ALL,
  GET_QUOTA_REQUEST,
  GET_QUOTA_SUCCESS,
  GET_QUOTA_FAILURE,
  RESET_QUOTA,
  SEND_TRASACTION_REQUEST,
  SEND_TRASACTION_SUCCESS,
  SEND_TRASACTION_FAILURE,
  GET_TRASACTIONREPORT_REQUEST,
  GET_TRASACTIONREPORT_SUCCESS,
  GET_TRASACTIONREPORT_FAILURE,
  GET_SELLERSREPORT_REQUEST,
  GET_SELLERSREPORT_SUCCESS,
  GET_SELLERSREPORT_FAILURE,
  GET_SELLERSNUMBER_SUCCESS,
  GET_SELLERSNUMBER_REQUEST,
  GET_SELLERSNUMBER_FAILURE,
  EXPORT_SELLERSREPORT_REQUEST,
  EXPORT_SELLERSREPORT_SUCCESS,
  EXPORT_SELLERSREPORT_FAILURE,
  EXPORT_BULKTRASACTION_REQUEST,
  EXPORT_BULKTRASACTION_SUCCESS,
  EXPORT_BULKTRASACTION_FAILURE,
  EXPORT_TRASACTIONREPORT_REQUEST,
  EXPORT_TRASACTIONREPORT_SUCCESS,
  EXPORT_TRASACTIONREPORT_FAILURE,
  EXPORT_QUOTAREPORT_REQUEST,
  EXPORT_QUOTAREPORT_SUCCESS,
  EXPORT_QUOTAREPORT_FAILURE,
  RESET_EXPORT_TRASACTIONREPORT_REQUEST,
  SEND_BULKEMAILTRASACTION_REQUEST,
  SEND_BULKEMAILTRASACTION_SUCCESS,
  SEND_BULKEMAILTRASACTION_FAILURE,
  SEND_BULKTRASACTION_REQUEST,
  SEND_BULKTRASACTION_SUCCESS,
  SEND_BULKTRASACTIONFINAL_SUCCESS,
  SEND_BULKTRASACTION_FAILURE,
  UPDATE_STUDENT_REQUEST,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_FAILURE,
  VALIDATE_QUERYCODE_REQUEST,
  VALIDATE_QUERYCODE_SUCCESS,
  VALIDATE_QUERYCODE_FAILURE,
} from '../action-types'

const initialState = {
  loading: false,
  userQuota: false,
  transactionResponse: false,
  transactionResponseError: false,
  transactionError: false,
  error: false,
  bulkEmailTransactionResponse: false,
  bulkEmailTransactionError: false,
  bulkTransactionError: false,
  bulkTransactionResponse: false,
  bulkTransactionFinalResponse: false,
  transactionsNumber: [],
  loadingSeller: false,
  sellers: [],
  sellersPageCount: 1,
  transactions: [],
  transactionsPageCount: 1,
  exportTransactions: false,
  exportSellers: false,
  quotaReport: false,
  validQueryCode: false,
}

const transactions = (state = initialState, action) => {
  switch (action.type) {

    case GET_SELLERSREPORT_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_SELLERSREPORT_SUCCESS: {
      let sellersTemp = 'VENDEDORES' in action.data ? action.data.VENDEDORES : [];
      let sellersPageCount = sellersTemp.length;
      let sellers = action.page === 1 ? sellersTemp : state.sellers.concat(sellersTemp);
      return {
        ...state,
        sellers,
        sellersPageCount,
        error: false,
        loading: false
      }
    }

    case GET_SELLERSREPORT_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case GET_SELLERSNUMBER_REQUEST: {
      return {
        ...state,
        loadingSeller: true
      }
    }

    case GET_SELLERSNUMBER_SUCCESS: {
      let transactionsNumber = 'VENDEDORES' in action.data ? action.data.VENDEDORES : [];
      return {
        ...state,
        transactionsNumber,
        loadingSeller: false
      }
    }

    case GET_SELLERSNUMBER_FAILURE: {
      return {
        ...state,
        error: action.error,
        loadingSeller: false
      }
    }

    case GET_TRASACTIONREPORT_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_TRASACTIONREPORT_SUCCESS: {
      let transactionsTemp = 'REPORTE_TRANSACCIONES' in action.data ? action.data.REPORTE_TRANSACCIONES : [];
      let transactionsPageCount = transactionsTemp.length;
      let transactions = action.page === 1 ? transactionsTemp : state.transactions.concat(transactionsTemp);
      return {
        ...state,
        transactions,
        transactionsPageCount,
        error: false,
        loading: false
      }
    }

    case GET_TRASACTIONREPORT_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case GET_QUOTA_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_QUOTA_SUCCESS: {
      let userData = action.data
      return {
        ...state,
        userQuota: userData,
        error: false,
        loading: false
      }
    }

    case GET_QUOTA_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case SEND_TRASACTION_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case SEND_TRASACTION_SUCCESS: {
      let transactionResponse = action.data
      return {
        ...state,
        transactionResponse,
        transactionError: false,
        loading: false
      }
    }

    case SEND_TRASACTION_FAILURE: {
      return {
        ...state,
        transactionResponseError: action.error,
        loading: false
      }
    }

    case SEND_BULKTRASACTION_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case SEND_BULKTRASACTION_SUCCESS: {
      let bulkTransactionResponse = "CARGA" in action.data ? action.data.CARGA : {REGISTROS_CORRECTOS: [], REGISTROS_ERROR: []}
      return {
        ...state,
        bulkTransactionResponse,
        bulkTransactionError: false,
        loading: false
      }
    }

    case SEND_BULKTRASACTIONFINAL_SUCCESS: {
      let bulkTransactionFinalResponse = "CARGA" in action.data ? action.data.CARGA : []
      return {
        ...state,
        bulkTransactionFinalResponse,
        bulkTransactionError: false,
        loading: false
      }
    }

    case SEND_BULKTRASACTION_FAILURE: {
      return {
        ...state,
        bulkTransactionError: action.error,
        loading: false
      }
    }

    case SEND_BULKEMAILTRASACTION_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case SEND_BULKEMAILTRASACTION_SUCCESS: {
      let bulkEmailTransactionResponse = action.data
      return {
        ...state,
        bulkEmailTransactionResponse,
        bulkEmailTransactionError: false,
        loading: false
      }
    }

    case SEND_BULKEMAILTRASACTION_FAILURE: {
      return {
        ...state,
        bulkEmailTransactionError: action.error,
        loading: false
      }
    }

    case UPDATE_STUDENT_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case UPDATE_STUDENT_SUCCESS: {
      return {
        ...state,
        error: false,
        loading: false
      }
    }

    case UPDATE_STUDENT_FAILURE: {
      return {
        ...state,
        transactionError: action.error,
        loading: false
      }
    }

    case VALIDATE_QUERYCODE_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case VALIDATE_QUERYCODE_SUCCESS: {
      return {
        ...state,
        validQueryCode: action.data,
        error: false,
        loading: false
      }
    }

    case VALIDATE_QUERYCODE_FAILURE: {
      return {
        ...state,
        transactionError: action.error,
        loading: false
      }
    }

    case RESET_QUOTA: {
      return {
        ...state,
        loading: false,
        userQuota: false,
        transactionResponse: false,
        transactionResponseError: false,
        transactionError: false,
        error: false,
        bulkEmailTransactionError: false,
        bulkEmailTransactionResponse: false,
        bulkTransactionError: false,
        bulkTransactionResponse: false,
        bulkTransactionFinalResponse: false,
        transactions: [],
        transactionsPageCount: 1,
        exportSellers: false,
        exportTransactions: false,
      }
    }

    case EXPORT_TRASACTIONREPORT_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case EXPORT_TRASACTIONREPORT_SUCCESS: {
      return {
        ...state,
        exportTransactions: action.data,
        error: false,
        loading: false
      }
    }

    case RESET_EXPORT_TRASACTIONREPORT_REQUEST: {
      return {
        ...state,
        exportTransactions: false,
        exportSellers: false,
        quotaReport: false,
      }
    }

    case EXPORT_TRASACTIONREPORT_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case EXPORT_SELLERSREPORT_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case EXPORT_SELLERSREPORT_SUCCESS: {
      return {
        ...state,
        exportSellers: action.data,
        error: false,
        loading: false
      }
    }

    case EXPORT_SELLERSREPORT_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case EXPORT_QUOTAREPORT_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case EXPORT_QUOTAREPORT_SUCCESS: {
      return {
        ...state,
        quotaReport: action.data,
        error: false,
        loading: false
      }
    }

    case EXPORT_QUOTAREPORT_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case EXPORT_BULKTRASACTION_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case EXPORT_BULKTRASACTION_SUCCESS: {
      return {
        ...state,
        exportTransactions: action.data,
        error: false,
        loading: false
      }
    }

    case EXPORT_BULKTRASACTION_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case RESET_ALL: {
      return initialState
    }

    default:
      return state
  }
}

export default transactions
