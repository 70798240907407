import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import { Col, Button, Modal, Row } from 'react-bootstrap';

class StudySucces extends Component {
  confirm = () => {
    this.props.onHide();
  };

  render() {
    const {
      show,
      onHide,
      quotaValue,
      name,
      documentNumber,
      code,
      quotaValueMask,
      title,
      showInfo,
      approvedQuota,
      threePayments,
      date1,
      date2,
      date3,
      consecutive,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <div className='query-code-title modal-study-title'>{title}</div>
        </Modal.Header>
        <Modal.Body>
          <div className='terms-modal-body'>
            {showInfo && (
              <Col xs={12} className='study-modal-succes_title'>
                En un periodo máximo de{' '}
                {`${
                  quotaValue
                    ? parseInt(quotaValue) <= 20000000
                      ? '2'
                      : '24'
                    : '2'
                }`}{' '}
                horas tendrá su respuesta.*
              </Col>
            )}
            {showInfo && (
              <Col xs={12} className='study-modal-succes_description'>
                *Sujeto a horario laboral de lunes a viernes de 08:00 a.m. a
                05:00 p.m. <br /> y sábado de 08:00 a 12:00 p.m.
              </Col>
            )}
            <Col
              xs={12}
              md={{ span: 8, offset: 2 }}
              className='study-modal-succes_info-container'
            >
              <Row>
                <Col xs={6} className='study-modal-succes_info-key'>
                  Nombre:
                </Col>
                <Col xs={6} className='study-modal-succes_info-value'>
                  {name}
                </Col>
                {!threePayments && (
                  <>
                    <Col xs={6} className='study-modal-succes_info-key'>
                      N° de documento:
                    </Col>
                    <Col xs={6} className='study-modal-succes_info-value'>
                      {documentNumber}
                    </Col>
                  </>
                )}
                {threePayments && date1 && date2 && date3 && consecutive && (
                  <>
                    <Col xs={6} className='study-modal-succes_info-key'>
                      Consecutivo 3 pagos:
                    </Col>
                    <Col xs={6} className='study-modal-succes_info-value'>
                      {consecutive}
                    </Col>
                    <Col xs={6} className='study-modal-succes_info-key'>
                      Fecha vencimiento 1:
                    </Col>
                    <Col xs={6} className='study-modal-succes_info-value'>
                      {date1}
                    </Col>
                    <Col xs={6} className='study-modal-succes_info-key'>
                      Fecha vencimiento 2:
                    </Col>
                    <Col xs={6} className='study-modal-succes_info-value'>
                      {date2}
                    </Col>
                    <Col xs={6} className='study-modal-succes_info-key'>
                      Fecha vencimiento 3:
                    </Col>
                    <Col xs={6} className='study-modal-succes_info-value'>
                      {date3}
                    </Col>
                  </>
                )}
                <Col xs={6} className='study-modal-succes_info-key'>
                  {threePayments ? 'Valor Factura:' : 'Cupo solicitado:'}
                </Col>
                <Col xs={6} className='study-modal-succes_info-value'>
                  ${quotaValueMask}
                </Col>
                {approvedQuota !== '' && !threePayments && (
                  <Col xs={6} className='study-modal-succes_info-key'>
                    Cupo asignado:
                  </Col>
                )}
                {approvedQuota !== '' && !threePayments && (
                  <Col xs={6} className='study-modal-succes_info-value'>
                    ${approvedQuota}
                  </Col>
                )}
                {!threePayments && (
                  <>
                    <Col xs={6} className='study-modal-succes_info-key'>
                      Fecha:
                    </Col>
                    <Col xs={6} className='study-modal-succes_info-value'>
                      {moment().format('DD/MM/YYYY')}
                    </Col>
                  </>
                )}
                <Col xs={6} className='study-modal-succes_info-key'>
                  Código de confirmación:
                </Col>
                <Col xs={6} className='study-modal-succes_info-value'>
                  {code}
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={{ span: 4, offset: 4 }}
              className='query-code-buttons'
            >
              <Button
                className='button-send'
                variant='success'
                onClick={(e) => {
                  e.preventDefault();
                  this.confirm();
                }}
                block
              >
                Aceptar
              </Button>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

StudySucces.propTypes = {
  quotaValue: PropTypes.number,
  quotaValueMask: PropTypes.string,
  approvedQuota: PropTypes.string,
  name: PropTypes.string,
  documentNumber: PropTypes.string,
  code: PropTypes.string,
  title: PropTypes.string,
  showInfo: PropTypes.bool,
};

StudySucces.defaultProps = {
  quotaValue: 0,
  quotaValueMask: '',
  name: '',
  documentNumber: '',
  code: '',
  approvedQuota: '',
  title: '',
  showInfo: false,
};

export default StudySucces;
