import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './reports.scss';
import * as actions from '../../redux/actions'
import { Col, Button, Row, Table } from 'react-bootstrap';
import Footer from '../footer';
import Header from '../header';
import Menu from '../menu';
import Chat from '../chat';
import UserHeader from '../user-header';
import arrowback from '../../assets/icons/arrowback.svg';
import denegate from '../../assets/icons/denegate.svg';
import searchicon from '../../assets/icons/searchicon.svg';
import userLogo from '../../assets/logos/user-logo.svg';
import calendaricon from '../../assets/icons/calendaricon.svg';
import { TextField, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment from "moment";
import "moment/locale/es";
import MomentUtils from '@date-io/moment';
// import CircleChart from './circle-chart'
import Chart from 'react-apexcharts';
import Loading from '../loading';
import ReduxLazyScroll from 'redux-lazy-scroll';
import FileSaver from 'file-saver';
import ListLoader from '../list-loader';

moment.locale("es");

const searchSelect = [
  { id: "document", value: "Documento" },
  { id: "invoice", value: "Factura" }
]

const colors = [
  '#44C4E2',
  '#1d69ae',
  '#1d69ae',
];

class Reports extends Component {

  constructor() {
    super();
    this.state = {
      menuOpen: false,
      transactions: true,
      transactionsError: false,
      UsersError: false,
      fromDate: moment().subtract(1, 'months').toDate(),
      toDate: moment(new Date()).toDate(),
      invoiceNumber: '',
      documentType: '',
      documentNumber: '',
      sellerDocumentType: '',
      sellerDocumentNumber: '',
      productNumber: '',
      transactionSearchSelect: 'document',
    };
    this.page = 0;
    this.pageSellers = 0;
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  goHome = () => {
    this.props.actions.resetQuota();
    this.props.history.push(`/`);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.actions.getUser();
    this.props.actions.getAppGlobals();
    this.props.actions.getDocuments();
  }

  getMyStatistics = (user) => {
    this.props.actions.getMyStatistics({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
    });
  }

  getTransactionReport = () => {
    const {
      fromDate,
      toDate,
      sellerDocumentType,
      sellerDocumentNumber,
      productNumber
    } = this.state;
    const {
      user
    } = this.props;
    this.page = this.page + 1;
    this.props.actions.getTransactionReport({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      sellerDocumentType: sellerDocumentType ? sellerDocumentType : null,
      sellerDocumentNumber: sellerDocumentNumber ? sellerDocumentNumber : null,
      initialDate: fromDate ? moment(fromDate).format('DD/MM/YYYY') : moment().subtract(1, 'months').format('DD/MM/YYYY'),
      finalDate: toDate ? moment(toDate).format('DD/MM/YYYY') : moment(new Date()).format('DD/MM/YYYY'),
      page: this.page,
      productNumber: productNumber ? productNumber : null,
    });
  }

  getSellersReport = () => {
    const {
      documentType,
      documentNumber,
    } = this.state;
    const {
      user
    } = this.props;
    this.pageSellers = this.pageSellers + 1;
    this.props.actions.getSellersReport({
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userDocument: user.userInfo.DOCUMENTO,
      documentNumber: documentNumber ? documentNumber : 0,
      documentType: documentType ? documentType : 'C',
      page: this.pageSellers
    });
  }

  exportSellersReport = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    // window.gtag('event', 'descargar_informe_vendedores', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    const {
      documentType,
      documentNumber,
    } = this.state;
    const {
      user
    } = this.props;
    this.pageSellers = this.pageSellers + 1;
    this.props.actions.exportSellersReport({
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userDocument: user.userInfo.DOCUMENTO,
      documentNumber: documentNumber ? documentNumber : 0,
      documentType: documentType ? documentType : 'C'
    });
  }

  exportQuotaReport = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    // window.gtag('event', 'descargar_reporte_cupos', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    const {
      user
    } = this.props;
    this.props.actions.exportQuotaReport({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
    });
  }

  exportTransactionReport = () => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    // window.gtag('event', 'descargar_informe_transacciones', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    const {
      fromDate,
      toDate,
      sellerDocumentType,
      sellerDocumentNumber,
      productNumber
    } = this.state;
    const {
      user
    } = this.props;
    this.props.actions.exportTransactionReport({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      sellerDocumentType: sellerDocumentType ? sellerDocumentType : null,
      sellerDocumentNumber: sellerDocumentNumber ? sellerDocumentNumber : null,
      initialDate: fromDate ? moment(fromDate).format('DD/MM/YYYY') : moment().subtract(1, 'months').format('DD/MM/YYYY'),
      finalDate: toDate ? moment(toDate).format('DD/MM/YYYY') : moment(new Date()).format('DD/MM/YYYY'),
      productNumber: productNumber ? productNumber : null,
    });
  }

  saveTransactionFile(excelBlob) {
    FileSaver.saveAs(excelBlob, `reporteTransacciones.xlsx`);
  }

  saveQuotaReport(excelBlob) {
    FileSaver.saveAs(excelBlob, `reporteDecupos.xlsx`);
  }

  saveSellerFile(excelBlob) {
    FileSaver.saveAs(excelBlob, `reporteVendedores.xlsx`);
  }

  getRolPermissions(user) {
    this.props.actions.getRolPermissions({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      idRol: user.userInfo.ROL_USUARIO,
      reload: false
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.getMyStatistics(nextProps.user);
      this.getRolPermissions(nextProps.user);
    }
    if (nextProps.exportTransactions) {
      this.props.actions.resetExportTransactionReport()
      this.saveTransactionFile(nextProps.exportTransactions)
    }
    if (nextProps.exportSellers && nextProps.exportSellers !== this.props.exportSellers) {
      this.props.actions.resetExportTransactionReport()
      this.saveSellerFile(nextProps.exportSellers)
    }
    if (nextProps.quotaReport && nextProps.quotaReport !== this.props.QuotaReport) {
      this.props.actions.resetExportTransactionReport()
      this.saveQuotaReport(nextProps.quotaReport)
    }
  }

  closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true
    });
  }

  changeTab = (tab) => {
    this.setState({
      transactions: tab
    });
  }

  handleDateFromChange = date => {
    this.setState({ fromDate: date });
  };

  handleDateToChange = date => {
    this.setState({ toDate: date });
  };

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if (thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  getPercentaje = (first, second) => {
    let newFirst = parseInt(first);
    let newSecond = parseInt(second);
    let diference = newFirst - newSecond;
    let prepercentaje = diference / second;
    let percentaje = prepercentaje * 100;
    return percentaje;
  }

  oninputChange = (event, input) => {
    this.setState({
      [input]: event.target.value
    });
  }

  scrollToMyRef = () => {
    let myDiv = document.getElementsByClassName("redux-lazy-scroll");
    myDiv[0].scrollTop = 0;
  }

  renderNoticeSearchSelect = () => {
    const {
      transactionSearchSelect,
      sellerDocumentType,
      sellerDocumentNumber,
      productNumber
    } = this.state;
    const {
      globals
    } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let searchForm = ''
    switch (transactionSearchSelect) {
      case 'invoice':
        searchForm = (
          <Col xs={12} md={9}>
            <Row className="search-date-container">
              <Col>
                <TextField
                  id="productNumber"
                  label="Buscar número de factura"
                  className="home-input"
                  margin="dense"
                  value={productNumber}
                  onChange={(event) => {
                    this.oninputChange(event, 'productNumber');
                  }}
                  InputProps={{
                    endAdornment: (
                      <img src={searchicon} alt="icono calendario" className="calendar-icon" />
                    ),
                  }}
                  fullWidth
                />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="button-send"
                  variant="success"
                  disabled={productNumber === ''}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      sellerDocumentType: '',
                      sellerDocumentNumber: '',
                    }, () => {
                      this.props.actions.resetQuota();
                      this.scrollToMyRef();
                      this.page = 0;
                      this.getTransactionReport();
                    })
                  }}
                  block
                >
                  Buscar
              </Button>
              </Col>
            </Row>
          </Col>
        )
        break;
      case 'document':
        searchForm = (
          <Col xs={12} md={9}>
            <Row>
              <Col xs={12} md={4} className="login-input-container">
                <TextField
                  id="sellerDocumentType"
                  select
                  label="Tipo de documento"
                  className="home-input"
                  margin="dense"
                  value={sellerDocumentType}
                  onChange={(event) => {
                    this.oninputChange(event, 'sellerDocumentType');
                  }}
                  fullWidth
                >
                  {documents.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.ID}>
                        {item.LABEL}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Col>
              <Col xs={12} md={4} className="login-input-container">
                <TextField
                  id="sellerDocumentNumber"
                  label="Número de documento"
                  className="home-input"
                  margin="dense"
                  type="number"
                  value={sellerDocumentNumber}
                  onChange={(event) => {
                    this.oninputChange(event, 'sellerDocumentNumber');
                  }}
                  fullWidth
                />
              </Col>
              <Col xs={12} md={4}>
                <Button
                  className="button-send"
                  variant="success"
                  // disabled={sellerDocumentType === '' || sellerDocumentNumber === ''}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      productNumber: '',
                    }, () => {
                      this.props.actions.resetQuota();
                      this.scrollToMyRef();
                      this.page = 0;
                      this.getTransactionReport();
                    })
                  }}
                  block
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Col>
        )
        break;
      default:

    }
    return searchForm;
  }

  render() {
    const {
      menuOpen,
      transactions,
      fromDate,
      toDate,
      documentNumber,
      sellerDocumentType,
      sellerDocumentNumber,
      transactionSearchSelect
    } = this.state;
    const {
      loading,
      myStatistics,
      user,
      loadingTransaction,
      transactionsPageCount,
      transactionsArray,
      sellers,
      sellersPageCount,
      permissions,
      globals
    } = this.props;
    let permissionsAray = permissions && 'MENU' in permissions ? permissions.MENU : [];
    let permissionReport = user && user.userInfo && user.userInfo.REPORTE_CUPOS === 'SI';
    let downloadReport = permissionsAray.find(permission => parseInt(permission.IDOPCION) === 17);
    let sellerReport = permissionsAray.find(permission => parseInt(permission.IDOPCION) === 18);
    let downloadSellerReport = permissionsAray.find(permission => parseInt(permission.IDOPCION) === 19);
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];

    let myStatisticsObject = myStatistics && 'CONSOLIDADO_CUPOS' in myStatistics && myStatistics.CONSOLIDADO_CUPOS.length > 0 ? myStatistics.CONSOLIDADO_CUPOS[0] : {}

    let quotaAssigned = myStatisticsObject.CANTIDAD_CUPOS_ASIGNADOS ? parseInt(myStatisticsObject.CANTIDAD_CUPOS_ASIGNADOS) : 0
    let quotaUsed = myStatisticsObject.CANTIDAD_CUPOS_UTILIZADOS ? parseInt(myStatisticsObject.CANTIDAD_CUPOS_UTILIZADOS) : 0
    let quotaAvailable = myStatisticsObject.CANTIDAD_CUPOS_SIN_UTILIZAR ? parseInt(myStatisticsObject.CANTIDAD_CUPOS_SIN_UTILIZAR) : 0

    let quotaValueAvailable = myStatisticsObject.VALOR_CUPOS_SIN_UTILIZAR ? parseInt(myStatisticsObject.VALOR_CUPOS_SIN_UTILIZAR) : 0
    let quotaValueAssigned = myStatisticsObject.VALOR_CUPOS_ASIGNADOS ? parseInt(myStatisticsObject.VALOR_CUPOS_ASIGNADOS) : 0
    let quotaValueUsed = myStatisticsObject.VALOR_DISPONIBLE_UTILIZADO ? parseInt(myStatisticsObject.VALOR_DISPONIBLE_UTILIZADO) : 0
    let quotaValueAvailableUsed = myStatisticsObject.VALOR_DISPONIBLE_POR_UTILIZAR ? parseInt(myStatisticsObject.VALOR_DISPONIBLE_POR_UTILIZAR) : 0
    let series = [];
    let labels = [];
    let seriesValue = [];
    let labelsValue = [];
    if (myStatisticsObject) {
      series.push(quotaAvailable)
      labels.push('cupos sin utilizar')
      series.push(quotaUsed)
      labels.push('cupos utilizados')

      seriesValue.push(quotaValueAvailable)
      labelsValue.push('valor cupos sin utilizar')
      seriesValue.push(quotaValueUsed)
      labelsValue.push('valor cupos utilizados')
      seriesValue.push(quotaValueAvailableUsed)
      labelsValue.push('valor disponible cupos utilizados')

    }
    let options = {
      legend: false,
      stroke: {
        colors: ['#F5F5F5']
      },
      labels,
      colors,
      fontFamily: 'LatoWeb',
      dataLabels: {
        enabled: false,
      },
    }

    let optionsValue = {
      legend: false,
      stroke: {
        colors: ['#F5F5F5'],
      },
      labels: labelsValue,
      colors,
      fontFamily: 'LatoWeb',
      dataLabels: {
        enabled: false
      },
    }
    return (
      <div className="reports">
        <Menu isOpen={menuOpen} closeMenu={this.closeMenu} pageId={9} />
        <Header openMenu={this.openMenu} />
        <div className="page-body-container center-horizontally">
          <div className="page-body">
            <UserHeader />
            {true && (
              <Col xs={12} className="general-main-card">
                {(loading) && (
                  <Loading />
                )}
                <div xs={12} className="goback-container space-between">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                  >
                    <span>
                      <img src={arrowback} alt="arrow back" className="goback-icon" />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                  {permissionReport && (
                    <Button
                      className="button-send report-button"
                      variant="success"
                      onClick={(e) => {
                        e.preventDefault();
                        this.exportQuotaReport();
                      }}
                      block>
                      Reporte Cupos
                    </Button>
                  )}
                </div>
                <Col xs={12} className="reports-margin">
                  <Col xs={12} className="card-title-transaction">
                    Reportes
                    </Col>
                </Col>
                {myStatistics && (
                  <Col xs={12} md={{ span: 10, offset: 1 }}>
                    <Row>
                      <Col xs={12} md={6} className="reports_general-container reports_general-separator">
                        <Row className="donut-container">
                          <Col xs={12} className="donut-padding">
                            <div className="donut">
                              <div className="reports_donut-title">
                                <div className="reports_donut-title_description">
                                  Cantidad de <br /> cupos asignados
                                </div>
                                <div className="reports_donut-title_value">
                                  {quotaAssigned}
                                </div>
                              </div>
                              <Chart options={options} series={series} type="donut" width="100%" />
                            </div>
                          </Col>
                          <Col xs="auto">
                            <Row className="flex-justify">
                              <Col xs="auto">
                                <Row>
                                  <Col xs="auto" className="report-point-container">
                                    <div className="report-point" />
                                  </Col>
                                  <Col className="reports_general-key">
                                    <span>{quotaUsed}</span> cupos utilizados
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="auto">
                                <Row>
                                  <Col xs="auto" className="report-point-container">
                                    <div className="report-point light" />
                                  </Col>
                                  <Col className="reports_general-key">
                                    <span>{quotaAvailable}</span> cupos sin utilizar
                              </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} className="reports_general-container reports_general-separator">
                        <Row className="donut-container">
                          <Col xs={12} className="donut-padding">
                            <div className="donut">
                              <div className="reports_donut-title">
                                <div className="reports_donut-title_description">
                                  Valor de cupos <br /> asignados
                                </div>
                                <div className="reports_donut-title_value">
                                  $ {this.getNumber(quotaValueAssigned)}
                                </div>
                              </div>
                              <Chart options={optionsValue} series={seriesValue} type="donut" width="100%" />
                            </div>
                          </Col>
                          <Col xs="auto">
                            <Row className="flex-justify">
                              <Col xs="auto">
                                <Row>
                                  <Col xs="auto" className="report-point-container">
                                    <div className="report-point" />
                                  </Col>
                                  <Col className="reports_general-key">
                                    <span>$ {this.getNumber(quotaValueUsed)}</span><br /> valor cupos utilizados
                              </Col>
                                </Row>
                              </Col>
                              <Col xs="auto">
                                <Row>
                                  <Col xs="auto" className="report-point-container">
                                    <div className="report-point" />
                                  </Col>
                                  <Col className="reports_general-key">
                                    <span>$ {this.getNumber(quotaValueAvailableUsed)}</span><br /> valor disponible cupos<br /> utilizados
                              </Col>
                                </Row>
                              </Col>
                              <Col xs="auto">
                                <Row>
                                  <Col xs="auto" className="report-point-container">
                                    <div className="report-point light" />
                                  </Col>
                                  <Col className="reports_general-key">
                                    <span>$ {this.getNumber(quotaValueAvailable)}</span><br /> valor cupos sin utilizar
                              </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col xs={12}>
                  <Col xs={12} className="">
                    <Row>
                      <Col xs={12} className="tab-container">
                        <Row>
                          <Col
                            xs={6}
                            className={transactions ? 'tab-text-selected' : 'tab-text'}
                            onClick={() => {
                              this.changeTab(true);
                            }}
                          >Transacciones realizadas</Col>
                          {sellerReport && sellerReport.ESTADO !== 'O' && (
                            <Col
                              xs={6}
                              className={sellerReport.ESTADO === 'I' ? 'tab-text-disabled' : (transactions ? 'tab-text' : 'tab-text-selected tab-check')}
                              onClick={() => {
                                if (sellerReport) {
                                  this.changeTab(false);
                                }
                              }}
                            >Vendedores</Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Col>
                {transactions && (
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} className="reports-margin reports-margin-top">
                        <Row>
                          <Col xs={12} md={12}>
                            <Row>
                              <Col xs={12} className="exoneraton-search_title">Seleccione una fecha</Col>
                              <Col xs={12} className="exoneraton-col-padding">
                                <Row>
                                  <Col xs={12} md={6} className="exoneraton-date-boder">
                                    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                      <DatePicker
                                        margin="normal"
                                        label="Desde"
                                        className="home-input"
                                        value={fromDate}
                                        locale='es-ES'
                                        onChange={this.handleDateFromChange}
                                        fullWidth
                                        format="DD/MM/YYYY"
                                        keyboardIcon={<img src={calendaricon} alt="icono calendario" className="calendar-icon" />}
                                        keyboard
                                        disableOpenOnEnter
                                        placeholder="DD/MM/YYYY"
                                        invalidDateMessage="Formato inválido"
                                        mask={value =>
                                          // handle clearing outside if value can be changed outside of the component
                                          value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                                        }
                                        onInputChange={e => {
                                          let value = e.target.value
                                          value = value.replace(/_/g, '')
                                          if (value.length === 10) {
                                            // this.handleDateChange(moment(value, 'DD/MM/YYYY'), 'toDate')
                                            this.handleDateFromChange(moment(value, 'DD/MM/YYYY'))
                                          }
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                                      <DatePicker
                                        margin="normal"
                                        label="Hasta"
                                        className="home-input"
                                        value={toDate}
                                        locale='es-ES'
                                        onChange={this.handleDateToChange}
                                        fullWidth
                                        format="DD/MM/YYYY"
                                        keyboardIcon={<img src={calendaricon} alt="icono calendario" className="calendar-icon" />}
                                        keyboard
                                        disableOpenOnEnter
                                        placeholder="DD/MM/YYYY"
                                        invalidDateMessage="Formato inválido"
                                        mask={value =>
                                          // handle clearing outside if value can be changed outside of the component
                                          value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                                        }
                                        onInputChange={e => {
                                          let value = e.target.value
                                          value = value.replace(/_/g, '')
                                          if (value.length === 10) {
                                            // this.handleDateChange(moment(value, 'DD/MM/YYYY'), 'toDate')
                                            this.handleDateToChange(moment(value, 'DD/MM/YYYY'))
                                          }
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} className="claim-margin">
                            <Row className="search-notice-form">
                              <Col xs={12} md={3} className="login-input-container">
                                <TextField
                                  id="transactionSearchSelect"
                                  select
                                  label="Filtro de busqueda"
                                  className="home-input"
                                  margin="dense"
                                  value={transactionSearchSelect}
                                  onChange={(event) => {
                                    this.oninputChange(event, 'transactionSearchSelect');
                                  }}
                                  fullWidth
                                >
                                  {searchSelect.map((item, index) => {
                                    return (
                                      <MenuItem key={index} value={item.id}>
                                        {item.value}
                                      </MenuItem>
                                    )
                                  })}
                                </TextField>
                              </Col>
                              {this.renderNoticeSearchSelect()}
                            </Row>
                          </Col>

                        </Row>
                      </Col>
                      <Col xs={12} className="results-table-container">
                        {user && (
                          <ReduxLazyScroll
                            id="scroll-pager"
                            isFetching={loadingTransaction}
                            errorMessage=''
                            isParentScrollable={true}
                            parentHeight='250px'
                            loadMore={() => {
                              this.getTransactionReport();
                            }}
                            hasMore={transactionsPageCount > 0}
                          >
                            <Table bordered>
                              <thead>
                                <tr>
                                  <th>Doc</th>
                                  <th>Número documento</th>
                                  <th>Nombre</th>
                                  <th>Número factura</th>
                                  <th>Valor factura</th>
                                  <th>Fecha vencimiento</th>
                                  <th className="notice-th">Código aprobación</th>
                                  <th className="notice-th">Estado</th>
                                </tr>
                              </thead>
                              <tbody>
                                {transactionsArray.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.TIPO_DOCUMENTO}</td>
                                      <td>{item.DOCUMENTO}</td>
                                      <td>{item.CLIENTE}</td>
                                      <td>{item.TITULO_VALOR}</td>
                                      <td>${this.getNumber(item.VALOR_GARANTIZADO)}</td>
                                      <td>{item.VENCIMIENTO === '' ? '' : moment(item.VENCIMIENTO).format('DD/MM/YYYY')}</td>
                                      <td>{item.AUTORIZACION}</td>
                                      <td>{item.ESTADO}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                            {(loadingTransaction) && (
                              <ListLoader />
                            )}
                          </ReduxLazyScroll>
                        )}

                      </Col>
                      {(transactionsArray.length > 0 && downloadReport && downloadReport.ESTADO !== 'O') && (
                        <Col xs={12} md={{ span: 4, offset: 4 }}>
                          <Button
                            className="button-send"
                            variant="success"
                            disabled={transactionsArray.length === 0 || downloadReport.ESTADO === 'I'}
                            onClick={(e) => {
                              e.preventDefault();
                              this.exportTransactionReport();
                            }}
                            block>
                            Descargar informe
                          </Button>
                        </Col>
                      )}
                      {/*
                        {!(!sellerDocumentNumber || (sellerDocumentNumber && transactionsArray.length > 0)) && (
                        <Col xs={12}>
                        <Row>
                        <Col xs={12} md={{span: 4, offset: 4}} className="reservation-loading-text">
                        <img src={denegate} alt="icono reservas" className="resevation-icon-denegate"/>
                        </Col>
                        <Col xs={12} md={{span: 6, offset: 3}} className="not-reservation-text">
                        El documento <span className="not-reservation-text_bold">Nº {sellerDocumentNumber}</span> no tiene facturas registradas en el sistema
                        </Col>
                        </Row>
                        </Col>
                        )}
                        */}
                    </Row>
                  </Col>
                )}
                {!transactions && (
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} className="reports-margin reports-margin-top">
                        <Row>
                          <Col xs={12} md={4} className="users-tab-search-title users-tab-search-title-margin">
                            Números del último mes contable
                          </Col>
                          <Col xs={12} md={8} className="users-tab-search-title-margin">
                            <Row>
                              <Col>
                                {/*
                                  <div className="search-input-container">
                                  <span>
                                  <img src={searchicon} alt="Icono buscar"/>
                                  </span>
                                  <input placeholder="Buscar número de cedula" />
                                  </div>
                                  */}
                                <TextField
                                  id="invoiceNumber"
                                  label="Buscar número de cédula"
                                  className="home-input"
                                  margin="dense"
                                  value={documentNumber}
                                  onChange={(event) => {
                                    this.oninputChange(event, 'documentNumber');
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <img src={searchicon} alt="icono calendario" className="calendar-icon" />
                                    ),
                                  }}
                                  fullWidth
                                />
                              </Col>
                              <Col xs={5}>
                                <Button
                                  className="button-send"
                                  variant="success"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.scrollToMyRef();
                                    this.pageSellers = 0;
                                    this.getSellersReport();
                                  }}
                                  block>
                                  Buscar
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      {(!documentNumber || (documentNumber && sellers.length > 0)) && (
                        <Col xs={12} className="results-table-container">
                          {user && (
                            <ReduxLazyScroll
                              id="scroll-pager"
                              isFetching={loadingTransaction}
                              errorMessage=''
                              isParentScrollable={true}
                              parentHeight='250px'
                              loadMore={() => {
                                this.getSellersReport();
                              }}
                              hasMore={sellersPageCount > 0}
                            >
                              <Row>
                                {sellers.map((item, index) => {
                                  return (
                                    <Col xs={12} key={index} className="reports_user">
                                      <Row className="reports_info-container">
                                        <Col xs={12} md={4}>
                                          <Row>
                                            <Col xs={12} md="auto" className="reports_user-key-container">
                                              <img src={item.FOTO_USUARIO ? item.FOTO_USUARIO : userLogo} alt="imagen usuario" className="reports_user-image" />
                                            </Col>
                                            <Col xs={12} md className="reports_user-key-container">
                                              <Row>
                                                <Col xs={12} className="reports_user-key">{item.NOMBRE_USUARIO}</Col>
                                                <Col xs={12} className="reports_user-value">{item.TIPO_DOCUMENTO_USUARIO} {item.DOCUMENTO_USUARIO}</Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col xs={12} md className="reports_user-key-container">
                                          <Row>
                                            <Col xs={12} className="reports_user-key">Transacciones aprobadas</Col>
                                            <Col xs={12} className="reports_user-value">{item.APROBADAS}</Col>
                                          </Row>
                                        </Col>
                                        <Col xs={12} md className="reports_user-key-container">
                                          <Row>
                                            <Col xs={12} className="reports_user-key">Consultas realizadas</Col>
                                            <Col xs={12} className="reports_user-value">{item.CONSULTA_PR_CUPO}</Col>
                                          </Row>
                                        </Col>
                                        <Col xs={12} md className="reports_user-key-container">
                                          <Row>
                                            <Col xs={12} className="reports_user-key">Monto</Col>
                                            <Col xs={12} className="reports_user-value">${this.getNumber(item.VALOR_GARANTIAS)}</Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Col>
                                  )
                                })}
                              </Row>
                              {(loadingTransaction) && (
                                <ListLoader />
                              )}
                            </ReduxLazyScroll>
                          )}
                        </Col>
                      )}
                      {(sellers.length > 0 && downloadSellerReport && downloadSellerReport.ESTADO !== 'O') && (
                        <Col xs={12} md={{ span: 4, offset: 4 }}>
                          <Button
                            className="button-send"
                            variant="success"
                            disabled={downloadSellerReport.ESTADO === 'I'}
                            onClick={(e) => {
                              e.preventDefault();
                              this.exportSellersReport();
                            }}
                            block>
                            Descargar informe
                          </Button>
                        </Col>
                      )}
                      {!(!documentNumber || (documentNumber && sellers.length > 0)) && (
                        <Col xs={12}>
                          <Row>
                            <Col xs={12} md={{ span: 4, offset: 4 }} className="reservation-loading-text">
                              <img src={denegate} alt="icono reservas" className="resevation-icon-denegate" />
                            </Col>
                            <Col xs={12} md={{ span: 6, offset: 3 }} className="not-reservation-text">
                              El usuario identificado con <span className="not-reservation-text_bold">CC {documentNumber}</span> no se encuentra registrada en el sistema
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Col>
                )}
              </Col>
            )}
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
    user,
    myStatistics,
    loading,
    permissions,
    globals,
  } = state.user;
  let {
    transactions: transactionsArray,
    transactionsPageCount,
    loading: loadingTransaction,
    exportTransactions,
    sellers,
    sellersPageCount,
    exportSellers,
    quotaReport,
  } = state.transaction;
  return {
    loading,
    myStatistics,
    user,
    transactionsArray,
    transactionsPageCount,
    loadingTransaction,
    exportTransactions,
    sellers,
    sellersPageCount,
    exportSellers,
    permissions,
    quotaReport,
    globals,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
