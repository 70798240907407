import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from './redux/actions'
import ErrorModal from './components/error-modal';

import './assets/latofonts.css';
import './assets/bootstrap.min.css';
import './App.scss';
import config from './config';

class App extends Component {
  constructor () {
    const fecha = new Date(2024, 4, 14, 12, 0) + ' --> Salida a producción - ajuste consumo servicio pr_vendedores';
    console.info(fecha);
    console.info('env --> ', config.apiUrl);
    super();
    this.state = {
      showModal: false
    }
  }

  termModalClose = () => {
    this.setState({
      showModal: false
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.generalError && nextProps.generalError !== this.props.generalError) {
      this.setState({
        showModal: true,
        generalError: nextProps.generalError
      })
    }
  }

  static propTypes={
    children: PropTypes.object.isRequired
  };

  render() {
    const { children }= this.props;
    const { showModal, generalError }= this.state;

    return (
      <div className="App">
        <ErrorModal
          show={showModal}
          confirm={this.termModalClose}
          onHide={this.termModalClose}
          errorText={generalError}
          />
        { children }
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
      generalError
  } = state.user;

  return {
      generalError
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
