import config from '../../config';
import { authHeader } from '../../helpers';

export const sendReserve = ({documentType, documentNumber, userDocument, userDocumentType, userEmail, reserveValue, queryCode}) => {
  const headers = authHeader()
  const requestOptions = {
    method: 'POST',
    headers
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais = ('PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]) ? globals.PAIS[0].ID_PAIS : '1';
  let finalParams = `id_pais=${idPais}&comprador_tipo_documento=${documentType}&tipo_documento=${userDocumentType}&documento=${userDocument}&comprador_documento=${documentNumber}&usuario=${userEmail}&valor=${reserveValue}&codigo_consulta=${queryCode}`;
  return fetch(`${config.apiUrl}/pr_solicitud_reservas?${finalParams}`, requestOptions).then(handleResponse);
}

export const getReservations = ({documentType, documentNumber, userDocument, userDocumentType, page}) => {
  const headers = authHeader()
  const requestOptions = {
    method: 'GET',
    headers
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais = ('PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]) ? globals.PAIS[0].ID_PAIS : '1';
  let finalParams = `id_pais=${idPais}&comprador_tipo_documento=${documentType}&tipo_documento=${userDocumentType}&documento=${userDocument}&comprador_documento=${documentNumber}&pagina=${page}&registros=5`;
  return fetch(`${config.apiUrl}/pr_reserva_detalle?${finalParams}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = (text && response.ok) && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        window.location.reload(true);
      }
      let errorMessage = false
      if (response.status === 400) {
        errorMessage = response.headers.get('mensaje_error');
      }
      let errorArray = {
        status: response.status,
        text: (data && data.message) || response.statusText,
        errorMessage
      }
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}
