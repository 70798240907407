import { combineReducers } from 'redux'

import advanceAgreements from './advance-agreements.reducer'
import user from './user.reducer'
import document from './document.reducer'
import transaction from './transaction.reducer'
import reserve from './reserve.reducer'
import refunds from './refunds.reducer'
import exoneration from './exoneration.reducer'
import claim from './claim.reducer'
import liquidator from './liquidator.reducer'

const rootReducer = combineReducers({
  advanceAgreements,
  user,
  document,
  transaction,
  reserve,
  refunds,
  exoneration,
  claim,
  liquidator
})

export default rootReducer
