import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import './chat.scss';
import chatNew from '../../assets/icons/chatnew.png';
import chatoptions from '../../assets/icons/chatoptions.png';
import wpicon from '../../assets/icons/wpicon.png';

class Chat extends Component {

  state = {
    isOpen: false
  }

  openOptions = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  // openChat = () => {
  //   const { home } = this.props;

  //   if(home) {
  //     window.gtag('event', 'chat_linea', { 'event_category': 'sin-login', 'event_label': 'sin-login' });
  //   }

  //   // window.$zopim.livechat.departments.filter("Mi Covinoc");
  //   // window.$zopim.livechat.departments.setVisitorDepartment('Mi Covinoc');
  //   // window.$zopim.livechat.window.show();

  //   window.lh_inst.showStartWindow()
  // }

  openWp = () => {
    const { home } = this.props;
    if (home) {
      // window.gtag('event', 'chat_whapp', { 'event_category': 'sin-login', 'event_label': 'sin-login' });
    } else {
      let userJson = JSON.parse(localStorage.getItem('user'));
      let globalsJson = JSON.parse(localStorage.getItem('globals'));
      let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
      // window.gtag('event', 'chat_whapp', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    }
    window.open("https://covinoc.com/w/?telefono=573187116639&utm_source=COVIFACTURA&utm_medium=WEB&texto=&utm_content=boton_web&plantilla=WEB&utm_term=COVIFACTURA&utm_campaign=COVIFACTURA_WEB&campaign=COVIFACTURA_WEB", '_newtab');
  }

  render() {
    const { home, mobile } = this.props;
    const { isOpen } = this.state;
    let chatMobile = mobile ? "chat" : "chat"
    return (
      <div
        className={home ? chatMobile + " chat-home" : chatMobile}>
        {/* <div
          onClick={(e) => {
            e.preventDefault()
            this.openChat()
          }}
          className={`chat-new_items-container chat-new_item-chat${isOpen ? ' is-open' : ''}`}>
          <div>
            Chat
                </div>
          <img src={chatNew} alt="chat icon" />

        </div> */}
        <div
          onClick={(e) => {
            e.preventDefault()
            this.openWp()
          }}
          className={`chat-new_items-container chat-new_item-wp${isOpen ? ' is-open' : ''}`}>
          <div>
            Whatsapp
                </div>
          <img src={wpicon} alt="chat icon" />
        </div>
        <div
          onClick={(e) => {
            e.preventDefault()
            this.openOptions()
          }}
          className="chat-new_options-cotainer">
          <img src={chatoptions} alt="chat icon" />
          <div>
            ¿Necesita ayuda?
                </div>
        </div>
      </div>
    );
  }
}

Chat.propTypes = {
  home: PropTypes.bool,
};

Chat.defaultProps = {
  home: false,
};

export default Chat;
