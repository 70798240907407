import * as liquidatorService from '../services';
import {
  GET_LIQUIDATOR_RATES_REQUEST,
  GET_LIQUIDATOR_RATES_SUCCESS,
  GET_LIQUIDATOR_RATES_FAILURE,
  GET_LIQUIDATOR_DETAIL_REQUEST,
  GET_LIQUIDATOR_DETAIL_SUCCESS,
  GET_LIQUIDATOR_DETAIL_FAILURE,
  GET_LIQUIDATOR_REQUEST,
  GET_LIQUIDATOR_SUCCESS,
  GET_LIQUIDATOR_FAILURE,
  GENERAL_ERROR,
} from '../action-types'

export const getRates = ({userDocumentType, userDocument, queryCode}) => {
  return dispatch => {
    dispatch(request());
    liquidatorService.getRates({userDocumentType, userDocument, queryCode})
    .then(
      data => {
        dispatch(success(data));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: GET_LIQUIDATOR_RATES_REQUEST } }
  function success(data) { return { type: GET_LIQUIDATOR_RATES_SUCCESS, data } }
  function failure(error) { return { type: GET_LIQUIDATOR_RATES_FAILURE, error } }
}

export const getLiquidatorDetail = ({userDocumentType, userDocument, liquidator}) => {
  return dispatch => {
    dispatch(request());
    liquidatorService.getLiquidatorDetail({userDocumentType, userDocument, liquidator})
    .then(
      data => {
        dispatch(success(data));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: GET_LIQUIDATOR_DETAIL_REQUEST } }
  function success(data) { return { type: GET_LIQUIDATOR_DETAIL_SUCCESS, data } }
  function failure(error) { return { type: GET_LIQUIDATOR_DETAIL_FAILURE, error } }
}

export const getLiquidator = ({userDocumentType, userDocument, queryCode, sellerDocumentType, sellerDocumentNumber, quotaValue, firstInstallmentDate, coviRate, netRate, dues, extendedQuota, userName}) => {
  return dispatch => {
    dispatch(request());
    liquidatorService.getLiquidator({userDocumentType, userDocument, queryCode, sellerDocumentType, sellerDocumentNumber, quotaValue, firstInstallmentDate, coviRate, netRate, dues, extendedQuota, userName})
    .then(
      data => {
        dispatch(success(data));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: GET_LIQUIDATOR_REQUEST } }
  function success(data) { return { type: GET_LIQUIDATOR_SUCCESS, data } }
  function failure(error) { return { type: GET_LIQUIDATOR_FAILURE, error } }
}

export const generalError = (generalError) => { return { type: GENERAL_ERROR, generalError } }
