import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Col, Button, Modal } from 'react-bootstrap';
import { FormControlLabel, Checkbox} from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'

const styles = {
  root: {
    color: green[600],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
};

class TermsModal extends Component {

  constructor() {
    super();
    this.state = {
      termsAccepted: false,
    };

  }

  checkChange = () => event => {
    this.setState({ termsAccepted: event.target.checked });
  };

  confirm = () => {
    this.props.confirm();
  }

  render() {
    const {
      classes,
      show,
      onHide,
    } = this.props;
    const {
      termsAccepted,
    } = this.state;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="terms-modal-title">
            Bienvenido a Covifactura
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="terms-modal-body">
            <Col xs={12} className="terms-modal_body-description">
              Para poder acceder a nuestra plataforma usted primero debe
            </Col>
            <Col xs={12} className="terms-modal_body-form">
              <Link to="/terminos-y-condiciones?termino=1" className="terms-link-modal" target="_blank">Aceptar los términos y condiciones</Link>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAccepted}
                    onChange={this.checkChange()}
                    classes={{
                      root: classes.root,
                      checked: classes.checked,
                    }}
                  />
                }
              />
            </Col>
            <Col xs={12}>
              <Button
                className="button-send"
                variant="success"
                disabled={!termsAccepted}
                onClick={(e) => {
                  e.preventDefault();
                  this.confirm();
                }}
                block
              >
                Aceptar
              </Button>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

TermsModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TermsModal);
