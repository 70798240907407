export const GET_OBLIGATIONS_REQUEST = 'GET_OBLIGATIONS_REQUEST';
export const GET_OBLIGATIONS_SUCCESS = 'GET_OBLIGATIONS_SUCCESS';
export const GET_OBLIGATIONS_FAILURE = 'GET_OBLIGATIONS_FAILURE';

export const SET_USERDATA = 'SET_USERDATA';
export const GET_USERDATA = 'GET_USERDATA';
export const RESET_ALL = 'RESET_ALL';

export const RESET_DEBTOR = 'RESET_DEBTOR';

export const SIGNOFF = 'SIGNOFF';

export const GENERAL_ERROR = 'GENERAL_ERROR';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const GET_GLOBALDATA_REQUEST = 'GET_GLOBALDATA_REQUEST';
export const GET_GLOBALDATA_SUCCESS = 'GET_GLOBALDATA_SUCCESS';
export const GET_GLOBALDATA_FAILURE = 'GET_GLOBALDATA_FAILURE';

export const GET_ROLPERMISSIONS_REQUEST = 'GET_ROLPERMISSIONS_REQUEST';
export const GET_ROLPERMISSIONS_SUCCESS = 'GET_ROLPERMISSIONS_SUCCESS';
export const GET_ROLPERMISSIONS_FAILURE = 'GET_ROLPERMISSIONS_FAILURE';

export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE';

export const GET_CLIENTTYPES_REQUEST = 'GET_CLIENTTYPES_REQUEST';
export const GET_CLIENTTYPES_SUCCESS = 'GET_CLIENTTYPES_SUCCESS';
export const GET_CLIENTTYPES_FAILURE = 'GET_CLIENTTYPES_FAILURE';

export const SET_BIRTHDATE_REQUEST = 'SET_BIRTHDATE_REQUEST';
export const SET_BIRTHDATE_SUCCESS = 'SET_BIRTHDATE_SUCCESS';
export const SET_BIRTHDATE_FAILURE = 'SET_BIRTHDATE_FAILURE';

export const SEND_FILE_REQUEST = 'SEND_FILE_REQUEST';
export const SEND_FILE_SUCCESS = 'SEND_FILE_SUCCESS';
export const SEND_FILE_FAILURE = 'SEND_FILE_FAILURE';

export const GET_FAMILYLIST_REQUEST = 'GET_FAMILYLIST_REQUEST';
export const GET_FAMILYLIST_SUCCESS = 'GET_FAMILYLIST_SUCCESS';
export const GET_FAMILYLIST_FAILURE = 'GET_FAMILYLIST_FAILURE';

export const GET_CAREERLIST_REQUEST = 'GET_CAREERLIST_REQUEST';
export const GET_CAREERLIST_SUCCESS = 'GET_CAREERLIST_SUCCESS';
export const GET_CAREERLIST_FAILURE = 'GET_CAREERLIST_FAILURE';

export const GET_ISUSER_REQUEST = 'GET_ISUSER_REQUEST';
export const GET_ISUSER_SUCCESS = 'GET_ISUSER_SUCCESS';
export const GET_ISUSER_FAILURE = 'GET_ISUSER_FAILURE';
export const GET_ISUSER_CLEAN = 'GET_ISUSER_CLEAN';

export const GET_RECOVER_REQUEST = 'GET_RECOVER_REQUEST';
export const GET_RECOVER_SUCCESS = 'GET_RECOVER_SUCCESS';
export const GET_RECOVER_FAILURE = 'GET_RECOVER_FAILURE';

export const GET_REMEMBER_REQUEST = 'GET_REMEMBER_REQUEST';
export const GET_REMEMBER_SUCCESS = 'GET_REMEMBER_SUCCESS';
export const GET_REMEMBER_FAILURE = 'GET_REMEMBER_FAILURE';

export const GET_CHANGE_REQUEST = 'GET_CHANGE_REQUEST';
export const GET_CHANGE_SUCCESS = 'GET_CHANGE_SUCCESS';
export const GET_CHANGE_FAILURE = 'GET_CHANGE_FAILURE';

export const GET_CHANGEPASSWORD_REQUEST = 'GET_CHANGEPASSWORD_REQUEST';
export const GET_CHANGEPASSWORD_SUCCESS = 'GET_CHANGEPASSWORD_SUCCESS';
export const GET_CHANGEPASSWORD_FAILURE = 'GET_CHANGEPASSWORD_FAILURE';

export const GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAILURE = 'GET_DOCUMENTS_FAILURE';

export const GET_TERMS_REQUEST = 'GET_TERMS_REQUEST';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
export const GET_TERMS_FAILURE = 'GET_TERMS_FAILURE';

export const GET_TERMSACEPTED_REQUEST = 'GET_TERMSACEPTED_REQUEST';
export const GET_TERMSACEPTED_SUCCESS = 'GET_TERMSACEPTED_SUCCESS';
export const GET_TERMSACEPTED_FAILURE = 'GET_TERMSACEPTED_FAILURE';

export const SET_TERMSACEPTED_REQUEST = 'SET_TERMSACEPTED_REQUEST';
export const SET_TERMSACEPTED_SUCCESS = 'SET_TERMSACEPTED_SUCCESS';
export const SET_TERMSACEPTED_FAILURE = 'SET_TERMSACEPTED_FAILURE';

export const GET_QUOTA_REQUEST = 'GET_QUOTA_REQUEST';
export const GET_QUOTA_SUCCESS = 'GET_QUOTA_SUCCESS';
export const GET_QUOTA_FAILURE = 'GET_QUOTA_FAILURE';

export const SEND_TRASACTION_REQUEST = 'SEND_TRASACTION_REQUEST';
export const SEND_TRASACTION_SUCCESS = 'SEND_TRASACTION_SUCCESS';
export const SEND_TRASACTION_FAILURE = 'SEND_TRASACTION_FAILURE';

export const GET_TRASACTIONREPORT_REQUEST = 'GET_TRASACTIONREPORT_REQUEST';
export const GET_TRASACTIONREPORT_SUCCESS = 'GET_TRASACTIONREPORT_SUCCESS';
export const GET_TRASACTIONREPORT_FAILURE = 'GET_TRASACTIONREPORT_FAILURE';

export const GET_SELLERSREPORT_REQUEST = 'GET_SELLERSREPORT_REQUEST';
export const GET_SELLERSREPORT_SUCCESS = 'GET_SELLERSREPORT_SUCCESS';
export const GET_SELLERSREPORT_FAILURE = 'GET_SELLERSREPORT_FAILURE';

export const GET_SELLERSNUMBER_REQUEST = 'GET_SELLERSNUMBER_REQUEST';
export const GET_SELLERSNUMBER_SUCCESS = 'GET_SELLERSNUMBER_SUCCESS';
export const GET_SELLERSNUMBER_FAILURE = 'GET_SELLERSNUMBER_FAILURE';

export const EXPORT_SELLERSREPORT_REQUEST = 'EXPORT_SELLERSREPORT_REQUEST';
export const EXPORT_SELLERSREPORT_SUCCESS = 'EXPORT_SELLERSREPORT_SUCCESS';
export const EXPORT_SELLERSREPORT_FAILURE = 'EXPORT_SELLERSREPORT_FAILURE';

export const EXPORT_QUOTAREPORT_REQUEST = 'EXPORT_QUOTAREPORT_REQUEST';
export const EXPORT_QUOTAREPORT_SUCCESS = 'EXPORT_QUOTAREPORT_SUCCESS';
export const EXPORT_QUOTAREPORT_FAILURE = 'EXPORT_QUOTAREPORT_FAILURE';

export const EXPORT_BULKTRASACTION_REQUEST = 'EXPORT_BULKTRASACTION_REQUEST';
export const EXPORT_BULKTRASACTION_SUCCESS = 'EXPORT_BULKTRASACTION_SUCCESS';
export const EXPORT_BULKTRASACTION_FAILURE = 'EXPORT_BULKTRASACTION_FAILURE';

export const RESET_EXPORT_TRASACTIONREPORT_REQUEST =
  'RESET_EXPORT_TRASACTIONREPORT_REQUEST';

export const EXPORT_TRASACTIONREPORT_REQUEST =
  'EXPORT_TRASACTIONREPORT_REQUEST';
export const EXPORT_TRASACTIONREPORT_SUCCESS =
  'EXPORT_TRASACTIONREPORT_SUCCESS';
export const EXPORT_TRASACTIONREPORT_FAILURE =
  'EXPORT_TRASACTIONREPORT_FAILURE';

export const SEND_BULKTRASACTION_REQUEST = 'SEND_BULKTRASACTION_REQUEST';
export const SEND_BULKTRASACTION_SUCCESS = 'SEND_BULKTRASACTION_SUCCESS';
export const SEND_BULKTRASACTIONFINAL_SUCCESS =
  'SEND_BULKTRASACTIONFINAL_SUCCESS';
export const SEND_BULKTRASACTION_FAILURE = 'SEND_BULKTRASACTION_FAILURE';

export const SEND_BULKEMAILTRASACTION_REQUEST =
  'SEND_BULKEMAILTRASACTION_REQUEST';
export const SEND_BULKEMAILTRASACTION_SUCCESS =
  'SEND_BULKEMAILTRASACTION_SUCCESS';
export const SEND_BULKEMAILTRASACTION_FAILURE =
  'SEND_BULKEMAILTRASACTION_FAILURE';

export const SEND_BULKEXONERATION_REQUEST = 'SEND_BULKEXONERATION_REQUEST';
export const SEND_BULKEXONERATION_SUCCESS = 'SEND_BULKEXONERATION_SUCCESS';
export const SEND_BULKEXONERATIONFINAL_SUCCESS =
  'SEND_BULKEXONERATIONFINAL_SUCCESS';
export const SEND_BULKEXONERATION_FAILURE = 'SEND_BULKEXONERATION_FAILURE';

export const SEND_BULKNOTICE_REQUEST = 'SEND_BULKNOTICE_REQUEST';
export const SEND_BULKNOTICE_SUCCESS = 'SEND_BULKNOTICE_SUCCESS';
export const SEND_BULKNOTICEFINAL_SUCCESS = 'SEND_BULKNOTICEFINAL_SUCCESS';
export const SEND_BULKNOTICE_FAILURE = 'SEND_BULKNOTICE_FAILURE';

export const RESET_EXPORT_BULKEXONERATION_REQUEST =
  'RESET_EXPORT_BULKEXONERATION_REQUEST';
export const EXPORT_BULKEXONERATION_REQUEST = 'EXPORT_BULKEXONERATION_REQUEST';
export const EXPORT_BULKEXONERATION_SUCCESS = 'EXPORT_BULKEXONERATION_SUCCESS';
export const EXPORT_BULKEXONERATION_FAILURE = 'EXPORT_BULKEXONERATION_FAILURE';

export const RESET_EXPORT_BULKNOTICE_REQUEST =
  'RESET_EXPORT_BULKNOTICE_REQUEST';
export const EXPORT_BULKNOTICE_REQUEST = 'EXPORT_BULKNOTICE_REQUEST';
export const EXPORT_BULKNOTICE_SUCCESS = 'EXPORT_BULKNOTICE_SUCCESS';
export const EXPORT_BULKNOTICE_FAILURE = 'EXPORT_BULKNOTICE_FAILURE';

export const SEND_RESERVE_REQUEST = 'SEND_RESERVE_REQUEST';
export const SEND_RESERVE_SUCCESS = 'SEND_RESERVE_SUCCESS';
export const SEND_RESERVE_FAILURE = 'SEND_RESERVE_FAILURE';

export const UPDATE_STUDENT_REQUEST = 'UPDATE_STUDENT_REQUEST';
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS';
export const UPDATE_STUDENT_FAILURE = 'UPDATE_STUDENT_FAILURE';

export const VALIDATE_QUERYCODE_REQUEST = 'VALIDATE_QUERYCODE_REQUEST';
export const VALIDATE_QUERYCODE_SUCCESS = 'VALIDATE_QUERYCODE_SUCCESS';
export const VALIDATE_QUERYCODE_FAILURE = 'VALIDATE_QUERYCODE_FAILURE';

export const GET_RESERVATIONS_REQUEST = 'GET_RESERVATIONS_REQUEST';
export const GET_RESERVATIONS_SUCCESS = 'GET_RESERVATIONS_SUCCESS';
export const GET_RESERVATIONS_FAILURE = 'GET_RESERVATIONS_FAILURE';

export const GET_MYPAYMENTS_REQUEST = 'GET_MYPAYMENTS_REQUEST';
export const GET_MYPAYMENTS_SUCCESS = 'GET_MYPAYMENTS_SUCCESS';
export const GET_MYPAYMENTS_FAILURE = 'GET_MYPAYMENTS_FAILURE';

export const GET_MYPAYMENT_INVOICE_REQUEST = 'GET_MYPAYMENT_INVOICE_REQUEST';
export const GET_MYPAYMENT_INVOICE_SUCCESS = 'GET_MYPAYMENT_INVOICE_SUCCESS';
export const GET_MYPAYMENT_INVOICE_FAILURE = 'GET_MYPAYMENT_INVOICE_FAILURE';

export const GET_AFFILIATE_REQUEST = 'GET_AFFILIATE_REQUEST';
export const GET_AFFILIATE_SUCCESS = 'GET_AFFILIATE_SUCCESS';
export const GET_AFFILIATE_FAILURE = 'GET_AFFILIATE_FAILURE';

export const GET_AFFILIATE_TOKEN_REQUEST = 'GET_AFFILIATE_TOKEN_REQUEST';
export const GET_AFFILIATE_TOKEN_SUCCESS = 'GET_AFFILIATE_TOKEN_SUCCESS';
export const GET_AFFILIATE_TOKEN_FAILURE = 'GET_AFFILIATE_TOKEN_FAILURE';

export const GET_MANAGEDUSERS_REQUEST = 'GET_MANAGEDUSERS_REQUEST';
export const GET_MANAGEDUSERS_SUCCESS = 'GET_MANAGEDUSERS_SUCCESS';
export const GET_MANAGEDUSERS_FAILURE = 'GET_MANAGEDUSERS_FAILURE';

export const SET_MANAGEDUSERS_REQUEST = 'SET_MANAGEDUSERS_REQUEST';
export const SET_MANAGEDUSERS_SUCCESS = 'SET_MANAGEDUSERS_SUCCESS';
export const SET_MANAGEDUSERS_FAILURE = 'SET_MANAGEDUSERS_FAILURE';

export const RESET_MANAGEDUSERS = 'RESET_MANAGEDUSERS';

export const GET_ROLELIST_REQUEST = 'GET_ROLELIST_REQUEST';
export const GET_ROLELIST_SUCCESS = 'GET_ROLELIST_SUCCESS';
export const GET_ROLELIST_FAILURE = 'GET_ROLELIST_FAILURE';

export const GET_PAYMENTDETAIL_REQUEST = 'GET_PAYMENTDETAIL_REQUEST';
export const GET_PAYMENTDETAIL_SUCCESS = 'GET_PAYMENTDETAIL_SUCCESS';
export const GET_PAYMENTDETAIL_FAILURE = 'GET_PAYMENTDETAIL_FAILURE';

export const GET_PAYMENTLINK_REQUEST = 'GET_PAYMENTLINK_REQUEST';
export const GET_PAYMENTLINK_SUCCESS = 'GET_PAYMENTLINK_SUCCESS';
export const GET_PAYMENTLINK_FAILURE = 'GET_PAYMENTLINK_FAILURE';

export const RESET_PAYMENTDETAIL_REQUEST = 'RESET_PAYMENTDETAIL_REQUEST';

export const GET_REFUNDS_REQUEST = 'GET_REFUNDS_REQUEST';
export const GET_REFUNDS_SUCCESS = 'GET_REFUNDS_SUCCESS';
export const GET_REFUNDS_FAILURE = 'GET_REFUNDS_FAILURE';

export const EXPORT_REFUNDS_REQUEST = 'EXPORT_REFUNDS_REQUEST';
export const EXPORT_REFUNDS_SUCCESS = 'EXPORT_REFUNDS_SUCCESS';
export const EXPORT_REFUNDS_FAILURE = 'EXPORT_REFUNDS_FAILURE';

export const RESET_EXPORT_REFUNDS_REQUEST = 'RESET_EXPORT_REFUNDS_REQUEST';
export const RESET_REFUNDS_REQUEST = 'RESET_REFUNDS_REQUEST';

export const GET_ADVANCE_AGREEMENTS_REQUEST = 'GET_ADVANCE_AGREEMENTS_REQUEST';
export const GET_ADVANCE_AGREEMENTS_SUCCESS = 'GET_ADVANCE_AGREEMENTS_SUCCESS';
export const GET_ADVANCE_AGREEMENTS_FAILURE = 'GET_ADVANCE_AGREEMENTS_FAILURE';

export const UPLOAD_ADVANCE_AGREEMENT_REQUEST =
  'UPLOAD_ADVANCE_AGREEMENT_REQUEST';
export const UPLOAD_ADVANCE_AGREEMENT_SUCCESS =
  'UPLOAD_ADVANCE_AGREEMENT_SUCCESS';
export const UPLOAD_ADVANCE_AGREEMENT_FAILURE =
  'UPLOAD_ADVANCE_AGREEMENT_FAILURE';

export const RESET_UPLOAD_ADVANCE_AGREEMENT_REQUEST =
  'RESET_UPLOAD_ADVANCE_AGREEMENT_REQUEST';
export const RESET_ADVANCE_AGREEMENTS_REQUEST =
  'RESET_ADVANCE_AGREEMENTS_REQUEST';

export const GET_MYSTATISTICS_REQUEST = 'GET_MYSTATISTICS_REQUEST';
export const GET_MYSTATISTICS_SUCCESS = 'GET_MYSTATISTICS_SUCCESS';
export const GET_MYSTATISTICS_FAILURE = 'GET_MYSTATISTICS_FAILURE';

export const REQUEST_CLIENTSTUDY_REQUEST = 'REQUEST_CLIENTSTUDY_REQUEST';
export const REQUEST_CLIENTSTUDY_SUCCESS = 'REQUEST_CLIENTSTUDY_SUCCESS';
export const REQUEST_CLIENTSTUDY_FAILURE = 'REQUEST_CLIENTSTUDY_FAILURE';

export const REQUEST_THREE_PAYMENTS_CLIENTSTUDY_REQUEST =
  'REQUEST_THREE_PAYMENTS_CLIENTSTUDY_REQUEST';
export const REQUEST_THREE_PAYMENTS_CLIENTSTUDY_SUCCESS =
  'REQUEST_THREE_PAYMENTS_CLIENTSTUDY_SUCCESS';
export const REQUEST_THREE_PAYMENTS_CLIENTSTUDY_FAILURE =
  'REQUEST_THREE_PAYMENTS_CLIENTSTUDY_FAILURE';

export const SAVE_FORM_REQUEST = 'SAVE_FORM_REQUEST';
export const SAVE_FORM_SUCCESS = 'SAVE_FORM_SUCCESS';
export const SAVE_FORM_FAILURE = 'SAVE_FORM_FAILURE';

export const GET_VALIDATE_FORM_REQUEST = 'GET_VALIDATE_FORM_REQUEST';
export const GET_VALIDATE_FORM_SUCCESS = 'GET_VALIDATE_FORM_SUCCESS';
export const GET_VALIDATE_FORM_FAILURE = 'GET_VALIDATE_FORM_FAILURE';

export const REQUEST_PUBLICCLIENTSTUDY_REQUEST =
  'REQUEST_PUBLICCLIENTSTUDY_REQUEST';
export const REQUEST_PUBLICCLIENTSTUDY_SUCCESS =
  'REQUEST_PUBLICCLIENTSTUDY_SUCCESS';
export const REQUEST_PUBLICCLIENTSTUDY_FAILURE =
  'REQUEST_PUBLICCLIENTSTUDY_FAILURE';

export const SEND_CLIENTLINK_REQUEST = 'SEND_CLIENTLINK_REQUEST';
export const SEND_CLIENTLINK_SUCCESS = 'SEND_CLIENTLINK_SUCCESS';
export const SEND_CLIENTLINK_FAILURE = 'SEND_CLIENTLINK_FAILURE';
export const RESET_CLIENTLINK = 'RESET_CLIENTLINK';
export const RESET_AFFILIATE_TOKEN = 'RESET_AFFILIATE_TOKEN';

export const GET_GUARANTEESTOEXONERATE_REQUEST =
  'GET_GUARANTEESTOEXONERATE_REQUEST';
export const GET_GUARANTEESTOEXONERATE_SUCCESS =
  'GET_GUARANTEESTOEXONERATE_SUCCESS';
export const GET_GUARANTEESTOEXONERATE_FAILURE =
  'GET_GUARANTEESTOEXONERATE_FAILURE';

export const GET_GUARANTEESTONOTIFY_REQUEST = 'GET_GUARANTEESTONOTIFY_REQUEST';
export const GET_GUARANTEESTONOTIFY_SUCCESS = 'GET_GUARANTEESTONOTIFY_SUCCESS';
export const GET_GUARANTEESTONOTIFY_FAILURE = 'GET_GUARANTEESTONOTIFY_FAILURE';

export const GET_GUARANTEESTORAISE_REQUEST = 'GET_GUARANTEESTORAISE_REQUEST';
export const GET_GUARANTEESTORAISE_SUCCESS = 'GET_GUARANTEESTORAISE_SUCCESS';
export const GET_GUARANTEESTORAISE_FAILURE = 'GET_GUARANTEESTORAISE_FAILURE';

export const EXONERATEGUARANTE_REQUEST = 'EXONERATEGUARANTE_REQUEST';
export const EXONERATEGUARANTE_SUCCESS = 'EXONERATEGUARANTE_SUCCESS';
export const EXONERATEGUARANTE_FAILURE = 'EXONERATEGUARANTE_FAILURE';

export const SEND_NOTIFY_REQUEST = 'SEND_NOTIFY_REQUEST';
export const SEND_NOTIFY_SUCCESS = 'SEND_NOTIFY_SUCCESS';
export const SEND_NOTIFY_FAILURE = 'SEND_NOTIFY_FAILURE';

export const SEND_RAISE_REQUEST = 'SEND_RAISE_REQUEST';
export const SEND_RAISE_SUCCESS = 'SEND_RAISE_SUCCESS';
export const SEND_RAISE_FAILURE = 'SEND_RAISE_FAILURE';

export const RESET_QUOTA = 'RESET_QUOTA';

export const RESET_GUARANTEESTOEXONERATE = 'RESET_GUARANTEESTOEXONERATE';

export const RESET_GUARANTEESTONOTIFY = 'RESET_GUARANTEESTONOTIFY';

export const RESET_RESERVE = 'RESET_RESERVE';

// liquidator
export const GET_LIQUIDATOR_RATES_REQUEST = 'GET_LIQUIDATOR_RATES_REQUEST';
export const GET_LIQUIDATOR_RATES_SUCCESS = 'GET_LIQUIDATOR_RATES_SUCCESS';
export const GET_LIQUIDATOR_RATES_FAILURE = 'GET_LIQUIDATOR_RATES_FAILURE';

export const GET_LIQUIDATOR_DETAIL_REQUEST = 'GET_LIQUIDATOR_DETAIL_REQUEST';
export const GET_LIQUIDATOR_DETAIL_SUCCESS = 'GET_LIQUIDATOR_DETAIL_SUCCESS';
export const GET_LIQUIDATOR_DETAIL_FAILURE = 'GET_LIQUIDATOR_DETAIL_FAILURE';

export const GET_LIQUIDATOR_REQUEST = 'GET_LIQUIDATOR_REQUEST';
export const GET_LIQUIDATOR_SUCCESS = 'GET_LIQUIDATOR_SUCCESS';
export const GET_LIQUIDATOR_FAILURE = 'GET_LIQUIDATOR_FAILURE';
