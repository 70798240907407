import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import reducers from '../redux/reducers';

// const loggerMiddleware = createLogger();

export const store = createStore(
  reducers,
  compose(
      applyMiddleware(thunkMiddleware),
      window.devToolsExtension ? window.devToolsExtension() : f => f
    )
)
