import * as transactionService from '../services';
import {
  GET_ADVANCE_AGREEMENTS_REQUEST,
  GET_ADVANCE_AGREEMENTS_SUCCESS,
  GET_ADVANCE_AGREEMENTS_FAILURE,
  UPLOAD_ADVANCE_AGREEMENT_REQUEST,
  UPLOAD_ADVANCE_AGREEMENT_SUCCESS,
  UPLOAD_ADVANCE_AGREEMENT_FAILURE,
  RESET_ADVANCE_AGREEMENTS_REQUEST,
  RESET_UPLOAD_ADVANCE_AGREEMENT_REQUEST,
  GENERAL_ERROR,
} from '../action-types'

export const getAdvanceAgreements = ({documentType, documentNumber, userDocument, userDocumentType, page}) => {
  return dispatch => {
    dispatch(request());
    transactionService.getAdvanceAgreements({documentType, documentNumber, userDocument, userDocumentType, page})
    .then(
      data => {
        dispatch(success(data, page));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: GET_ADVANCE_AGREEMENTS_REQUEST } }
  function success(data, page) { return { type: GET_ADVANCE_AGREEMENTS_SUCCESS, data, page } }
  function failure(error) { return { type: GET_ADVANCE_AGREEMENTS_FAILURE, error } }
}

export const uploadAdvanceAgreement = ({documentType, documentNumber, userDocument, userDocumentType, userName, invoiceCount, valueToPay, valueSelected}) => {
  return dispatch => {
    dispatch(request());
    transactionService.uploadAdvanceAgreement({documentType, documentNumber, userDocument, userDocumentType, userName, invoiceCount, valueToPay, valueSelected})
    .then(
      data => {
        dispatch(success(data));
      },
      error => {
        if (error.errorMessage) {
          dispatch(generalError(error.errorMessage));
        }
        dispatch(failure(error));
      }
    );
  }

  function request() { return { type: UPLOAD_ADVANCE_AGREEMENT_REQUEST } }
  function success(data) { return { type: UPLOAD_ADVANCE_AGREEMENT_SUCCESS, data } }
  function failure(error) { return { type: UPLOAD_ADVANCE_AGREEMENT_FAILURE, error } }
}

export const resetAdvanceAgreements = () => {
  return dispatch => {
    dispatch(request());
  }
  function request() { return { type: RESET_ADVANCE_AGREEMENTS_REQUEST } }
}

export const resetExportAdvanceAgreements = () => {
  return dispatch => {
    dispatch(request());
  }
  function request() { return { type: RESET_UPLOAD_ADVANCE_AGREEMENT_REQUEST } }
}

export const generalError = (generalError) => { return { type: GENERAL_ERROR, generalError } }
