import {
  SET_USERDATA,
  GET_USERDATA,
  GET_GLOBALDATA_REQUEST,
  GET_GLOBALDATA_SUCCESS,
  GET_GLOBALDATA_FAILURE,
  GET_ROLPERMISSIONS_REQUEST,
  GET_ROLPERMISSIONS_SUCCESS,
  GET_ROLPERMISSIONS_FAILURE,
  GET_ISUSER_REQUEST,
  GET_ISUSER_SUCCESS,
  GET_ISUSER_FAILURE,
  GET_ISUSER_CLEAN,
  GET_RECOVER_REQUEST,
  GET_RECOVER_SUCCESS,
  GET_RECOVER_FAILURE,
  GET_REMEMBER_REQUEST,
  GET_REMEMBER_SUCCESS,
  GET_REMEMBER_FAILURE,
  GET_CHANGE_REQUEST,
  GET_CHANGE_SUCCESS,
  GET_CHANGE_FAILURE,
  GET_CHANGEPASSWORD_REQUEST,
  GET_CHANGEPASSWORD_SUCCESS,
  GET_CHANGEPASSWORD_FAILURE,
  GET_FAMILYLIST_REQUEST,
  GET_FAMILYLIST_SUCCESS,
  GET_FAMILYLIST_FAILURE,
  GET_CAREERLIST_REQUEST,
  GET_CAREERLIST_SUCCESS,
  GET_CAREERLIST_FAILURE,
  GET_MYPAYMENTS_REQUEST,
  GET_MYPAYMENTS_SUCCESS,
  GET_MYPAYMENTS_FAILURE,
  GET_MYPAYMENT_INVOICE_REQUEST,
  GET_MYPAYMENT_INVOICE_SUCCESS,
  GET_MYPAYMENT_INVOICE_FAILURE,
  GET_ROLELIST_REQUEST,
  GET_ROLELIST_SUCCESS,
  GET_ROLELIST_FAILURE,
  GET_MANAGEDUSERS_REQUEST,
  GET_MANAGEDUSERS_SUCCESS,
  GET_MANAGEDUSERS_FAILURE,
  GET_AFFILIATE_REQUEST,
  GET_AFFILIATE_SUCCESS,
  GET_AFFILIATE_FAILURE,
  GET_AFFILIATE_TOKEN_REQUEST,
  GET_AFFILIATE_TOKEN_SUCCESS,
  GET_AFFILIATE_TOKEN_FAILURE,
  SET_MANAGEDUSERS_REQUEST,
  SET_MANAGEDUSERS_SUCCESS,
  SET_MANAGEDUSERS_FAILURE,
  RESET_MANAGEDUSERS,
  GET_PAYMENTDETAIL_REQUEST,
  GET_PAYMENTDETAIL_SUCCESS,
  GET_PAYMENTDETAIL_FAILURE,
  GET_PAYMENTLINK_REQUEST,
  GET_PAYMENTLINK_SUCCESS,
  GET_PAYMENTLINK_FAILURE,
  RESET_PAYMENTDETAIL_REQUEST,
  GET_MYSTATISTICS_REQUEST,
  GET_MYSTATISTICS_SUCCESS,
  GET_MYSTATISTICS_FAILURE,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
  GET_CLIENTTYPES_REQUEST,
  GET_CLIENTTYPES_SUCCESS,
  GET_CLIENTTYPES_FAILURE,
  SET_BIRTHDATE_REQUEST,
  SET_BIRTHDATE_SUCCESS,
  SET_BIRTHDATE_FAILURE,
  SEND_FILE_REQUEST,
  SEND_FILE_SUCCESS,
  SEND_FILE_FAILURE,
  REQUEST_CLIENTSTUDY_REQUEST,
  REQUEST_CLIENTSTUDY_SUCCESS,
  REQUEST_CLIENTSTUDY_FAILURE,
  REQUEST_THREE_PAYMENTS_CLIENTSTUDY_REQUEST,
  REQUEST_THREE_PAYMENTS_CLIENTSTUDY_SUCCESS,
  REQUEST_THREE_PAYMENTS_CLIENTSTUDY_FAILURE,
  REQUEST_PUBLICCLIENTSTUDY_REQUEST,
  REQUEST_PUBLICCLIENTSTUDY_SUCCESS,
  REQUEST_PUBLICCLIENTSTUDY_FAILURE,
  GET_VALIDATE_FORM_REQUEST,
  GET_VALIDATE_FORM_SUCCESS,
  GET_VALIDATE_FORM_FAILURE,
  SAVE_FORM_REQUEST,
  SAVE_FORM_SUCCESS,
  SAVE_FORM_FAILURE,
  SEND_CLIENTLINK_REQUEST,
  SEND_CLIENTLINK_SUCCESS,
  SEND_CLIENTLINK_FAILURE,
  RESET_CLIENTLINK,
  RESET_AFFILIATE_TOKEN,
  RESET_ALL,
  GENERAL_ERROR,
} from '../action-types';

const initialState = {
  loading: false,
  loadingCareerList: false,
  loadingFamilyList: false,
  user: false,
  userRecover: false,
  userRemember: false,
  userChange: false,
  userChangePassword: false,
  error: false,
  errorUser: false,
  globals: false,
  permissions: false,
  familyList: false,
  careerList: false,
  myPayments: [],
  myPaymentInvoice: null,
  roleList: [],
  managedUsers: [],
  managedUsersPageCount: 1,
  managedUsersUpdate: false,
  managedUsersLoading: false,
  myPaymentDetail: false,
  myStatistics: false,
  cities: [],
  clientTypes: [],
  url: false,
  birthDate: false,
  fileSucces: false,
  clientStudyRequest: false,
  formValidate: null,
  saveForm: null,
  clientStudyLinkRequest: false,
  setUserData: false,
  generalError: false,
  affiliateUsers: [],
  affiliateToken: null,
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case GET_GLOBALDATA_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_GLOBALDATA_SUCCESS: {
      let globals = action.data;
      return {
        ...state,
        globals: globals,
        error: false,
        loading: false,
      };
    }

    case GET_GLOBALDATA_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_ROLPERMISSIONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_ROLPERMISSIONS_SUCCESS: {
      let permissions = action.data;
      return {
        ...state,
        permissions,
        error: false,
        loading: false,
      };
    }

    case GET_ROLPERMISSIONS_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_CAREERLIST_REQUEST: {
      return {
        ...state,
        loadingCareerList: true,
      };
    }

    case GET_CAREERLIST_SUCCESS: {
      let careerList = action.data;
      return {
        ...state,
        careerList,
        error: false,
        loadingCareerList: false,
      };
    }

    case GET_CAREERLIST_FAILURE: {
      return {
        ...state,
        error: action.error,
        loadingCareerList: false,
      };
    }

    case GET_CITIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CITIES_SUCCESS: {
      let cities = 'MUNICIPIOS' in action.data ? action.data.MUNICIPIOS : [];
      let stateArray = {
        ...state,
        cities,
        error: false,
        loading: false,
      };
      if (action.cityArray) {
        stateArray = {
          ...state,
          [action.cityArray]: cities,
          error: false,
          loading: false,
        };
      }
      return stateArray;
    }

    case GET_CITIES_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_CLIENTTYPES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CLIENTTYPES_SUCCESS: {
      let clientTypes = 'PRODUCTO' in action.data ? action.data.PRODUCTO : [];
      return {
        ...state,
        clientTypes,
        error: false,
        loading: false,
      };
    }

    case GET_CLIENTTYPES_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_FAMILYLIST_REQUEST: {
      return {
        ...state,
        loadingFamilyList: true,
      };
    }

    case GET_FAMILYLIST_SUCCESS: {
      let familyList = action.data;
      return {
        ...state,
        familyList,
        error: false,
        loadingFamilyList: false,
      };
    }

    case GET_FAMILYLIST_FAILURE: {
      return {
        ...state,
        error: action.error,
        loadingFamilyList: false,
      };
    }

    case GET_ISUSER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_ISUSER_SUCCESS: {
      let userData = action.data;
      return {
        ...state,
        user: userData,
        errorUser: false,
        loading: false,
      };
    }

    case GET_ISUSER_CLEAN: {
      return {
        ...state,
        errorUser: false,
        userRecover: false,
        userRemember: false,
        userChange: false,
        userChangePassword: false,
        loading: false,
        clientStudyRequest: false,
        formValidate: null,
        clientStudyLinkRequest: false,
      };
    }

    case GET_ISUSER_FAILURE: {
      return {
        ...state,
        errorUser: action.error,
        loading: false,
      };
    }

    case GET_RECOVER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_RECOVER_SUCCESS: {
      let userData = action.data;
      return {
        ...state,
        userRecover: userData,
        errorUser: false,
        loading: false,
      };
    }

    case GET_RECOVER_FAILURE: {
      return {
        ...state,
        errorUser: action.error,
        loading: false,
      };
    }

    case SET_BIRTHDATE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_BIRTHDATE_SUCCESS: {
      let birthDate = action.data;
      return {
        ...state,
        birthDate,
        errorUser: false,
        loading: false,
      };
    }

    case SET_BIRTHDATE_FAILURE: {
      return {
        ...state,
        errorUser: action.error,
        loading: false,
      };
    }

    case SEND_FILE_REQUEST: {
      return {
        ...state,
        [`${action.fileType}Loading`]: true,
      };
    }

    case SEND_FILE_SUCCESS: {
      let fileSucces = action.data;
      console.log('fileSucces', fileSucces);
      return {
        ...state,
        fileSucces,
        errorUser: false,
        [`${action.fileType}Loading`]: false,
      };
    }

    case SEND_FILE_FAILURE: {
      return {
        ...state,
        errorUser: action.error,
        [`${action.fileType}Loading`]: false,
      };
    }

    case GET_REMEMBER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_REMEMBER_SUCCESS: {
      let userData = action.data;
      return {
        ...state,
        userRemember: userData,
        errorUser: false,
        loading: false,
      };
    }

    case GET_REMEMBER_FAILURE: {
      return {
        ...state,
        errorUser: action.error,
        loading: false,
      };
    }

    case GET_CHANGE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CHANGE_SUCCESS: {
      let userData = action.data;
      return {
        ...state,
        userChange: userData,
        errorUser: false,
        loading: false,
      };
    }

    case GET_CHANGE_FAILURE: {
      return {
        ...state,
        errorUser: action.error,
        loading: false,
      };
    }

    case GET_CHANGEPASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_CHANGEPASSWORD_SUCCESS: {
      let userData = action.data;
      return {
        ...state,
        userChangePassword: userData,
        errorUser: false,
        loading: false,
      };
    }

    case GET_CHANGEPASSWORD_FAILURE: {
      return {
        ...state,
        errorUser: action.error,
        loading: false,
      };
    }

    case GET_MYPAYMENTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_MYPAYMENTS_SUCCESS: {
      let myPayments = 'FACTURAS' in action.data ? action.data.FACTURAS : [];
      return {
        ...state,
        myPayments,
        error: false,
        loading: false,
      };
    }

    case GET_MYPAYMENTS_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_MYPAYMENT_INVOICE_REQUEST: {
      return {
        ...state,
        myPaymentInvoice: null,
        loading: true,
      };
    }

    case GET_MYPAYMENT_INVOICE_SUCCESS: {
      return {
        ...state,
        myPaymentInvoice: action.data,
        error: false,
        loading: false,
      };
    }

    case GET_MYPAYMENT_INVOICE_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_ROLELIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_ROLELIST_SUCCESS: {
      let roleList =
        'ROL_USUARIO' in action.data ? action.data.ROL_USUARIO : [];
      return {
        ...state,
        roleList,
        error: false,
        loading: false,
      };
    }

    case GET_ROLELIST_FAILURE: {
      return {
        ...state,
        error: action.error,
        managedUsersLoading: false,
      };
    }

    case GET_MANAGEDUSERS_REQUEST: {
      return {
        ...state,
        managedUsersLoading: true,
      };
    }

    case GET_MANAGEDUSERS_SUCCESS: {
      let managedUsersTemp =
        'USUARIOS' in action.data ? action.data.USUARIOS : [];
      let managedUsersPageCount = managedUsersTemp.length;
      let managedUsers =
        action.page === 1
          ? managedUsersTemp
          : state.managedUsers.concat(managedUsersTemp);
      return {
        ...state,
        managedUsers,
        managedUsersPageCount,
        error: false,
        managedUsersLoading: false,
      };
    }

    case GET_MANAGEDUSERS_FAILURE: {
      return {
        ...state,
        error: action.error,
        managedUsersLoading: false,
      };
    }

    case SET_MANAGEDUSERS_REQUEST: {
      return {
        ...state,
        managedUsersLoading: true,
      };
    }

    case SET_MANAGEDUSERS_SUCCESS: {
      let managedUsersUpdate = action.data;
      return {
        ...state,
        managedUsersUpdate,
        error: false,
        managedUsersLoading: false,
      };
    }

    case SET_MANAGEDUSERS_FAILURE: {
      return {
        ...state,
        error: action.error,
        managedUsersLoading: false,
      };
    }

    case GET_AFFILIATE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_AFFILIATE_SUCCESS: {
      let affiliateUsers =
        'AFILIADO_SELECCIONAR' in action.data
          ? action.data.AFILIADO_SELECCIONAR
          : [];
      return {
        ...state,
        affiliateUsers,
        error: false,
        loading: false,
      };
    }

    case GET_AFFILIATE_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_AFFILIATE_TOKEN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_AFFILIATE_TOKEN_SUCCESS: {
      let affiliateToken = 'TOKEN' in action.data ? action.data.TOKEN : null;
      return {
        ...state,
        affiliateToken,
        error: false,
        loading: false,
      };
    }

    case GET_AFFILIATE_TOKEN_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case RESET_MANAGEDUSERS: {
      return {
        ...state,
        managedUsersUpdate: false,
      };
    }

    case GET_PAYMENTDETAIL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PAYMENTDETAIL_SUCCESS: {
      return {
        ...state,
        myPaymentDetail: action.data,
        error: false,
        loading: false,
      };
    }

    case RESET_PAYMENTDETAIL_REQUEST: {
      return {
        ...state,
        myPaymentDetail: false,
      };
    }

    case GET_PAYMENTDETAIL_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_PAYMENTLINK_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PAYMENTLINK_SUCCESS: {
      let url = 'url' in action.data ? action.data.url : false;
      return {
        ...state,
        url,
        error: false,
        loading: false,
      };
    }

    case GET_PAYMENTLINK_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_MYSTATISTICS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_MYSTATISTICS_SUCCESS: {
      let myStatistics = action.data;
      return {
        ...state,
        myStatistics,
        error: false,
        loading: false,
      };
    }

    case GET_MYSTATISTICS_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case REQUEST_CLIENTSTUDY_REQUEST:
    case REQUEST_THREE_PAYMENTS_CLIENTSTUDY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case REQUEST_CLIENTSTUDY_SUCCESS:
    case REQUEST_THREE_PAYMENTS_CLIENTSTUDY_SUCCESS: {
      let clientStudyRequest = action.data;
      return {
        ...state,
        clientStudyRequest,
        error: false,
        loading: false,
      };
    }

    case REQUEST_CLIENTSTUDY_FAILURE:
    case REQUEST_THREE_PAYMENTS_CLIENTSTUDY_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case GET_VALIDATE_FORM_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_VALIDATE_FORM_SUCCESS: {
      let formValidate = action.data;
      return {
        ...state,
        formValidate,
        error: false,
        loading: false,
      };
    }

    case GET_VALIDATE_FORM_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case SAVE_FORM_REQUEST: {
      return {
        ...state,
      };
    }

    case SAVE_FORM_SUCCESS: {
      let saveForm = action.data;
      return {
        ...state,
        saveForm,
        error: false,
      };
    }

    case SAVE_FORM_FAILURE: {
      return {
        ...state,
        // error: action.error,
      };
    }

    case REQUEST_PUBLICCLIENTSTUDY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case REQUEST_PUBLICCLIENTSTUDY_SUCCESS: {
      let clientStudyRequest = action.data;
      return {
        ...state,
        clientStudyRequest,
        error: false,
        loading: false,
      };
    }

    case REQUEST_PUBLICCLIENTSTUDY_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case SEND_CLIENTLINK_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case SEND_CLIENTLINK_SUCCESS: {
      let clientStudyLinkRequest = action.data;
      return {
        ...state,
        clientStudyLinkRequest,
        error: false,
        loading: false,
      };
    }

    case SEND_CLIENTLINK_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }

    case RESET_CLIENTLINK: {
      return {
        ...state,
        clientStudyLinkRequest: false,
        error: false,
        loading: false,
      };
    }

    case RESET_AFFILIATE_TOKEN: {
      return {
        ...state,
        affiliateToken: null,
      };
    }

    case SET_USERDATA: {
      return {
        ...state,
        error: {},
        setUserData: true,
        loading: false,
      };
    }

    case GET_USERDATA: {
      return {
        ...state,
        user: action.user,
        loading: false,
      };
    }

    case RESET_ALL: {
      return initialState;
    }

    case GENERAL_ERROR: {
      return {
        ...state,
        generalError: action.generalError,
      };
    }

    default:
      return state;
  }
};

export default users;
