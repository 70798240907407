import React, { Component } from 'react';

import { Col, Button, Modal, Row } from 'react-bootstrap';
import aproved from '../../../assets/icons/aproved.svg';


class EmailModalSucces extends Component {

  confirm = () => {
    this.props.onHide()
  }

  render() {
    const {
      show,
      onHide,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="query-code-title">
            Enviar por correo
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="terms-modal-body">
            <Col xs={12} className="query-code-description">
              <img src={aproved} className="img-aproved" alt="aprobado icono" />
            </Col>
            <Col xs={12} className="query-code-description">
              El correo electrónico<br/>
            ha sido enviado exitosamente<br/>
            </Col>
            <Col xs={12} className="query-code-buttons">
              <Button
                className="button-send"
                variant="success"
                onClick={(e) => {
                  e.preventDefault();
                  this.confirm();
                }}
                block
                >
                Cerrar
              </Button>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EmailModalSucces;
