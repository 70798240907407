import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./profile.scss";
import * as actions from "../../redux/actions";
import { Col, Button, Row } from "react-bootstrap";
import arrowback from "../../assets/icons/arrowback.svg";
import Footer from "../footer";
import Header from "../header";
import Menu from "../menu";
import Chat from "../chat";
import UserHeader from "../user-header";
import calendaricon from "../../assets/icons/calendaricon.svg";
import userFullLogo from "../../assets/logos/user-full-logo.svg";
import { TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import moment from "moment";
import "moment/locale/es";
import MomentUtils from "@date-io/moment";
import ConfirmChange from "./confirm-change";
import Loading from "../loading";

moment.locale("es");

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
      fromDate: null,
      toDate: null,
      selectedDate: null,
      confirmModal: false,
      changePassword: false,
      currentPassword: "",
      password: "",
      passwordRepeat: "",
      imageFile: false,
      serverPhoto: "",
      photoToSave: false,
      dateToSave: false,
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.imageFile = React.createRef();
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const terms = params.get("terms");
    if (terms) {
      this.goChangePassword();
    }
    this.loadData();
  }

  loadData() {
    this.props.actions.getUser();
  }

  getSellersReport = (user) => {
    this.props.actions.getTransactionsNumber({
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      userDocument: user.userInfo.DOCUMENTO,
      documentNumber: 0,
      documentType: user.userInfo.TIPO_DOCUMENTO_USUARIO,
      page: 1,
      sellerEmail: user.userEmail,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.userChangePassword) {
      this.props.actions.cleanUser();
      this.setState({
        confirmModal: true,
        currentPassword: "",
        password: "",
        passwordRepeat: "",
      });
    }
    if (nextProps.user !== this.props.user) {
      let selectedDate =
        nextProps.user &&
        nextProps.user.userInfo &&
        nextProps.user.userInfo.NACIMIENTO
          ? moment(nextProps.user.userInfo.NACIMIENTO)
          : null;
      this.setState({
        selectedDate,
      });
      // this.getSellersReport(nextProps.user); //TODO: se evita el llamado al servicio de pr_vendedores
    }
    if (nextProps.transactionsNumber !== this.props.transactionsNumber) {
      let randNumber = Math.random() * 100;
      let serverPhoto =
        nextProps.transactionsNumber.length > 0
          ? nextProps.transactionsNumber[0].FOTO_USUARIO + "?" + randNumber
          : "";
      this.setState({
        serverPhoto,
      });
    }
    if (nextProps.fileSucces !== this.props.fileSucces) {
      this.userHeaderRef.changeImgNumber();
      this.menuRef.changeImgNumber();
    }
  }

  goHome = () => {
    const { changePassword } = this.state;
    if (changePassword) {
      this.setState({
        changePassword: false,
      });
    } else {
      this.props.history.push(`/`);
    }
  };

  signoff = () => {
    let userJson = JSON.parse(localStorage.getItem("user"));
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    // window.gtag("event", "cerrar_sesion", {
    //   event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
    //   event_label: userJson.userEmail,
    // });
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("permissions");
    localStorage.removeItem("documents");
    window.location.reload(true);
    // this.props.history.push("/login");
  };

  changePassword = () => {
    const { user } = this.props;
    const { password, passwordRepeat, currentPassword } = this.state;
    let userJson = JSON.parse(localStorage.getItem("user"));
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    // window.gtag("event", "cambio_contrasena", {
    //   event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
    //   event_label: userJson.userEmail,
    // });
    this.props.actions.changeUserPassword({
      userEmail: user.userEmail,
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      password,
      passwordRepeat,
      currentPassword,
    });
    const params = new URLSearchParams(this.props.location.search);
    const terms = params.get("terms");
    if (terms) {
      let userJson = JSON.parse(localStorage.getItem("user"));
      let globalsJson = JSON.parse(localStorage.getItem("globals"));
      let idPais =
        "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
          ? globalsJson.PAIS[0].ID_PAIS
          : "1";
      // window.gtag("event", "acepto_terminos", {
      //   event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
      //   event_label: userJson.userEmail,
      // });
    }
    this.props.actions.setTermsAcepted({
      documentType: user.userInfo.TIPO_DOCUMENTO,
      documentNumber: user.userInfo.DOCUMENTO,
      userEmail: user.userEmail,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO_USUARIO,
      userDocumentNumber: user.userInfo.DOCUMENTO_USUARIO,
      termsType: "[1]",
    });
  };

  closeMenu() {
    this.setState({
      menuOpen: false,
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true,
    });
  }

  handleDateChange = (date) => {
    this.setState({
      selectedDate: date,
      dateToSave: true,
    });
  };

  confirmChangeClose = () => {
    this.setState({
      confirmModal: false,
    });
  };

  goChangePassword = () => {
    this.setState({
      changePassword: true,
    });
  };

  isPasswordFormat = (password) => {
    let isFormated = true;

    if (password.length < 8) {
      return false;
    }

    if (!/[a-z]/.test(password)) {
      return false;
    }

    if (!/[A-Z]/.test(password)) {
      return false;
    }

    if (!/[0-9]/.test(password)) {
      return false;
    }

    return isFormated;
  };

  oninputChange = (event, input) => {
    this.setState({
      [input]: event.target.value,
    });
  };

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if (thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  openSelector = () => {
    this.imageFile.click();
  };

  saveChanges = () => {
    let userJson = JSON.parse(localStorage.getItem("user"));
    let globalsJson = JSON.parse(localStorage.getItem("globals"));
    let idPais =
      "PAIS" in globalsJson && "ID_PAIS" in globalsJson.PAIS[0]
        ? globalsJson.PAIS[0].ID_PAIS
        : "1";
    // window.gtag("event", "cambiar_fecha_nacimiento", {
    //   event_category: `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`,
    //   event_label: userJson.userEmail,
    // });
    const { user, transactionsNumber } = this.props;
    const { selectedDate, imageFile, dateToSave, photoToSave } = this.state;
    let userEmail =
      transactionsNumber.length > 0 ? transactionsNumber[0].USUARIO : "";
    let userInfo = user.userInfo;
    userInfo.NACIMIENTO = moment(selectedDate).format("YYYY-MM-DD");
    this.props.actions.setUser({
      userEmail: user.userEmail,
      token: user.token,
      userInfo,
    });
    if (dateToSave) {
      this.props.actions.setBirthDate({
        userEmail: userEmail,
        documentNumber: user.userInfo.DOCUMENTO,
        documentType: user.userInfo.TIPO_DOCUMENTO,
        birthDate: moment(selectedDate).format("DDMMYYYY"),
      });
    }
    if (photoToSave) {
      this.props.actions.sendFile({
        documentType: user.userInfo.TIPO_DOCUMENTO,
        documentNumber: user.userInfo.DOCUMENTO,
        fileType: "perfil",
        sellerDocumentType: user.userInfo.TIPO_DOCUMENTO_USUARIO,
        sellerDocumentNumber: user.userInfo.DOCUMENTO_USUARIO,
        file: imageFile,
      });
    }

    this.setState({
      dateToSave: false,
      photoToSave: false,
    });
  };

  handleFileChange = (imageFile) => {
    this.setState({
      imageFile: imageFile[0],
      photoToSave: true,
    });
  };

  render() {
    const {
      menuOpen,
      selectedDate,
      confirmModal,
      changePassword,
      currentPassword,
      password,
      passwordRepeat,
      imageFile,
      serverPhoto,
      dateToSave,
      photoToSave,
    } = this.state;

    const { loading, loadingSeller, transactionsNumber, user } = this.props;

    let userTransactions =
      transactionsNumber.length > 0
        ? transactionsNumber[0].NUMERO_GARANTIAS
        : 0;
    let quota = transactionsNumber.length > 0 ? transactionsNumber[0].MONTO : 0;
    let loginPhoto =
      user && user.userInfo && user.userInfo.FOTO_USUARIO
        ? user.userInfo.FOTO_USUARIO
        : "";
    let userEmail =
      user && user.userInfo && user.userInfo.EMAIL ? user.userInfo.EMAIL : "";
    let userName =
      user && user.userInfo && user.userInfo.NOMBRE ? user.userInfo.NOMBRE : "";

    let userPhoto = imageFile
      ? URL.createObjectURL(imageFile)
      : serverPhoto
      ? serverPhoto
      : loginPhoto;

    return (
      <div className="profile">
        <ConfirmChange show={confirmModal} onHide={this.confirmChangeClose} />
        <Menu
          isOpen={menuOpen}
          closeMenu={this.closeMenu}
          pageId={14}
          onRef={(ref) => (this.menuRef = ref)}
        />
        <Header openMenu={this.openMenu} />
        <div className="page-body-container center-horizontally">
          <div className="page-body">
            <UserHeader onRef={(ref) => (this.userHeaderRef = ref)} />
            <input
              style={{ display: "none" }}
              ref={(input) => (this.imageFile = input)}
              type="file"
              accept="image/*"
              onChange={(e) => this.handleFileChange(e.target.files)}
            />
            {!changePassword && (
              <Col xs={12} className="general-main-card">
                {(loading || loadingSeller) && <Loading />}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                  >
                    <span>
                      <img
                        src={arrowback}
                        alt="arrow back"
                        className="goback-icon"
                      />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                <Col xs={12} className="profile-margin">
                  <Col xs={12} className="card-title">
                    Perfil
                  </Col>
                  <Col xs={12}>
                    <hr />
                  </Col>
                </Col>
                <Row className="justify-content-md-center">
                  <Col xs={12} md={3}>
                    <Row>
                      <Col xs={12} className="profile_change-photo-container">
                        <img
                          src={userPhoto ? userPhoto : userFullLogo}
                          alt=""
                          className="user-full-logo"
                        />
                        <div
                          className="profile_change-photo"
                          onClick={(e) => {
                            e.preventDefault();
                            this.openSelector();
                          }}
                        >
                          Cambiar imagen
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={5}>
                    <Row>
                      <Col xs={12}>
                        <Row>
                          <Col xs="auto" className="profile_user-key">
                            Transacciones aprobadas
                          </Col>
                          <Col className="profile_user-value">
                            {userTransactions}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col xs="auto" className="profile_user-key">
                            Monto
                          </Col>
                          <Col className="profile_user-value">
                            ${this.getNumber(quota)}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col xs="auto" className="profile_user-key">
                            Nombre
                          </Col>
                          <Col className="profile_user-value">{userName}</Col>
                        </Row>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col xs="auto" className="profile_user-key">
                            E-mail
                          </Col>
                          <Col className="profile_user-value">{userEmail}</Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    md={{ offset: 3, span: 5 }}
                    className="bith-date"
                  >
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      moment={moment}
                    >
                      <DatePicker
                        margin="normal"
                        label="Fecha de cumpleaños"
                        className="home-input"
                        value={selectedDate}
                        locale="es-ES"
                        onChange={this.handleDateChange}
                        fullWidth
                        format="DD/MMMM"
                        InputProps={{
                          endAdornment: (
                            <img
                              src={calendaricon}
                              alt="icono calendario"
                              className="calendar-icon"
                            />
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Col>
                  <Col xs={12} md={8}>
                    <Row>
                      <Col xs={12} md={6}>
                        <Button
                          className="button-send profile-save-changes"
                          variant="success"
                          disabled={!(dateToSave || photoToSave)}
                          onClick={(e) => {
                            e.preventDefault();
                            this.saveChanges();
                          }}
                          block
                        >
                          Guardar cambios
                        </Button>
                      </Col>
                      <Col xs={12} md={6}>
                        <Button
                          className="button-send"
                          variant="success"
                          onClick={(e) => {
                            e.preventDefault();
                            this.goChangePassword();
                          }}
                          block
                        >
                          Cambiar contraseña
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/*
                  <Col
                  xs={12}
                  className="close-sesion"
                  onClick={(e) => {
                  e.preventDefault();
                  this.signoff();
                  }}
                  >
                  CERRAR SESIÓN
                  <img src={arrow} alt="flecha derecha" className="goback-icon" />
                  </Col>
                  */}
              </Col>
            )}
            {changePassword && (
              <Col xs={12} className="general-main-card">
                {loading && <Loading />}
                <div xs={12} className="goback-container">
                  <div
                    className="goback-action"
                    onClick={(e) => {
                      e.preventDefault();
                      this.goHome();
                    }}
                  >
                    <span>
                      <img
                        src={arrowback}
                        alt="arrow back"
                        className="goback-icon"
                      />
                    </span>
                    <span className="goback-text">Volver</span>
                  </div>
                </div>
                <Col xs={12} className="profile-margin">
                  <Col xs={12} className="card-title">
                    Perfil
                  </Col>
                  <Col xs={12}>
                    <hr />
                  </Col>
                </Col>
                <Col
                  xs={12}
                  md={{ span: 6, offset: 3 }}
                  className="change-password_title"
                >
                  Cambiar contraseña
                </Col>
                <Col
                  xs={12}
                  md={{ span: 6, offset: 3 }}
                  className="password-container"
                >
                  <TextField
                    id="currentPassword"
                    label="Contraseña actual"
                    className="home-input"
                    margin="dense"
                    type="password"
                    onChange={(event) => {
                      this.oninputChange(event, "currentPassword");
                    }}
                    value={currentPassword}
                    fullWidth
                  />
                </Col>
                <Col
                  xs={12}
                  md={{ span: 6, offset: 3 }}
                  className="password-container"
                >
                  <TextField
                    id="password"
                    label="Nueva contraseña"
                    className="home-input"
                    margin="dense"
                    type="password"
                    onChange={(event) => {
                      this.oninputChange(event, "password");
                    }}
                    value={password}
                    error={!!password && !this.isPasswordFormat(password)}
                    helperText={
                      !!password && !this.isPasswordFormat(password)
                        ? "La contraseña debe ser minimo de 8 caracteres y tener al menos una letra minuscula, una mayuscula y un numero"
                        : null
                    }
                    fullWidth
                  />
                </Col>
                <Col
                  xs={12}
                  md={{ span: 6, offset: 3 }}
                  className="password-container"
                >
                  <TextField
                    id="passwordRepeat"
                    label="Confirmar nueva contraseña"
                    className="home-input"
                    margin="dense"
                    type="password"
                    onChange={(event) => {
                      this.oninputChange(event, "passwordRepeat");
                    }}
                    value={passwordRepeat}
                    error={
                      (!!passwordRepeat &&
                        !this.isPasswordFormat(passwordRepeat)) ||
                      (!!passwordRepeat &&
                        !!password &&
                        password !== passwordRepeat)
                    }
                    helperText={
                      !!passwordRepeat && !this.isPasswordFormat(passwordRepeat)
                        ? "La contraseña debe ser minimo de 8 caracteres y tener al menos una letra minuscula, una mayuscula y un numero"
                        : passwordRepeat &&
                          password &&
                          password !== passwordRepeat
                        ? "Las contraseñas no coinciden"
                        : null
                    }
                    fullWidth
                  />
                </Col>
                <Col
                  xs={12}
                  md={{ span: 4, offset: 4 }}
                  className="change-password-button"
                >
                  <Button
                    className="button-send"
                    variant="success"
                    disabled={
                      !this.isPasswordFormat(passwordRepeat) ||
                      !this.isPasswordFormat(passwordRepeat) ||
                      password !== passwordRepeat ||
                      !currentPassword
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.changePassword();
                    }}
                    block
                  >
                    Cambiar
                  </Button>
                </Col>
              </Col>
            )}
          </div>
        </div>
        <Chat />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let { user, globals, userChangePassword, loading, fileSucces } = state.user;
  let { transactionsNumber, loadingSeller } = state.transaction;
  return {
    user,
    globals,
    userChangePassword,
    loading,
    transactionsNumber,
    loadingSeller,
    fileSucces,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
