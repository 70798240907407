import React, { Component } from 'react';
import { PropTypes } from 'prop-types'

import { Col, Button, Modal, Row } from 'react-bootstrap';

class ConfirmChange extends Component {

  cancel = () => {
    this.props.onHide();
  }

  confirm = () => {
    const {
      individualExoneration
    } = this.props;
    this.props.confirmIndividualExoneration(individualExoneration);
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  render() {
    const {
      individualExoneration,
      show,
      onHide,
    } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="confirm-reserve-title">
            Confirmar exoneración individual
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="confirm-reserve-body">
            <Col xs={12} className="confirm-reserve_body-description">
              <Row>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Tipo de documento:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">{individualExoneration.TIPO_DOCUMENTO}</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Número de documento:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">{individualExoneration.DOCUMENTO}</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Número de producto:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">{individualExoneration.TITULO_VALOR}</Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col className="confirm-reserve_body-key">Valor a exonerar:</Col>
                    <Col xs="auto" className="confirm-reserve_body-value">${this.getNumber(individualExoneration.VALOR_A_EXONERAR)}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row>
                <Col xs={6}>
                  <Button
                    className="button-primary"
                    variant="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        this.cancel();
                    }}
                    block
                  >
                    Corregir
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className="button-send"
                    variant="success"
                    onClick={(e) => {
                        e.preventDefault();
                        this.confirm();
                    }}
                    block>
                    Aceptar
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ConfirmChange.propTypes = {
  individualExoneration: PropTypes.object,
  confirmIndividualExoneration: PropTypes.func.isRequired,
};

ConfirmChange.defaultProps = {
  individualExoneration: {
    "DOCUMENTO": "1047408785",
    "AUTORIZACION": "201177",
    "NUMERO_RECLAMACION": "0",
    "FECHA": "2020-03-10 08:58:49.0",
    "TIPO_TITULO": "FACTURA",
    "TITULO_VALOR": "97941",
    "VALOR_A_EXONERAR": "1000000",
    "DIAS_EXONERAR": "113",
    "TIPO_DOCUMENTO": "C",
    "VALOR_GARANTIZADO": "1000000",
    "RN": "2",
    "VENCIMIENTO": "2020-04-10 00:00:00.0",
    "N_REGISTROS": "2",
    "NOMBRE": "LINA  MARIA GARCIA DE LA OSSA",
   },
  confirmIndividualExoneration: () => {},
};

export default ConfirmChange;
