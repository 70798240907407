import config from '../../config';
import { authHeader } from '../../helpers';

export const getRates = ({userDocumentType, userDocument, queryCode}) => {
  const headers = authHeader()
  const requestOptions = {
    method: 'GET',
    headers
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais = ('PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]) ? globals.PAIS[0].ID_PAIS : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${userDocumentType}&documento=${userDocument}&codigo_consulta=${queryCode}`
  return fetch(`${config.apiUrl}/pr_tasas_liquidador?${finalParams}`, requestOptions).then(handleResponse);
}

export const getLiquidator = ({userDocumentType, userDocument, queryCode, sellerDocumentType, sellerDocumentNumber, quotaValue, firstInstallmentDate, coviRate, netRate, dues, extendedQuota, userName}) => {
  const headers = authHeader()
  const requestOptions = {
    method: 'GET',
    headers
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais = ('PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]) ? globals.PAIS[0].ID_PAIS : '1';
  let firstParams = `&comprador_tipo_documento=${sellerDocumentType}&comprador_documento=${sellerDocumentNumber}&valor_a_financiar=${quotaValue}`
  let secondParameters = `&fecha_primer_cuota=${firstInstallmentDate}&tasa_covicheque=${coviRate}&tasa_net=${netRate}`
  let thirdParameters = `&num_cuotas=${dues}&cupo_extendido=${extendedQuota}&usuario=${userName}`
  let lastParameters = `&valor_cuota_inicial=0&tipo_documento_estudiante=&documento_estudiante=`
  let finalParams = `id_pais=${idPais}&tipo_documento=${userDocumentType}&documento=${userDocument}&codigo_consulta=${queryCode}${firstParams}${secondParameters}${thirdParameters}${lastParameters}`
  return fetch(`${config.apiUrl}/pr_liquidador?${finalParams}`, requestOptions).then(handleResponse);
}

export const getLiquidatorDetail = ({userDocumentType, userDocument, liquidator}) => {
  const headers = authHeader()
  const requestOptions = {
    method: 'GET',
    headers
  };
  let globals = JSON.parse(localStorage.getItem('globals'));
  let idPais = ('PAIS' in globals && 'ID_PAIS' in globals.PAIS[0]) ? globals.PAIS[0].ID_PAIS : '1';
  let finalParams = `id_pais=${idPais}&tipo_documento=${userDocumentType}&documento=${userDocument}&liquidador=${liquidator}`
  return fetch(`${config.apiUrl}/pr_detalle_liquidador?${finalParams}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  if(response.headers.get("content-type").indexOf("application/ms-excel") !== -1) {
    return response.blob().then(blob => {
      return blob;
    });
  }
  return response.text().then(text => {
    const data = (text && response.ok) && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        window.location.reload(true);
      }
      let errorMessage = false
      if (response.status === 400) {
        errorMessage = response.headers.get('mensaje_error');
      }
      let errorArray = {
        status: response.status,
        text: (data && data.message) || response.statusText,
        errorMessage
      }
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}
