import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import '../login/login.scss';
import * as actions from '../../redux/actions'
import { Row, Col, Button } from 'react-bootstrap';
import covifacturaLogo from '../../assets/logos/Logoblanco.svg';
import erroricon from '../../assets/icons/warning-icon.svg';
import TextField from '@material-ui/core/TextField';
import Footer from '../footer';
import Loading from '../loading';

class RememberPassword extends Component {

  constructor() {
    super();
    this.state = {
      emailUser: '',
      password: '',
      validEmail: true,
    };

  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.tryAgain();
  }

  isValid() {
    const { username, password } = this.state;
    return !!(username && password);
  }

  isEmail = (event) => {
    let emailUser = event.target.value;
    // let validEmail = false;
    // if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(emailUser)) {
    //   validEmail = true;
    // }
    this.setState({
      emailUser
    });
  }

  onPasswordChange = (event) => {
    this.setState({
      password: event.target.value
    });
  }

  handleSubmit = () => {
      this.props.actions.rememberPassword({ userEmail: this.state.emailUser, rememberType: 'RECUPERAR' });
  }

  tryAgain = () => {
      this.props.actions.cleanUser();
  }

  goLogin = () => {
    this.props.actions.cleanUser();
    this.props.history.push(`/login`)
  }

  componentWillReceiveProps(nextProps) {
      if (nextProps.userRemember) {
        // this.props.actions.setUser({userEmail: this.state.emailUser, password: this.state.password, token: nextProps.user.TOKEN, userInfo: nextProps.user});
        // this.props.history.push(`/`);
      }
  }

  render() {
    const { loading, errorUser, userRemember } = this.props;

    const {
      emailUser,
    } = this.state;
    return (
      <div className="login full-screen">
        { loading && (
          <Loading />
        )}
        <Col className="image-cover full-screen hide-mobile" md={8} >
          <Row>
            <Col md={6}>
              <h2 className="login_title">Respaldamos a sus clientes</h2>
              <div className="login_title_line-container">
                <div className="login_title_line"></div><div className="login_title_line-point"></div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="full-screen login-scroll" xs={12} md={4} >
          { (!errorUser && !userRemember) && (
            <Row>
              <Col xs={12}>
                <Col xs={12} className="center-horizontally login-logo-container">
                  <img className="login-logo" src={covifacturaLogo} alt='covifactura logo'/>
                </Col>
                <Col xs={12} className="login_sub-title-container">
                  <h3 className="login_sub-title">RECUPERAR CONTRASEÑA</h3>
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="login_error-login remember-margin">
                  Para recuperar su contraseña ingrese su usuario y haga clic en continuar.
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="login-input-container">
                  <TextField
                    id="user"
                    label="Usuario"
                    className="login-input"
                    margin="dense"
                    onChange={this.isEmail}
                    value={emailUser}
                    fullWidth
                    />
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="button-send-container">
                  <Button
                    className="button-send"
                    variant="success"
                    block
                    disabled={(emailUser !== '') ? false : true}
                    onClick={(e) => {
                        e.preventDefault()
                        this.handleSubmit()
                    }}
                  >
                    CONTINUAR
                  </Button>
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="button-link-container">
                  <Button
                    className="button-link"
                    variant="link"
                    block
                    onClick={(e) => {
                        e.preventDefault()
                        this.goLogin()
                    }}
                  >
                    Volver al inicio
                  </Button>
                </Col>
              </Col>
            </Row>
          )}
          { errorUser && (
            <Row>
              <Col xs={12}>
                <Col xs={12} className="center-horizontally login-logo-container">
                  <img className="login-logo" src={covifacturaLogo} alt='covifactura logo'/>
                </Col>
                <Col xs={12} md={{ span: 8, offset: 2 }} className="login_sub-title-container">
                  <h3 className="login_sub-title">RECUPERAR CONTRASEÑA</h3>
                </Col>
                <Col xs={12} className="login_error-logo-container">
                  <img className="login_error-logo" src={erroricon} alt='icono error'/>
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="login_error-login">
                  El usuario {emailUser} no se encuentra en nuestra base de datos. Verifique la información.
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="button-send-container">
                  <Button
                    className="button-send"
                    variant="success"
                    block
                    onClick={(e) => {
                        e.preventDefault()
                        this.tryAgain()
                    }}
                  >
                    ACEPTAR
                  </Button>
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="button-link-container">
                  <Button
                    className="button-link"
                    variant="link"
                    block
                    onClick={(e) => {
                        e.preventDefault()
                        this.goLogin()
                    }}
                  >
                    Volver al inicio
                  </Button>
                </Col>
              </Col>
            </Row>
          )}
          { userRemember && (
            <Row>
              <Col xs={12}>
                <Col xs={12} className="center-horizontally login-logo-container">
                  <img className="login-logo" src={covifacturaLogo} alt='covifactura logo'/>
                </Col>
                <Col xs={12} md={{ span: 8, offset: 2 }} className="login_sub-title-container">
                  <h3 className="login_sub-title">RECUPERAR CONTRASEÑA</h3>
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="login_error-login remember_send-email">
                  Hemos enviado un correo electrónico al administrador de su cuenta para realizar el cambio de contraseña.
                </Col>
                <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} className="button-send-container">
                  <Button
                    className="button-send"
                    variant="success"
                    block
                    onClick={(e) => {
                        e.preventDefault()
                        this.goLogin()
                    }}
                  >
                    ACEPTAR
                  </Button>
                </Col>
              </Col>
            </Row>
          )}
        </Col>
        <Footer isLogin />
      </div>
    );
  }
}


const mapStateToProps = (state, props) => {

  let {
      loading,
      userRemember,
      errorUser
  } = state.user;

  console.log('state.user', state.user);

  return {
      loading,
      errorUser,
      userRemember
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RememberPassword);
