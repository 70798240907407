import {
  RESET_ALL,
  GET_ADVANCE_AGREEMENTS_REQUEST,
  GET_ADVANCE_AGREEMENTS_SUCCESS,
  GET_ADVANCE_AGREEMENTS_FAILURE,
  UPLOAD_ADVANCE_AGREEMENT_REQUEST,
  UPLOAD_ADVANCE_AGREEMENT_SUCCESS,
  UPLOAD_ADVANCE_AGREEMENT_FAILURE,
  RESET_ADVANCE_AGREEMENTS_REQUEST,
  RESET_UPLOAD_ADVANCE_AGREEMENT_REQUEST,
} from '../action-types'

const initialState = {
  loading: false,
  loadingPaginate: false,
  advanceAgreements: [],
  advanceAgreementsPageCount: 1,
  error: false,
  uploadAdvanceAgreements: false,
}

const advanceAgreements = (state = initialState, action) => {
  switch (action.type) {

    case GET_ADVANCE_AGREEMENTS_REQUEST: {
      return {
        ...state,
        loadingPaginate: true
      }
    }

    case GET_ADVANCE_AGREEMENTS_SUCCESS: {
      let advanceAgreementsTemp = 'GARANTIAS_ACUERDO_ANTICIPADO' in action.data ? action.data.GARANTIAS_ACUERDO_ANTICIPADO : [];
      let advanceAgreementsPageCount = advanceAgreementsTemp.length;
      let advanceAgreements = action.page === 1 ? advanceAgreementsTemp : state.advanceAgreements.concat(advanceAgreementsTemp);
      return {
        ...state,
        advanceAgreements,
        advanceAgreementsPageCount,
        error: false,
        loadingPaginate: false
      }
    }

    case GET_ADVANCE_AGREEMENTS_FAILURE: {
      return {
        ...state,
        error: action.error,
        loadingPaginate: false
      }
    }

    case UPLOAD_ADVANCE_AGREEMENT_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case UPLOAD_ADVANCE_AGREEMENT_SUCCESS: {
      return {
        ...state,
        uploadAdvanceAgreements: action.data,
        error: false,
        loading: false
      }
    }

    case RESET_ADVANCE_AGREEMENTS_REQUEST: {
      return {
        ...state,
        loading: false,
        loadingPaginate: false,
        advanceAgreements: [],
        advanceAgreementsPageCount: 1,
        error: false,
        uploadAdvanceAgreements: false
      }
    }

    case RESET_UPLOAD_ADVANCE_AGREEMENT_REQUEST: {
      return {
        ...state,
        uploadAdvanceAgreements: false
      }
    }

    case UPLOAD_ADVANCE_AGREEMENT_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case RESET_ALL: {
      return initialState
    }

    default:
      return state
  }
}

export default advanceAgreements
