import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import ReduxLazyScroll from 'redux-lazy-scroll';

import './reservations.scss';
import * as actions from '../../redux/actions'
import { Col, Button, Row, Table } from 'react-bootstrap';
// import arrowback from '../../assets/icons/arrowback.svg';
// import Footer from '../footer';
// import Header from '../header';
// import Menu from '../menu';
// import Chat from '../chat';
// import UserHeader from '../user-header';
import arrowgreen from '../../assets/icons/arrowGreen.svg';
// import reservesearch from '../../assets/icons/reserve-search.svg';
import notreserve from '../../assets/icons/not-reserve.svg';
import {TextField, MenuItem} from '@material-ui/core';
// import loadingicon from '../../assets/icons/loadingblue.svg';
import unknownnumber from '../../assets/icons/unknownnumber.svg';
import Loading from '../loading';
import QueryCodeModal from '../home/querycode-modal';
import ListLoader from '../list-loader';

class Reservations extends Component {

  constructor() {
    super();
    this.state = {
      menuOpen: false,
      documentType: '',
      documentNumber: '',
      tempDocumentType: '',
      tempDocumentNumber: '',
      tempValue: '',
      queryCodeModal: false,
    };
    this.page = 0;
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  goHome = () => {
    this.props.actions.resetQuota();
    this.props.history.push(`/`);
  }

  resetTab() {
    this.page = 0;
    this.setState({
      documentType: '',
      documentNumber: ''
    });
    this.getReservations();
  }

  componentDidMount() {
    this.props.provideCtrl({
      resetTab: () => this.resetTab()
    });
    this.loadData();
  }

  loadData = () => {
    this.props.actions.getUser();
    this.props.actions.getAppGlobals();
  }

  newSearch = () => {
    this.page = 0;
    this.getReservations();
  }

  getReservations = () => {
    const {
      documentType,
      documentNumber,
    } = this.state;
    const {
      user
    } = this.props;
    let newSearch = documentType === '' || documentNumber === '';
    this.page = this.page + 1;
    this.props.actions.getReservations({
      documentType: newSearch ? 'C' : documentType,
      documentNumber: newSearch ? 0 : documentNumber,
      userDocument: user.userInfo.DOCUMENTO,
      userDocumentType: user.userInfo.TIPO_DOCUMENTO,
      page: this.page,
    });
  }

  componentWillReceiveProps(nextProps) {

  }

  closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  openMenu() {
    this.setState({
      menuOpen: true
    });
  }

  oninputChange = (event, input) => {
    this.setState({
      [input] : event.target.value
    });
  }

  getNumber(numberValue) {
    let thousand = numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    let thousandArray = thousand.split(".");
    if(thousandArray.length > 2) {
      thousand = thousand.replace(".", "´");
    }
    return thousand;
  }

  getDocumentTypeName = (documentType) => {
    const { globals } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];
    let docuemntItem = documents.find((item)=> {
      return item.ID === documentType;
    });
    return docuemntItem ? docuemntItem.LABEL : '';
  }

  goTransaction = (documentType, documentNumber, tempValue, queryCode) => {
    let userJson = JSON.parse(localStorage.getItem('user'));
    let globalsJson = JSON.parse(localStorage.getItem('globals'));
    let idPais = ('PAIS' in globalsJson && 'ID_PAIS' in globalsJson.PAIS[0]) ? globalsJson.PAIS[0].ID_PAIS : '1';
    // window.gtag('event', 'abrir_transaccion_desde_reserva', { 'event_category': `${userJson.userInfo.TIPO_DOCUMENTO}:${userJson.userInfo.DOCUMENTO}:${idPais}`, 'event_label': userJson.userEmail });
    this.props.history.push(`/transacciones/${documentType}/${documentNumber}/${queryCode}?valor=${tempValue}`);
  }

  getDaysClass(days) {
    let classText = '';
    let hoursText = days.split(' ')
    if ( (hoursText[1] === 'Hora(s)') || days === 'Hoy' || days === '1 día' || days === '2 días') {
      classText = "red"
    }
    return classText;
  }

  openQueryCodeModal = (documentType, documentNumber, tempValue) => {
    this.setState({
      tempDocumentType: documentType,
      tempDocumentNumber: documentNumber,
      tempValue: tempValue,
      queryCodeModal: true
    });
  }

  queryCodeModalClose = () => {
    // this.setState({
    //   queryCodeModal: true
    // });
  }

  setQueryCode = (queryCode) => {
    this.setState({
      queryCodeModal: false,
      queryCode,
    }, () => {
      const {tempDocumentType, tempDocumentNumber, tempValue} = this.state;
      this.goTransaction(tempDocumentType, tempDocumentNumber, tempValue, queryCode);
    })
  }

  render() {
    const {
      documentType,
      documentNumber,
      queryCodeModal,
      tempDocumentType,
      tempDocumentNumber,
    } = this.state;
    const {
      loading,
      globals,
      reservations,
      reservationsNotRegistered,
      reservationsPageCount,
      user,
      loadingPaginate
    } = this.props;
    let documents = globals ? globals.TIPOS_DOCUMENTOS : [];

    return (
      <div className="reservations">
        { loading && (
          <Loading />
        )}
        <QueryCodeModal
          show={queryCodeModal}
          confirm={this.setQueryCode}
          onHide={this.queryCodeModalClose}
          selectedDocument={tempDocumentType}
          documentNumber={tempDocumentNumber}
        />
        <Col xs={12} className="reservations-margin">
          <Row>
            <Col xs={12} md={8} className="reservations-description-container">
              {/*
                <img src={reservesearch} alt="icono reservas" className="resevation-icon"/>
                */}
              <span className="reservations-description">
                Si quiere realizar la búsqueda de una reserva específica ingrese el tipo y número de documento
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="reservations-margin" id="table-scroll">
          <Row>
            <Col xs={12} md={4} className="input-container">
              <TextField
                id="documentType"
                select
                label="Tipo de documento"
                className="home-input"
                margin="dense"
                value={documentType}
                onChange={(event) => {
                  this.oninputChange(event, 'documentType');
                }}
                fullWidth
              >
                <MenuItem  value=''>
                </MenuItem>
                {documents.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.ID}>
                      {item.LABEL}
                    </MenuItem>
                  )
                })}
              </TextField>
            </Col>
            <Col xs={12} md={4} className="input-container">
              <TextField
                id="documentNumber"
                label="Número de documento"
                className="home-input"
                margin="dense"
                value={documentNumber}
                onChange={(event) => {
                  this.oninputChange(event, 'documentNumber');
                }}
                fullWidth
                />
            </Col>
            <Col xs={12} md={3}>
              <Button
                className="button-send"
                variant="success"
                disabled={documentNumber === '' || documentType === ''}
                onClick={(e) => {
                  e.preventDefault();
                  this.newSearch();
                }}
                block
              >
              Buscar
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="reservations-margin">
          <Row>
            <Col xs={12} className="reservations-description-container">
              <span className="reservations-description">
                En el siguiente cuadro encontrará las reservas realizadas en los últimos 5 días
              </span>
            </Col>
          </Row>
        </Col>
        { (!reservationsNotRegistered && user) && (
          <Col xs={12} className="reservations-table-container">
            <ReduxLazyScroll
              isFetching={loadingPaginate}
              errorMessage=''
              isParentScrollable={true}
              parentHeight='250px'
              loadMore={() => {
                this.getReservations();
              }}
              hasMore={reservationsPageCount > 0}
            >
              <Table bordered responsive="sm">
                <thead>
                  <tr>
                    <th>Tipo de d/mento</th>
                    <th>Documento</th>
                    <th>Vencimiento</th>
                    <th>Valor</th>
                    <th className="check">
                      Realizar transacción
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reservations.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.TIPO_DOCUMENTO}</td>
                        <td>{item.DOCUMENTO}</td>
                        {/*
                          <td>{moment(item.FECHA_RESERVA).format('DD/MM/YYYY')}</td>
                          */}
                        <td className={this.getDaysClass(item.DIAS_VENCIMIENTO)}>{item.DIAS_VENCIMIENTO}</td>
                        <td>${this.getNumber(item.VALOR_TOTAL)}</td>
                        <td
                          className="check selectable"
                          onClick={(e) => {
                            e.preventDefault();
                            this.openQueryCodeModal(item.TIPO_DOCUMENTO, item.DOCUMENTO, item.VALOR_TOTAL);
                          }}
                          >
                          Realizar transacción
                          <img src={arrowgreen} alt="flecha verde" className="link-arrow"/>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              { (loadingPaginate ) && (
                <ListLoader />
              )}
            </ReduxLazyScroll>
          </Col>
        )}
        { (!reservationsNotRegistered && reservations.length === 0 && documentNumber !== '') && (
          <Col xs={12}>
            <Row>
              <Col xs={12} md={{span: 4, offset: 4}} className="reservation-loading-text">
                <img src={notreserve} alt="icono reservas" className="resevation-icon"/>
              </Col>
              <Col xs={12} md={{span: 6, offset: 3}} className="not-reservation-text">
                El número de identificación <span className="not-reservation-text_bold">{this.getDocumentTypeName(documentType)} {documentNumber}</span> no cuenta con ningúna reserva de cupo en el momento. Si los datos estan mal diligenciados, escríbalos de nuevo.
              </Col>
            </Row>
          </Col>
        )}
        { (!loading && reservationsNotRegistered) && (
          <Col xs={12}>
            <Row>
              <Col xs={12} md={{span: 4, offset: 4}} className="reservation-loading-text">
                <img src={unknownnumber} alt="icono reservas" className="resevation-icon"/>
              </Col>
              <Col xs={12} md={{span: 6, offset: 3}} className="not-reservation-text">
                El número de identificación <span className="not-reservation-text_bold">{this.getDocumentTypeName(documentType)} {documentNumber}</span> no se encuentra registrado en nuestra base de datos. Si los datos estan mal diligenciados, escríbalos de nuevo.
              </Col>
            </Row>
          </Col>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let {
    user,
    globals
  } = state.user;
  let {
    loading,
    reservations,
    reservationsNotRegistered,
    reservationsPageCount,
    loadingPaginate
  } = state.reserve;

  return {
    reservationsNotRegistered,
    reservationsPageCount,
    reservations,
    loading,
    user,
    globals,
    loadingPaginate
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Reservations));
