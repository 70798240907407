import React, { Component } from 'react';

import './list-loader.scss';
import Loading from '../loading';


class ListLoader extends Component {

  render() {
    return (
      <div className="list-loader">
        <Loading />
      </div>
    );
  }
}

export default ListLoader;
